/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {TableCell, TableBody, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Edit} from '@mui/icons-material';

import {
  EnhancedTableToolbar,
  ModalConfirm,
  ModalSuccess,
  Table,
  Tooltips,
} from '../../../../components';
import {ColumnsType} from '../../../../types/typeIslandersPartners';
import IslanderService from '../../../../services/Islander/islander.service';
import ModalAddNewStaff from '../ModalAddNewStaff/ModalAddNewStaff';

// style
import {
  StyledTableRow,
  StyledAction,
  StyledIcon,
  Typography,
} from './TableStaff.style';

const staffMax = 20;

interface TableStaffProps {
  idMerchant: string | number;
  canDelete?: boolean;
  canCreate?: boolean;
}

function TableStaff({idMerchant}: TableStaffProps) {
  // states
  const [dense] = useState(false);
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [focusId, setFocusId] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [listStaff, setListStaff] = useState([]);
  const [idStaff, setIdStaff] = useState('');
  const [dataDetail, setDataDetail] = useState({});
  const [disableAddNew, setDisabledAddNew] = useState(false);
  const [listStaffLength, setListStaffLength] = useState(0);

  const [modal, setModal] = useState({
    isModalConfirmDelete: false,
    isModalSuccessDelete: false,
    isModalAddNewStaff: false,
  });
  const [params, setParams] = useState({
    _page: 0,
    _size: 5,
    status_eq: 1,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {dataIndex: 'email', numeric: false, disablePadding: false, label: 'Email'},
    // {
    //   dataIndex: 'contactNumber',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Mobile Number',
    // },
    {
      dataIndex: 'pin',
      numeric: false,
      disablePadding: false,
      label: 'Staff Pin code',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderAction = (value: string, objItem: object) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalAddNewStaff();
                setIdStaff(value);
                setDataDetail(objItem);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModalConfirmDelete();
                setIdStaff(value);
              }}
              size="medium"
            >
              <DeleteIcon style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalAddNewStaff = () => {
    setIdStaff('');
    setModal(preState => ({
      ...preState,
      isModalAddNewStaff: !modal.isModalAddNewStaff,
    }));
  };

  const toggleModalSuccessDelete = () => {
    new IslanderService()
      .softDelete(`${idMerchant}/employees/${idStaff}`)
      .then(res => {
        setIsLoading(true);
        if (res && res?.success) {
          setIsLoading(false);
          getListStaff();
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: !modal.isModalSuccessDelete,
          }));
          toggleModalConfirmDelete();
        }
      });
  };

  const renderName = values => {
    return `${values?.firstName} ${values?.lastName}`;
  };

  const getListStaff = () => {
    setIsLoading(true);
    new IslanderService()
      .getAllByID(`${idMerchant}/employees`, params)
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setIsLoading(false);
          setListStaff(res.data.items);
          setTotalPage(res?.data?.total);
          setListStaffLength(res?.data?.total);
        }
      });
  };

  useEffect(() => {
    getListStaff();
  }, [params, idMerchant]);

  useEffect(() => {
    setDisabledAddNew(listStaffLength >= staffMax);
  }, [listStaff]);

  return (
    <>
      <ModalConfirm
        visible={modal.isModalConfirmDelete}
        title={
          <Typography variant="h4">You are deleting the IP staff</Typography>
        }
        subTitle={<Typography>IP staff will be delete.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={toggleModalSuccessDelete}
      />

      <ModalSuccess
        visible={modal.isModalSuccessDelete}
        title={<Typography variant="h4">Delete successfully</Typography>}
        subTitle={<Typography>IP staff deleted.</Typography>}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: false,
          }));
        }}
      />

      <ModalAddNewStaff
        visible={modal.isModalAddNewStaff}
        onClose={toggleModalAddNewStaff}
        reloadPage={getListStaff}
        idMerchant={idMerchant}
        idStaff={idStaff}
        dataDetail={dataDetail}
      />

      <EnhancedTableToolbar
        isHeader={true}
        isAddNew
        isLoading={isLoading}
        titleHeader={'Staff (Max. 5)'}
        onClickAddNew={toggleModalAddNewStaff}
        canCreate={true}
        isDisabledAddNew={disableAddNew}
      />

      <Table
        dataSource={listStaff}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        orderBy={orderBy}
        dense={dense}
        isLoading={isLoading}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {listStaff?.map((row, index) => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell component="th" scope="row">
                  {renderName(row)}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.pin}</TableCell>
                <TableCell
                  align="right"
                  padding="checkbox"
                  style={{paddingRight: '10px'}}
                >
                  {renderAction(row.id, row)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
export default TableStaff;
