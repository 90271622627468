/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import axios from 'axios';

export default class ApiService extends React.Component {
  readonly domain: string | undefined | null = process.env.REACT_APP_API_DOMAIN;
  readonly basePath: string | null = null;
  readonly STATUS_CODE_SUCCESS = 200;
  readonly STATUS_CODE_UNAUTHORIZED = 401;
  readonly STATUS_CODE_FOBIDDEN = 403;
  readonly STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

  constructor(props: any = null) {
    super(props);
  }

  getUrl = (url: string | null): string => {
    if (url && ['http://', 'https://'].includes(url)) return url;
    return `${this.domain}${this.basePath ? `/${this.basePath}` : ''}${
      url ? `/${url}` : ''
    }`;
  };

  getHeaders = (): object => ({
    ContentType: 'application/json; charset=UTF-8',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
  });

  get = (
    url: string | null = null,
    params: object | null = {},
    headers: object | null = {}
  ) => {
    return axios.get(this.getUrl(url), {
      params,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      withCredentials: true,
    });
  };

  getProgress = (
    url: string | null = null,
    params: object | null = {},
    headers: object | null = {},
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return axios.get(this.getUrl(url), {
      params,
      timeout: 40000,
      onDownloadProgress: (progressEvent: any) =>
        onDownloadProgress(progressEvent),
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      withCredentials: true,
    });
  };

  postProgress = async (
    url: string | null = null,
    params: any | null = {},
    headers: object | null = {},
    key: string | null,
    onPostProgress: (progressEvent: any, key: string | null) => void
  ) => {
    return await axios({
      method: 'post',
      url: this.getUrl(url),
      data: params,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      onUploadProgress: data => {
        onPostProgress(data, key);
      },
      withCredentials: true,
    }).then(res => res?.data);
  };

  post = async (
    url: string | null = null,
    params: any | null = {},
    headers: object | null = {}
  ) => {
    return await axios({
      method: 'post',
      url: this.getUrl(url),
      data: params,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      withCredentials: true,
    }).then(res => res?.data);
  };

  patch = async (
    url: string | null = null,
    params: any | null = {},
    headers: object | null = {}
  ) => {
    return await axios({
      method: 'patch',
      url: this.getUrl(url),
      data: params,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      withCredentials: true,
    }).then(res => res?.data);
  };

  delete = async (
    url: string | null = null,
    params: any | null = {},
    headers: object | null = {}
  ) => {
    return await axios({
      method: 'delete',
      url: this.getUrl(url),
      data: params,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      withCredentials: true,
    }).then(res => res?.data);
  };
}
