import {getStorage} from '../../utils';
import ApiService from '../api.service';

export default class AuthService extends ApiService {
  readonly basePath = 'auth/v1';

  constructor(props = null) {
    super(props);
  }

  signIn = async (username: string, password: string) => {
    return await this.post('login', {
      email: username,
      password,
    })
      .then(res => res)
      .catch(error => {
        const res = error.toJSON();
        return {
          code: res.status,
          message: res.message,
          errorMessage: res.message,
          data: res,
          success: false,
        };
      });
  };

  signOut = async (headers: object) => {
    return await this.post('logout', {}, headers).then(res => res);
  };

  verification2Fa = async (token: string, code: string) => {
    return await this.post('2fa', {
      token,
      code,
    })
      .then(res => res)
      .catch(error => {
        const res = error.toJSON();
        return {
          code: res.status,
          message: res.message,
          errorMessage: res.message,
          data: res,
          success: false,
        };
      });
  };
}
