/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseApiService from '../baseApi.service';

export default class AllTransactionService extends BaseApiService {
  readonly basePath: string = 'transactions/v1';
  constructor(props = null) {
    super(props);
  }

  exportCSV = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress('exportCSV', params, {}, progressEvent =>
      onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };
}
