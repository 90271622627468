/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useNavigate, useLocation} from 'react-router-dom';

import {path} from '../../../routes/path';
import useAuth from '../../../hooks/useAuth';
import {images} from '../../../constants/image';
import {Input} from '../../../components';

// styles
import {
  Wrapper,
  StyledImage,
  StyleTitle,
  StyledSubTitle,
} from './2FAVerification.style';
import {envRole} from '../../../utils';
import {USER_TYPE} from '../../../constants/UserType';

function FAVerification() {
  const location = useLocation();
  const {token, user = {}} = location.state;
  const navigate = useNavigate();
  const {showNotification, verification2Fa, signIn, awaitGetHomeUrl, signOut} =
    useAuth();
  const {email, password} = user;

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const onVerification2Fa = async (
    values,
    {setErrors, setStatus, setSubmitting}
  ) => {
    setIsLoading(true);
    try {
      await verification2Fa(token, values?.authCode).then(async res => {
        const {success, errorMessage, attemptsLeft, lockdown} = res;
        if (success) {
          setIsLoading(false);
          awaitGetHomeUrl().then(url => {
            if (url !== null) {
              navigate(url);
            } else {
              showNotification({
                message:
                  +envRole === USER_TYPE.ADMINISTRATOR
                    ? "You don't have permission to login!"
                    : 'Incorrect role!',
                variation: 'error',
              });
              signOut();
            }
          });
        } else {
          if (lockdown) {
            showNotification({
              message: 'Your account is locked!',
              variation: 'error',
            });
            return navigate(`/auth/${path.SIGN_IN}`);
          } else {
            const message = `${errorMessage}${
              attemptsLeft
                ? `. ${attemptsLeft} attempt${
                    attemptsLeft > 0 ? 's' : ''
                  } left.`
                : ''
            }`;
            setIsLoading(false);
            setSubmitting(false);
            setErrors({
              authCode: message,
            });
            showNotification({
              message: message,
              variation: 'error',
            });
            if (attemptsLeft === undefined) {
              setIsExpired(true);
            }
          }
        }
      });
    } catch (error) {
      const message = error.message || 'Something went wrong';
      setStatus({success: false});
      setErrors({submit: message});
      setSubmitting(false);
    }
  };

  const resendCode2FA = async handleReset => {
    setIsLoading(true);
    try {
      await signIn(email, password).then(async res => {
        const {success, enabled2FA, token, user} = res;
        if (success) {
          setIsLoading(false);
          if (enabled2FA) {
            setIsExpired(false);
            showNotification({
              message: 'Resend 2FA code success!',
            });
            handleReset();
            return navigate(`/auth/${path.FA_VERITICATION}`, {
              state: {
                token,
                user,
              },
            });
          } else {
            /* By some reason, user disabled config for 2FA mode => redirect user to login again */
            showNotification({
              message: 'Something went wrong. Try to login again!',
              variation: 'error',
            });
            return navigate(`/auth/${path.SIGN_IN}`);
          }
        } else {
          /* Login failed. Can't get new token for 2FA => redirect to login page */
          setIsLoading(false);
          showNotification({
            message: 'Get error when resend code. Try to login again!',
            variation: 'error',
          });
          return navigate(`/auth/${path.SIGN_IN}`);
        }
      });
    } catch (error) {
      const message = error.message || 'Something went wrong';
      showNotification({
        message: message,
        variation: 'error',
      });
    }
  };

  return (
    <React.Fragment>
      <StyledImage>
        <img src={images.logo_sentosa} />
      </StyledImage>
      <Wrapper
        sx={{
          width: '500px',
        }}
      >
        <Helmet title="Sign In" />
        <StyleTitle>
          <Typography component="div" className="title" align="center">
            2-FA Verification
          </Typography>
        </StyleTitle>
        <StyledSubTitle>
          <Typography component="h3" variant="body1" align="center" mt={2}>
            Enter 2-factor authentication code to continue
          </Typography>
        </StyledSubTitle>
        <Formik
          initialValues={{
            authCode: '',
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            authCode: Yup.string()
              .max(255)
              .required('Authentication Code is required'),
          })}
          onSubmit={onVerification2Fa}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Input
                type="text"
                name="authCode"
                label="Authentication Code"
                value={values.authCode}
                placeholder="Enter your authentication code"
                error={Boolean(touched.authCode && errors.authCode)}
                fullWidth
                helperText={touched.authCode && errors.authCode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                loading={isLoading}
              >
                Sign in
              </LoadingButton>
              {isExpired && (
                <LoadingButton
                  className="btn-resend"
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={!isExpired}
                  loading={isLoading}
                  onClick={() => resendCode2FA(handleReset)}
                >
                  Resend Code
                </LoadingButton>
              )}
            </form>
          )}
        </Formik>
        <StyledSubTitle>
          <Typography component="h3" variant="body1" align="center" mt={8}>
            Signing in as: <span>{email || '--'}</span>
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Typography
              component="h3"
              width="120px"
              variant="body1"
              mt={4}
              color="#0075DA"
              fontWeight="450"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/auth/${path.SIGN_IN}`);
              }}
            >
              Change Account
            </Typography>
          </div>
        </StyledSubTitle>
      </Wrapper>
    </React.Fragment>
  );
}

export default FAVerification;
