export type ColumnsType = {
  dataIndex: string;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  firstName: string;
  lastName: string;
  designation: string;
  type: string;
};

export type DataDetail = {
  id?: string;
  firstName?: string;
  lastName?: string;
  designation?: string;
  email?: string;
  type?: number;
  merchantId?: string;
  isFreeze?: boolean;
  phoneNumber?: string | number;
};

export type DataAddNew = {
  firstName?: string;
  lastName?: string;
  designation?: string;
  email?: string;
  type?: number | string;
  password?: number | string;
  islandPartner?: string;
  phoneNumber?: string | number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean
) {
  return {name, path, isCurrentPage};
}
