/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {Modal, Select, Button, Input} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  deleteSearchParams?: () => void;
  merchants?: any;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter,
  deleteSearchParams,
  merchants,
}: ModalFilterProps) {
  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCleanModalForm = () => {
    handleClean();
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'Admin Name'}
            </Typography>
            <Input
              type="text"
              name="adminName_contains"
              placeholder="Enter Admin's Name"
              size="small"
              variant="outlined"
              value={valueFilter.adminName_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              IP Name
            </Typography>
            <Select
              labelId="ipName_contains"
              displayEmpty
              name="ipName_contains"
              variant="outlined"
              onChangeSelect={e => {
                onChange(e);
              }}
              value={valueFilter.ipName_contains}
              placeholder="Select IP Name"
              size="small"
              selectOptions={merchants}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'Email Address '}
            </Typography>
            <Input
              type="text"
              name="email_contains"
              placeholder="Enter Email Address"
              size="small"
              variant="outlined"
              value={valueFilter.email_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'Phone Number'}
            </Typography>
            <Input
              type="number"
              name="phoneNumber_contains"
              placeholder="Enter Phone Number"
              size="small"
              variant="outlined"
              value={valueFilter.phoneNumber_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              Type
            </Typography>
            <Select
              labelId="type_eq"
              displayEmpty
              name="type_eq"
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.type_eq}
              placeholder="Select Type"
              size="small"
              selectOptions={[
                createData('Administrator', '1'),
                createData('Merchant', '2'),
              ]}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleCleanModalForm}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={
                !valueFilter.adminName_contains &&
                !valueFilter.ipName_contains &&
                !valueFilter.email_contains &&
                !valueFilter.phoneNumber_contains &&
                !valueFilter.type_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
