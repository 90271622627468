/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import DateRangePicker, {DateRange} from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// Style
import {
  StyledInputRangePicker,
  Typography,
  StyledTextField,
} from './RangePicker.style';

interface RangePickerProps {
  value?: DateRange<Date>;
  required?: boolean;
  isEmptyValue?: boolean;
  onChange?: (newValue: any) => void;
}

function RangePicker({
  value,
  required = false,
  isEmptyValue = false,
  onChange,
  ...other
}: RangePickerProps) {
  const format = 'dd/MM/yyyy';
  const [isError, setIsError] = useState(false);

  const handleFocus = () => {
    if (required) {
      setIsError(value[0] === null || value[1] === null);
    }
  };

  useEffect(() => {
    if (required) {
      setIsError(isEmptyValue);
    }
  }, [isEmptyValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText=""
        endText=""
        mask="__/__/____"
        inputFormat={format}
        value={value}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <StyledInputRangePicker error={isError}>
            <StyledTextField
              {...startProps}
              size="small"
              error={isError}
              onFocus={handleFocus}
              helperText={isError ? 'Date is required' : null}
              sx={{
                marginTop: isError ? '20px' : null,
              }}
            />
            <Typography>-</Typography>
            <StyledTextField {...endProps} size="small" onFocus={handleFocus} />
          </StyledInputRangePicker>
        )}
        {...other}
      />
    </LocalizationProvider>
  );
}
export default RangePicker;
