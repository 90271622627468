import React from 'react';
import {FormControlLabel, RadioGroup, Radio} from '@mui/material';

type ItemRadioOptionsProps = {
  key: string;
  value: string | number;
};

interface RadioGroupProps {
  values?: string | number;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  options?: Array<ItemRadioOptionsProps>;
  name?: string;
}

function RadioGroups({
  values,
  options,
  onChange,
  name,
  ...other
}: RadioGroupProps) {
  return (
    <RadioGroup value={values} onChange={onChange} row {...other} name={name}>
      {options?.map((item, index) => (
        <FormControlLabel
          key={index}
          value={item.value}
          control={<Radio />}
          label={item.key}
        />
      ))}
    </RadioGroup>
  );
}
export default RadioGroups;
