/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit, Delete, FilterList as FilterListIcon} from '@mui/icons-material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {isEmpty, sortBy} from 'lodash';
import useAuth from '../../hooks/useAuth';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import success from '../../constants/success';
import queryString from 'query-string';
import {
  Breadcrumbs,
  Button,
  ModalConfirm,
  Table,
  Tooltips,
  Chip,
  EnhancedTableToolbar,
} from '../../components';
import {ColorType} from '../../types/typeChip';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import {
  formatDate,
  formatLocalDateFromUTCTime,
  getEndOfDate,
  getStartOfDate,
} from '../../utils';
import IslanderService from '../../services/Islander/islander.service';
import PointMultiplierEventService from '../../services/PointMultiplierEvent/pointMultiplierEvent.service';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import ModalFilter from './components/ModalFilter/ModalFilter';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
  StyledStack,
} from './LimitedEvent.style';
import {path} from '../../routes/path';
import {NavLink} from 'react-router-dom';
import {DateRange} from '@mui/lab/DateRangePicker';

const LimitedEvent = (props: any) => {
  const formatDateTime = 'DD/MM/YYYY HH:mm';
  const format = 'DD/MM/YYYY';

  // states
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [eventItem, setEventItem] = useState(null);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loadButtonSave, setLoadButtonSave] = useState(false);
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [valueFilter, setValueFilter] = useState({
    title_contains: searchParams.get('title_contains') ?? '',
    ipName_contains: searchParams.get('ipName_contains') ?? '',
    creationID_eq: searchParams.get('creationID_eq') ?? '',
    startDateTime_gte: searchParams.get('startDateTime_lte') ?? '',
    endDateTime_lte: searchParams.get('endDateTime_gte') ?? '',
    pointsMultiplier_eq: searchParams.get('pointsMultiplier_eq') ?? '',
  });
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    pointsMultiplier_eq: searchParamsObject.pointsMultiplier_eq ?? undefined,
    status_eq: searchParamsObject.status_eq ?? undefined,
    title_contains: searchParamsObject.title_contains ?? undefined,
    ipName_contains: searchParamsObject.ipName_contains ?? undefined,
    creationID_eq: searchParamsObject.creationID_eq ?? undefined,
    endDateTime_lt: searchParamsObject?.endDateTime_lt ?? undefined,
    startDateTime_lte: searchParamsObject?.startDateTime_lte ?? undefined,
    endDateTime_gte: searchParamsObject?.endDateTime_gte ?? undefined,
    startDateTime_gt: searchParamsObject?.startDateTime_gt ?? undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'creationId',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      dataIndex: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      dataIndex: 'pointsMultiplier',
      numeric: false,
      disablePadding: false,
      label: 'Points Multiplier',
    },
    {
      dataIndex: 'merchantIds',
      numeric: false,
      disablePadding: false,
      label: 'Merchants',
    },
    {
      dataIndex: 'numberOfTransaction',
      numeric: true,
      disablePadding: false,
      label: 'Number of Transactions',
    },
    {
      dataIndex: 'totalPointsIssued',
      numeric: false,
      disablePadding: false,
      label: 'Total Bonus Points Issued',
    },
    {
      dataIndex: 'startDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Start Date Time',
    },
    {
      dataIndex: 'endDateTime',
      numeric: false,
      disablePadding: false,
      label: 'End Date Time',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Limited event status',
    },
    {
      dataIndex: '',
      numeric: false,
      disablePadding: false,
      label: '',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const toggleModal = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const getListPointMultiplierEvent = () => {
    setIsLoading(true);
    new PointMultiplierEventService()
      .getAll({...params})
      .then(res => {
        if (res.success) {
          if (!isEmpty(res.data)) {
            setDataSource(res.data.items);
            setTotalPage(res.data.total);
            setIsLoading(false);
          }
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const getMerchants = () => {
    new IslanderService()
      .getAll({_size: 99999, _page: 0, _sort: 'name:asc'})
      .then(r => {
        if (r.success === true) {
          setMerchants(r.data.items.filter(item => item.status === 1));
        }
      })
      .catch(e => {
        return;
      });
  };

  const onDelete = () => {
    setIsLoading(true);
    new PointMultiplierEventService().delete(selectedId).then(res => {
      if (res.success) {
        showNotification({
          message: success.DELETE_SUCCESS,
        });
        toggleModalConfirmDelete();
        getListPointMultiplierEvent();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
        setIsLoading(false);
      }
    });
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportLimitedEvent({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleFilter = () => {
    if (valueFilter?.title_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        title_contains: valueFilter?.title_contains,
      }));
    }
    if (valueFilter?.pointsMultiplier_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointsMultiplier_eq: valueFilter?.pointsMultiplier_eq,
      }));
    }
    if (valueFilter?.creationID_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        creationID_eq: valueFilter?.creationID_eq,
      }));
    }
    if (valueFilter?.ipName_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        ipName_contains: valueFilter?.ipName_contains,
      }));
    }
    if (valueRangePicker[0] && valueRangePicker[1]) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        startDateTime_gte: getStartOfDate(valueRangePicker[0], format),
        endDateTime_lte: getEndOfDate(valueRangePicker[1], format),
      }));
    } else if (
      valueFilter?.startDateTime_gte !== '' &&
      valueFilter?.endDateTime_lte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        startDateTime_gte: valueFilter?.startDateTime_gte,
        endDateTime_lte: valueFilter?.endDateTime_lte,
      }));
    }
    toggleModalFilter();
    // handleClean();
  };

  const handleClean = () => {
    setValueFilter({
      title_contains: '',
      ipName_contains: '',
      creationID_eq: '',
      startDateTime_gte: '',
      endDateTime_lte: '',
      pointsMultiplier_eq: '',
    });
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'title_contains') {
      deleteSearchParams('title_contains');
      setValueFilter({
        ...valueFilter,
        title_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        title_contains: undefined,
      }));
    }

    if (type === 'ipName_contains') {
      deleteSearchParams('ipName_contains');
      setValueFilter({
        ...valueFilter,
        ipName_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        ipName_contains: undefined,
      }));
    }
    if (type === 'pointsMultiplier_eq') {
      deleteSearchParams('pointsMultiplier_eq');
      setValueFilter({
        ...valueFilter,
        pointsMultiplier_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointsMultiplier_eq: undefined,
      }));
    }
    if (type === 'creationID_eq') {
      deleteSearchParams('creationID_eq');
      setValueFilter({
        ...valueFilter,
        creationID_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        creationID_eq: undefined,
      }));
    }
    if (type === 'date') {
      deleteSearchParams('startDateTime_gte');
      deleteSearchParams('endDateTime_lte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        startDateTime_gte: '',
        endDateTime_lte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        startDateTime_gte: undefined,
        endDateTime_lte: undefined,
      }));
    }
  };

  const renderLinkAllTransaction = value => {
    return (
      <NavLink
        to={`/${path.ALL_TRANSACTION}?_page=0&_size=10&status_eq=1&pointMultiplierEventCreationId_eq=${value.creationId}`}
        style={{textDecoration: 'none'}}
      >
        All transactions
      </NavLink>
    );
  };

  const renderStatus = value => {
    if (value.status === 2) {
      return <Chip label="Deleted" color={'error' as ColorType} />;
    }
    if (
      new Date().toISOString() <= new Date(value?.endDateTime).toISOString() &&
      new Date().toISOString() >= new Date(value?.startDateTime).toISOString()
    ) {
      return <Chip label="Available" color={'success' as ColorType} />;
    }
    if (
      new Date(value?.startDateTime).toISOString() >= new Date().toISOString()
    ) {
      return <Chip label="Coming" color={'warning' as ColorType} />;
    }
    if (
      new Date(value?.endDateTime).toISOString() <= new Date().toISOString()
    ) {
      return <Chip label="Expired" color={'default' as ColorType} />;
    }
  };

  useEffect(() => {
    getMerchants();
  }, []);

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );

    getListPointMultiplierEvent();
  }, [params]);

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                setEventItem(value);
                toggleModal(!isModalAddNew);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              onClick={() => {
                setSelectedId(value);
                toggleModalConfirmDelete();
              }}
              size="medium"
            >
              <Delete style={StyledIcon} color="error" />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Limited Event" />

      <ModalAddNew
        idLimitedEvent={eventItem}
        visible={isModalAddNew}
        listMerchants={merchants}
        onClose={() => {
          setEventItem(null);
          toggleModal(!isModalAddNew);
        }}
        reloadPage={getListPointMultiplierEvent}
      />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        deleteSearchParams={deleteSearchParams}
        merchants={merchants}
        setValueRangePicker={setValueRangePicker}
        valueRangePicker={valueRangePicker}
      />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">
            Are you sure you want to delete event?
          </Typography>
        }
        // subTitle={<Typography>Voucher will not be able to login.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={onDelete}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Limited Event
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Limited Event', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('title_contains') !== null && (
              <Chip
                label={searchParams.get('title_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('title_contains');
                }}
              />
            )}
            {searchParams.get('pointsMultiplier_eq') !== null && (
              <Chip
                label={searchParams.get('pointsMultiplier_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointsMultiplier_eq');
                }}
              />
            )}
            {searchParams.get('ipName_contains') !== null && (
              <Chip
                label={
                  searchParams.get('ipName_contains')
                    ? searchParams.get('ipName_contains')
                    : ''
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('ipName_contains');
                }}
              />
            )}
            {searchParams.get('creationID_eq') !== null && (
              <Chip
                label={searchParams.get('creationID_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('creationID_eq');
                }}
              />
            )}
            {valueRangePicker[0] !== null &&
              valueRangePicker[1] !== null &&
              searchParams.get('startDateTime_gte') !== null &&
              searchParams.get('endDateTime_lte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('startDateTime_gte'),
                    format
                  )} -
                    ${formatDate(searchParams.get('endDateTime_lte'), format)}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}
          </StyledStack>
          <StyledStack spacing={3}>
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </StyledStack>

          <Button
            onClick={() => {
              toggleModal();
              setEventItem(null);
            }}
            loading={isLoading}
          >
            Add New Event
          </Button>
          <Tooltips title="Filter list" sx={{marginLeft: '10px'}}>
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        enableDeletedTab
        isAddNew
        setParams={setParams}
      />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no all event(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row.creationId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="left">{row.pointsMultiplier}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.merchantIds.map(r => r.merchantName).join(', ')}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {row.numberOfTransaction}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {row.totalPointsIssued}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatLocalDateFromUTCTime(
                      row.startDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatLocalDateFromUTCTime(
                      row.endDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {renderStatus(row)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {renderLinkAllTransaction(row)}
                  </TableCell>
                  <TableCell component="th" scope="row" width={100}>
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
};

export default LimitedEvent;
