import styled from 'styled-components/macro';
import {TableRow, Typography as MuiTypography} from '@mui/material';
import {display} from '@mui/system';

const StyledAction = styled.div`
  display: flex;
`;

const StyledIcon = {
  width: '18px',
  height: '18px',
};
const StyledTableRow = styled(TableRow)``;
const Typography = styled(MuiTypography)(display);

export {StyledTableRow, StyledAction, StyledIcon, Typography};
