/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Edit} from '@mui/icons-material';
import {NavLink, useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {FilterList as FilterListIcon} from '@mui/icons-material';

import {ColumnsType, typeRoutes} from '../../types/typeIslandersMembers';
import {path} from '../../routes/path';
import {Breadcrumbs, Button, Chip, Table, Tooltips} from '../../components';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import IslanderService from '../../services/Islander/islander.service';
import useAuth from '../../hooks/useAuth';
import {envRole, hasPermission} from '../../utils';
import ModalFilter from './components/ModalFilter/ModalFilter';
import {ColorType} from '../../types/typeChip';
import queryString from 'query-string';

// style
import {
  Typography,
  Divider,
  StyledTableRow,
  StyledAction,
  StyledIcon,
  StyledStack,
} from './IslandPartner.style';
import {USER_TYPE} from '../../constants/UserType';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import success from '../../constants/success';

const islandPartnerTitle = `Island Partner${
  +envRole === USER_TYPE.ADMINISTRATOR ? 's' : ''
}`;

function IslandPartner() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {getUser, showNotification} = useAuth();
  const {merchantId, email} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canCreate = hasPermission(path.ISLAND_PARTNERS, 'create');
  const canUpdate = hasPermission(path.ISLAND_PARTNERS, 'update');

  const navigate = useNavigate();

  // state
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [dense] = useState(false);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [name_contains, setName_contains] = useState(
    searchParams.get('name_contains') ?? ''
  );
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
    id_eq: undefined,
    name_contains: searchParamsObject.name_contains ?? undefined,
  });
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'merchant_name',
      numeric: false,
      disablePadding: false,
      label: 'Island Partner Name',
    },
    {
      dataIndex: 'totalPoints',
      numeric: true,
      disablePadding: false,
      label: 'Total Points Issued',
      helperText:
        "The sum of all merchant's point values whose operation is add (i.e. operation = 1) minus all point values whose operation is deduct (i.e. operation = 2)",
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  navigate(`/${path.ISLAND_PARTNERS}/${value}`, {
                    state: {
                      ...queryString.parse(searchParams.toString()),
                    },
                  });
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                navigate(`/${path.ISLAND_PARTNERS}/${value}`, {
                  state: params,
                });
              }}
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const toggleModal = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService()
      .getAll({
        ...params,
        id_eq: role === 'merchant' ? merchantId : undefined,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res.data.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        // Get api error => show notification or no items listing
        setError(error);
      });
  };

  const handleClean = () => {
    setName_contains('');
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = type => {
    if (type === 'name_contains') {
      deleteSearchParams('name_contains');
      setName_contains('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleFilter = () => {
    if (name_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        name_contains: name_contains,
      }));
    }
    toggleModalFilter();
    // handleClean();
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportIslandPartner({email: email, name_contains: params.name_contains})
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.gtag('js', new Date());
  //     window.gtag('config', 'G-EQ3JMF90YJ');
  //   }
  // }, []);

  useEffect(() => {
    if (role === 'merchant') {
      navigate(`/${path.ISLAND_PARTNERS}/${merchantId}`);
    }
  }, [role]);

  useEffect(() => {
    if (role && role !== 'merchant') {
      setSearchParams(queryString.stringify(params));
      getIslanderPartners();
    }
  }, [params, role]);

  return (
    <React.Fragment>
      <Helmet title={islandPartnerTitle} />
      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        name_contains={name_contains}
        setName_contains={setName_contains}
      />
      <ModalAddNew
        visible={isModalAddNew}
        onClose={toggleModal}
        reloadPage={getIslanderPartners}
      />
      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Island Partners
          </Typography>

          <Breadcrumbs routes={[typeRoutes(islandPartnerTitle, null, true)]} />
        </Grid>

        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('name_contains') !== null && (
              <Chip
                label={searchParams.get('name_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('name_contains');
                }}
              />
            )}
          </StyledStack>
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          {!!canCreate && (
            <Button onClick={toggleModal} loading={isLoading}>
              Add new
            </Button>
          )}

          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        // onRequestSort={onRequestSort}
        rowsPerPage={params._size}
        textNodata="There are no island partner(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        {!error && (
          <TableBody>
            {dataSource?.map(row => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right" width={250}>
                    {row.totalPoints}
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default IslandPartner;
