import styled from 'styled-components';
import {spacing} from '@mui/system';
import TextField from '@mui/material/TextField';

import {Typography as MuiTypography} from '@mui/material';

const Typography = styled(MuiTypography)`
  ${spacing}

  padding: 0px 10px 0px 10px;
`;

const StyledInputRangePicker = styled('div')<{error: boolean}>`
  border: 1px solid ${props => (props.error === true ? '#ba000d' : '#c4c4c4')};
  box-sizing: border-box;
  border-radius: 5px;
  height: 37px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 10px 0px;

  :hover {
    border-color: ${props => (props.error === true ? '#ba000d' : '#000000')};
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    & > fieldset {
      border: none;
    }
  }
  .MuiOutlinedInput-input {
    /* text-align: center; */
  }
`;

export {StyledInputRangePicker, Typography, StyledTextField};
