export type ColumnsType = {
  dataIndex: string | null;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  helperText?: string;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  name: string;
  email: string;
  points: number;
  tierName: string;
};

export type DataDetail = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  mobileNumber: string;
  jubileeVerified: boolean;
  islandPartner: string;
  createdBy?: number;
  createdDateTime?: string;
  id?: string;
  promotedByAdmin?: boolean;
  promotedDate?: string;
  status?: number;
  supportTierIds?: number | string;
  tierExpireDateTime?: string;
  tierId?: number;
  tierName?: string;
  totalDailyPoints?: number;
  totalPoints?: number;
  totalTierPoints?: number;
  updatedBy?: number;
  updatedDateTime?: string;
  userId?: string;
  referralCode?: string;
  codeUnique?: string;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean
) {
  return {name, path, isCurrentPage};
}
