import styled, {css} from 'styled-components/macro';
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
  Typography,
} from '@mui/material';

import {spacing} from '@mui/system';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';

import '../../vendor/perfect-scrollbar.css';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
  .MuiDrawer-paper {
    .MuiButtonBase-root {
      justify-content: flex-start;
    }
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)};
  padding-right: ${props => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`;

// const BrandIcon = styled(Logo)`
//   margin-right: ${props => props.theme.spacing(2)};
//   color: ${props => props.theme.sidebar.header.brand.color};
//   fill: ${props => props.theme.sidebar.header.brand.color};
//   width: 32px;
//   height: 32px;
// `;

const Footer = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}
    ${props => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const baseScrollbar = css`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)};
  padding-bottom: ${props => props.theme.spacing(2.5)};
`;

const Title = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  font-size: ${props => props.theme.typography.caption.fontSize};
  padding: ${props => props.theme.spacing(4)} ${props => props.theme.spacing(7)}
    ${props => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const StyledImage = styled.div`
  img {
    width: 118px;
  }
`;
export {
  Box,
  Drawer,
  Brand,
  Footer,
  FooterText,
  FooterSubText,
  Scrollbar,
  PerfectScrollbar,
  Items,
  Title,
  StyledImage,
};
