import React from 'react';
import {CircularProgress} from '@mui/material';

// styles
import {Root} from './Loader.style';

function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loader;
