/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {images} from '../../../constants/image';
import {path} from '../../../routes/path';
import {Input} from '../../../components';
import ResetPasswordService from '../../../services/User/reset_password.service';

// styles
import {Wrapper, Alert, StyledImage} from './ForgotPassword.style';

function ForgotPassword() {
  const navigate = useNavigate();
  // states
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values, {setErrors, setStatus, setSubmitting}) => {
    setIsLoading(true);
    try {
      await new ResetPasswordService()
        .post('requestResetPassword', {email: values.email})
        .then(res => {
          setIsLoading(false);
          if (res?.success) {
            navigate(`/${path.AUTH}/${path.RESET_PASSWORD_SUCCESS}`, {
              state: {email: values.email},
            });
          } else {
            setErrors({submit: res?.errorMessage});
          }
        });
    } catch (error: any) {
      const message = error.message || 'Something went wrong';
      setIsLoading(false);
      setStatus({success: false});
      setErrors({submit: message});
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <StyledImage>
        <img src={images.logo_sentosa} />
      </StyledImage>
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <Formik
          initialValues={{
            email: '',
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Input
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <LoadingButton
                type="submit"
                fullWidth
                className="btn-reset_password"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                loading={isLoading}
              >
                Reset password
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Wrapper>
    </React.Fragment>
  );
}

export default ForgotPassword;
