import styled from 'styled-components/macro';
import {
  Paper,
  Alert as MuiAlert,
  TextField as MuiTextField,
  FormControlLabel,
} from '@mui/material';
import {spacing} from '@mui/system';

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(7)};
  max-width: 456px;
  max-height: 411px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(7)};
  }
  .btn-forgot_password {
    margin-top: 10px;
  }
`;
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{my?: number}>(spacing);

const StyledImage = styled.div`
  img {
    max-width: 427px;
    max-height: 187px;
  }
`;

const StyleTitle = styled.div`
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const StyledSubTitle = styled.div`
  margin-bottom: 16px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 12px;
`;

export {
  Wrapper,
  Alert,
  TextField,
  StyledImage,
  StyleTitle,
  StyledSubTitle,
  StyledFormControlLabel,
};
