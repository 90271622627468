import {ModalConfirm, ModalSuccess} from '../../../components';
import ModalEditPoints from '../components/ModaEditPoints/ModalEditPoints';
import ModalEditTier from '../components/ModalEditTier/ModalEditTier';
import {useNavigate} from 'react-router-dom';
import {path} from '../../../routes/path';

// styles
import {Typography} from './IslanderMemberDetail.style';

interface AllModalProps {
  isModalConfirm?: boolean;
  isModalSuccess?: boolean;
  isModalConfirmDelete?: boolean;
  isModalSuccessDelete?: boolean;
  toggleModalConfirm?: () => void;
  toggleModalSuccess?: () => void;
  toggleModalConfirmDelete?: () => void;
  toggleModalSuccessDelete?: () => void;
  setModal?: (any) => void;
  onEditTier?: () => void;
  onEditPoints?: () => void;
  isModalEditTier?: boolean;
  isModalEditPoints?: boolean;
  isLoading?: boolean;
}

export function AllModal({
  isModalConfirm,
  toggleModalConfirm,
  isModalSuccess,
  toggleModalSuccess,
  setModal,
  isModalConfirmDelete,
  toggleModalConfirmDelete,
  toggleModalSuccessDelete,
  isModalSuccessDelete,
  isModalEditTier,
  onEditTier,
  isModalEditPoints,
  onEditPoints,
  isLoading,
}: AllModalProps) {
  const navigate = useNavigate();

  return (
    <>
      <ModalConfirm
        visible={isModalConfirm}
        title={
          <Typography variant="h4">
            You are resetting the member’s password
          </Typography>
        }
        subTitle={
          <Typography>
            Your member will not be able to login with the old password.
          </Typography>
        }
        onClose={toggleModalConfirm}
        onConfirm={toggleModalSuccess}
        isLoading={isLoading}
      />

      <ModalSuccess
        visible={isModalSuccess}
        title={
          <Typography variant="h4">Reset password successfully</Typography>
        }
        subTitle={
          <>
            <Typography>Member’s password has been changed.</Typography>
            <Typography>
              Tell them to check their email and set a new password.
            </Typography>
          </>
        }
        onClose={() =>
          setModal(preState => ({
            ...preState,
            isModalSuccess: false,
          }))
        }
      />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">You are deleting the account</Typography>
        }
        subTitle={
          <Typography>
            Your member will not be able to login and it is not recoverable upon
            deletion.
          </Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={toggleModalSuccessDelete}
        isLoading={isLoading}
      />

      <ModalSuccess
        visible={isModalSuccessDelete}
        title={<Typography variant="h4">Delete member successfully</Typography>}
        subTitle={<Typography>Member acount deleted</Typography>}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: false,
          }));
          navigate(`/${path.ISLAND_MEMBERS}`);
        }}
      />

      <ModalEditTier
        visible={isModalEditTier}
        onEditTier={onEditTier}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalEditTier: false,
          }));
        }}
      />

      <ModalEditPoints
        visible={isModalEditPoints}
        onEditPoints={onEditPoints}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalEditPoints: false,
          }));
        }}
      />
    </>
  );
}
