/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  Input,
  RangePicker,
} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
import {isEmpty} from 'lodash';

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  deleteSearchParams?: () => void;
  setValueRangePicker?: () => void;
  valueRangePicker?: any;
  merchants?: any;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter,
  deleteSearchParams,
  merchants,
  setValueRangePicker,
  valueRangePicker,
}: ModalFilterProps) {
  const [merchantOptions, setMerchantOptions] = useState([]);
  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCleanModalForm = () => {
    handleClean();
    setValueRangePicker([null, null]);
  };

  const onChangeRangePicker = value => {
    setValueRangePicker(value);
  };

  useEffect(() => {
    if (isEmpty(merchants)) return;
    const listMerchantOption = merchants.reduce((items, item) => {
      items.push({
        label: item.name || '-',
        value: item.name,
      });
      return items;
    }, []);
    setMerchantOptions(listMerchantOption ?? []);
  }, [merchants]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY LIMITED EVENT TITLE
            </Typography>
            <Input
              type="text"
              name="title_contains"
              placeholder="Enter Limited Event Title"
              size="small"
              variant="outlined"
              value={valueFilter.title_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY LIMITED EVENT ID
            </Typography>
            <Input
              type="text"
              name="creationID_eq"
              placeholder="Enter Limited Event ID"
              size="small"
              variant="outlined"
              value={valueFilter.creationID_eq}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              IP NAME
            </Typography>
            <Select
              labelId="ipName_contains"
              displayEmpty
              name="ipName_contains"
              variant="outlined"
              onChangeSelect={e => {
                onChange(e);
              }}
              value={valueFilter.ipName_contains}
              placeholder="Select IP Name"
              size="small"
              selectOptions={merchantOptions}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY POINTS MULTIPLIER
            </Typography>
            <Input
              type="number"
              name="pointsMultiplier_eq"
              placeholder="Enter Points Multiplier"
              size="small"
              variant="outlined"
              value={valueFilter.pointsMultiplier_eq}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY LIMITED EVENT DATE RANGE
            </Typography>
            <RangePicker
              value={valueRangePicker}
              onChange={onChangeRangePicker}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleCleanModalForm}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={
                !valueFilter.title_contains &&
                !valueFilter.ipName_contains &&
                !valueFilter.creationID_eq &&
                !valueFilter.pointsMultiplier_eq &&
                !valueRangePicker[0] &&
                !valueRangePicker[1]
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
