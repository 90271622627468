/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Formik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {Typography, Checkbox} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import {path} from '../../../routes/path';
import {images} from '../../../constants/image';
import {Input, InputPassword} from '../../../components';
import {MAX_LOGIN_ATTEMPTS} from '../../../constants';
import {envRole, formatDate} from '../../../utils';

// styles
import {
  Wrapper,
  StyledImage,
  StyleTitle,
  StyledSubTitle,
  StyledFormControlLabel,
} from './SignIn.style';
import {USER_TYPE} from '../../../constants/UserType';

function SignIn() {
  // const authService = new AuthService();
  const navigate = useNavigate();
  const {
    signIn,
    showNotification,
    lockedLogin,
    getLockedLoginTime,
    awaitGetHomeUrl,
    signOut,
  } = useAuth();
  const lockedLoginTime = getLockedLoginTime();
  const formatDateTime = 'HH:mm DD/MM/YYYY';
  const format = 'DD/MM/YYYY';
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [attempts, setAttempts] = useState(0);

  const onSignIn = async (values, {setErrors, setStatus, setSubmitting}) => {
    setIsLoading(true);
    try {
      await signIn(values.email, values.password).then(async res => {
        const {success, enabled2FA, lockedDateTime2FA, token, user} = res;
        if (success) {
          setIsLoading(false);
          if (lockedDateTime2FA) {
            const message = `Your account is locked to ${moment
              .tz(lockedDateTime2FA, format, moment.tz.guess())
              .format(formatDateTime)}`;
            showNotification({
              message,
              variation: 'error',
            });
            setErrors({
              email: message,
            });
          } else {
            if (!enabled2FA) {
              awaitGetHomeUrl().then(url => {
                if (url !== null) {
                  navigate(url);
                } else {
                  showNotification({
                    message:
                      +envRole === USER_TYPE.ADMINISTRATOR
                        ? "You don't have permission to login!"
                        : 'Incorrect role!',
                    variation: 'error',
                  });
                  signOut();
                }
              });
            } else {
              return navigate(`/auth/${path.FA_VERITICATION}`, {
                state: {
                  token,
                  user,
                },
              });
            }
          }
        } else {
          setAttempts(attempts + 1);
          const attemptsLeft = MAX_LOGIN_ATTEMPTS - (attempts + 1);
          setErrors({
            email: `You have ${attemptsLeft} attempt${
              attemptsLeft > 0 ? 's' : ''
            } left`,
          });
          setIsLoading(false);
          setSubmitting(false);
          if (attemptsLeft > 0) {
            showNotification({
              message: 'Login failed! Please check your email and password!',
              variation: 'error',
            });
          }
        }
      });
    } catch (error: any) {
      const message = error.message || 'Something went wrong';
      setStatus({success: false});
      setErrors({submit: message});
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (attempts >= MAX_LOGIN_ATTEMPTS && !lockedLoginTime) {
      const time = new Date();
      time.setMinutes(time.getMinutes() + 30);
      lockedLogin(time);
    }
  }, [attempts]);

  return (
    <React.Fragment>
      <StyledImage>
        <img src={images.logo_sentosa} />
      </StyledImage>
      <Wrapper>
        <Helmet title="Sign In" />
        {!(attempts >= MAX_LOGIN_ATTEMPTS) && !lockedLoginTime && (
          <>
            <StyleTitle>
              <Typography component="div" className="title" align="center">
                Welcome to Islander Program
                {+envRole === USER_TYPE.MERCHANT ? <br /> : ' '}
                {`${
                  +envRole === USER_TYPE.ADMINISTRATOR
                    ? 'Admin'
                    : 'Island Partner'
                }`}{' '}
                Portal!
              </Typography>
            </StyleTitle>
            <StyledSubTitle>
              <Typography component="h3" variant="body1" align="center" mt={2}>
                Sign in to your account to continue
              </Typography>
            </StyledSubTitle>
            <Formik
              initialValues={{
                email: '',
                password: '',
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required'),
              })}
              onSubmit={onSignIn}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    name="email"
                    label="Email Address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    placeholder="Enter your email"
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputPassword
                    name="password"
                    label="Password"
                    value={values.password}
                    placeholder="Enter your password"
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <StyledFormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    loading={isLoading}
                  >
                    Sign in
                  </LoadingButton>
                  <LoadingButton
                    className="btn-forgot_password"
                    component={Link}
                    to={`/${path.AUTH}/${path.FORGOT_PASSWORD}`}
                    fullWidth
                    color="primary"
                    type="submit"
                    loading={isLoading}
                  >
                    Forgot password
                  </LoadingButton>
                </form>
              )}
            </Formik>
          </>
        )}
        {(attempts >= MAX_LOGIN_ATTEMPTS || lockedLoginTime) && (
          <>
            <StyleTitle>
              <Typography component="div" className="title" align="center">
                Your account is locked down!
              </Typography>
            </StyleTitle>
            <StyledSubTitle>
              <Typography component="h3" variant="body1" align="center" mt={2}>
                Try to login after{' '}
                <b>{formatDate(lockedLoginTime, formatDateTime)}</b>
              </Typography>
            </StyledSubTitle>
          </>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
