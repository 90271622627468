/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';

import {images} from '../../../../../constants/image';
import {TableBody, TableCell} from '@mui/material';
import {Modal, Button, Table} from '../../../../../components';
import {ColumnsType, RowCodeType} from '../../../../../types/typePartnership';
import useAuth from '../../../../../hooks/useAuth';
import ReferralPartnershipService from '../../../../../services/ReferralProgramme/referral-partnership.service';
import {StyledTableRow} from '../../Partnership.style';

// style
import {
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalCodeList.style';

interface ModalCodeListProps {
  visible: boolean;
  idDetail: string;
  onClose?: () => void;
}

function ModalCodeList({visible, idDetail, onClose}: ModalCodeListProps) {
  const {showNotification} = Object.assign(useAuth());

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Generated Code',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
  ];

  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [focusId, setFocusId] = useState(null);
  const [params, setParams] = useState({
    _page: 0,
    _size: 25,
    status_eq: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [dataSource, setDataSource] = useState<Array<RowCodeType>>([]);

  const copyGeneratedCode = () => {
    const codes = dataSource.reduce((result, item) => {
      return `${result}${item.code},`;
    }, '');
    navigator.clipboard.writeText(codes);
    showNotification({
      message: 'Referral Codes Copied to clipboard',
    });
  };

  const getGenerateCode = () => {
    setIsLoading(true);
    new ReferralPartnershipService()
      .getGeneratedCodeList(idDetail, params)
      .then(res => {
        if (res && res.data && res.data.items) {
          setDataSource(res?.data?.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  useEffect(() => {
    if (visible) {
      getGenerateCode();
    }
  }, [visible, params]);

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 25),
    }));
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>List of unique generated codes</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent style={{height: '350px', overflowY: 'scroll'}}>
          <Table
            dataSource={dataSource}
            columns={columns}
            page={params._page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={params._size}
            order={order}
            textNodata="There are no voucher(s) matching the filter."
            orderBy={orderBy}
            isLoading={isLoading}
            dense={dense}
            isMultiCheckbox={false}
            count={totalPage}
          >
            <TableBody>
              {dataSource?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onMouseEnter={() => setFocusId(row.id)}
                    onMouseLeave={() => setFocusId(null)}
                  >
                    <TableCell align="left">{row.code}</TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.used === false ? 'Not Used' : 'Used'}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-2">
            <Button
              onClick={copyGeneratedCode}
              disabled={dataSource.length === 0}
            >
              Copy to Clipboard
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalCodeList;
