import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
} from '@mui/material';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  ${spacing};
  .MuiBreadcrumbs-li {
    > a {
      color: #1565c0;
    }
  }
`;
const Typography = styled(MuiTypography)`
  ${spacing};
`;

export {StyledBreadcrumbs, Typography};
