/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {
  Modal,
  Button,
  Input,
  MultipleSelectCheckbox,
} from '../../../../components';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {RangePicker, DateTimePicker} from '../../../../components';
// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';
import {DateRange} from '@mui/lab/DateRangePicker';
import {isEmpty} from 'lodash';
import PointMultiplierEventService from '../../../../services/PointMultiplierEvent/pointMultiplierEvent.service';
import {formatDateTime} from '../../../../utils';

interface ModalAddNewProps {
  idLimitedEvent?: any;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
  listMerchants?: any;
}

interface DateTimeRange {
  startDateTime?: Date | null;
  endDateTime?: Date | null;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({
  idLimitedEvent,
  visible,
  onClose,
  reloadPage,
  listMerchants,
}: ModalAddNewProps) {
  const format = 'DD/MM/YYYY HH:mm';

  const {showNotification} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyDate, setEmptyDate] = useState(false);
  const [merchantOptions, setMerchantOptions] = useState([
    {title: 'All IP', value: -1},
  ]);
  const [merchantsSelected, setMerchantsSelected] = useState<any>([]);
  const [limitedEventDetail, setLimitedEventDetail] = useState<any>();
  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startDateTime: null,
    endDateTime: null,
  });
  const [isEmptyValue, setIsEmptyValue] = useState(false);

  // states
  const [initialValues, setInitialValues] = useState({
    title: '',
    pointsMultiplier: 2,
    merchantIds: [],
    qrExpiredDate: qrExpiredDate.toISOString(),
    refreshedDate: refreshedDate.toISOString(),
    startDateTime: '',
    endDateTime: '',
  });
  // const [valueFile, setValueFile] = useState();

  // const onChangeUploadFile = (event: any) => {
  //   setValueFile(event.target.files[0]);
  // };

  const getDataDetail = () => {
    if (idLimitedEvent) {
      // Get detail
      new PointMultiplierEventService().getOne(idLimitedEvent).then(res => {
        if (res.status === 200) {
          if (res.data && res.data.data) {
            const detail = res.data.data;
            setInitialValues({
              ...initialValues,
              title: detail.title,
              pointsMultiplier: detail.pointsMultiplier,
              merchantIds: !isEmpty(detail.merchantIds)
                ? detail.merchantIds.map(item => item.merchantId)
                : [],
              startDateTime: formatDateTime(
                new Date(detail.startDateTime),
                format
              ),
              endDateTime: formatDateTime(new Date(detail.endDateTime), format),
            });
            setValueRangePicker({
              startDateTime: detail.startDateTime,
              endDateTime: detail.endDateTime,
            });
            setMerchantsSelected(
              !isEmpty(detail.merchantIds)
                ? detail.merchantIds.map(item => ({
                    title: item?.merchantName,
                    value: item.merchantId,
                  }))
                : []
            );
            // setValueRangePicker([detail.startDateTime, detail.endDateTime]);
          }
        }
      });
    }
  };

  const onUpdate = (values, limitedEventId) => {
    // Update detail
    setIsLoading(true);
    if (
      valueRangePicker.startDateTime !== null &&
      valueRangePicker.endDateTime !== null
    ) {
      if (
        new Date(valueRangePicker.startDateTime).getTime() >=
        new Date(valueRangePicker.endDateTime).getTime()
      ) {
        setIsLoading(false);
        showNotification({
          message: 'End Date Time should be after Start Date Time',
          variation: 'error',
        });
      } else {
        new PointMultiplierEventService()
          .update(limitedEventId, {...values})
          .then(res => {
            if (res && res?.success) {
              setIsLoading(false);
              onClose();
              reloadPage();
              showNotification({
                message: success.UPDATE_SUCCESS,
              });
            } else {
              setIsLoading(false);
              showNotification({
                message: res.errorMessage,
                variation: 'error',
              });
            }
          });
      }
    }
  };

  const onAdd = values => {
    // Create new limited Event
    setIsLoading(true);
    if (
      valueRangePicker.startDateTime !== null &&
      valueRangePicker.endDateTime !== null
    ) {
      if (
        new Date(valueRangePicker.startDateTime).getTime() >=
        new Date(valueRangePicker.endDateTime).getTime()
      ) {
        setIsLoading(false);
        showNotification({
          message: 'End Date Time should be after Start Date Time',
          variation: 'error',
        });
      } else {
        new PointMultiplierEventService().create({...values}).then(res => {
          if (res && res?.success) {
            setIsLoading(false);
            onClose();
            reloadPage();
            showNotification({
              message: success.CREATE_SUCCESS,
            });
          } else {
            setIsLoading(false);
            showNotification({
              message: res.errorMessage,
              variation: 'error',
            });
          }
        });
      }
    }
  };

  const onSubmit = values => {
    if (idLimitedEvent) {
      // call api update event
      onUpdate(values, idLimitedEvent);
    } else {
      // call api create event
      onAdd(values);
    }
    return;
  };

  const handleChangeValues = (value: any, key: string) => {
    if (key === 'merchantIds' && value.includes(-1)) {
      setInitialValues({
        ...initialValues,
        [key]: listMerchants.map(item => item.id),
      });
      return;
    }
    setInitialValues({...initialValues, [key]: value});
  };

  const resetForm = () => {
    setInitialValues({
      title: '',
      pointsMultiplier: 2,
      merchantIds: [],
      qrExpiredDate: qrExpiredDate.toISOString(),
      refreshedDate: refreshedDate.toISOString(),
      startDateTime: '',
      endDateTime: '',
    });
    setMerchantsSelected([]);
    // setValueRangePicker([null, null]);
    setValueRangePicker({startDateTime: null, endDateTime: null});
    setEmptyDate(false);
  };

  useEffect(() => {
    if (idLimitedEvent) {
      getDataDetail();
      setEmptyDate(false);
    } else {
      resetForm();
    }
  }, [idLimitedEvent]);

  useEffect(() => {
    if (isEmpty(listMerchants)) return;
    const listMerchantOption = listMerchants.map(item => ({
      title: item?.name,
      value: item.id,
    }));
    setMerchantOptions([...merchantOptions, ...listMerchantOption]);
  }, [listMerchants]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
        resetForm();
      }}
    >
      <>
        <StyledModalTitle>
          {idLimitedEvent ? 'Update Event' : 'Create Limited Event'}
        </StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .min(1, 'Min 1 char length')
                .max(50, 'Max 50 char length')
                .required('Title is required'),
              pointsMultiplier: Yup.number()
                .min(2, 'Minimum point multiplier value has to be 2.')
                .max(5, 'Maximum point multiplier value is 5.')
                .integer('Input value not use demical place')
                .required('Points multiplier is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Limited Event Title{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Enter Limited Event Title"
                    size="small"
                    variant="outlined"
                    value={values.title}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'title');
                      handleChange(e);
                    }}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Merchants
                  </Typography>
                  <MultipleSelectCheckbox
                    placeholder="Selecte merchants"
                    value={merchantsSelected}
                    onChange={(e, values) => {
                      const newData = values.map(item => item.value);
                      setMerchantsSelected([...values]);
                      handleChangeValues(newData, 'merchantIds');
                    }}
                    options={merchantOptions}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Points Multiplier{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="pointMultiplier"
                    placeholder="Enter Points Multiplier"
                    size="small"
                    variant="outlined"
                    readOnly={idLimitedEvent ? true : false}
                    value={values.pointsMultiplier}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'pointsMultiplier');
                      handleChange(e);
                    }}
                    helperText={
                      touched.pointsMultiplier && errors.pointsMultiplier
                    }
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.pointsMultiplier && errors.pointsMultiplier
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    Start Date Time <span className="mandatory-label">(*)</span>
                  </Typography>
                  <DateTimePicker
                    required
                    isEmptyValue={isEmptyValue}
                    value={valueRangePicker.startDateTime}
                    helperText="Start Date Time is required"
                    minDate={new Date()}
                    onChange={e => {
                      if (e >= valueRangePicker.endDateTime) {
                        setValueRangePicker({
                          ...valueRangePicker,
                          startDateTime: e,
                          endDateTime: null,
                        });
                        setInitialValues({
                          ...initialValues,
                          startDateTime: formatDateTime(e, format),
                          endDateTime: null,
                        });
                      } else {
                        setValueRangePicker({
                          ...valueRangePicker,
                          startDateTime: formatDateTime(e, format),
                        });
                        setInitialValues({
                          ...initialValues,
                          startDateTime: formatDateTime(e, format),
                        });
                      }
                    }}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    End Date Time <span className="mandatory-label">(*)</span>
                  </Typography>
                  <DateTimePicker
                    required
                    isEmptyValue={isEmptyValue}
                    disabled={valueRangePicker.startDateTime === null}
                    value={valueRangePicker.endDateTime}
                    helperText="End Date Time is required"
                    minDate={initialValues?.startDateTime || new Date()}
                    onChange={e => {
                      setValueRangePicker({
                        ...valueRangePicker,
                        endDateTime: e,
                      });
                      setInitialValues({
                        ...initialValues,
                        endDateTime: formatDateTime(e, format),
                      });
                    }}
                  />
                </FormControl>
                <Divider variant="middle" pt={8} />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      {idLimitedEvent ? 'Update' : 'Add Event'}
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
