/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect, createContext, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import AuthService from '../services/Auth/auth.service';
import UserService from '../services/User/user.service';
import Notification from '../components/Notification/Notification';
import {path} from '../routes/path';
import {USER_TYPE} from '../constants/UserType';
import {envRole} from '../utils';

const setSession = (token: any, refreshToken: any) => {
  if (token && refreshToken) {
    sessionStorage.setItem('accessToken', token);
    sessionStorage.setItem('refreshToken', refreshToken);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
  }
};

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const LOCKED_LOGIN = 'LOCKED_LOGIN';

export const AuthContext = createContext(null);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  lockedLoginTime: null,
};

let notificationTimeout: any;

function AuthProvider({children}: any) {
  const authService = new AuthService();
  const userService = new UserService();
  const navigate = useNavigate();

  const [notification, showNotification] = useState<object | undefined>();

  useEffect(() => {
    if (notificationTimeout) clearTimeout(notificationTimeout);
    notificationTimeout = setTimeout(() => showNotification(undefined), 4000);
    return () => notificationTimeout && clearTimeout(notificationTimeout);
  }, [notification]);

  /* --------------------------------- Reducer -------------------------------- */
  const reducer = (state: any, action: any) => {
    if (action.type === INITIALIZE) {
      const {isAuthenticated, user} = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    if (action.type === SIGN_IN) {
      const {user} = action.payload;
      return {...state, isAuthenticated: true, user};
    }
    if (action.type === SIGN_OUT) {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    if (action.type === LOCKED_LOGIN) {
      const {lockedLoginTime} = action.payload;
      if (lockedLoginTime)
        window.localStorage.setItem('lockedLoginTime', lockedLoginTime);
      else window.localStorage.removeItem('lockedLoginTime');
      return {
        ...state,
        lockedLoginTime,
      };
    }
    return state;
  };

  /* --------------------------------- Actions -------------------------------- */
  const signIn = async (email: string, password: string) => {
    return await authService.signIn(email, password).then(async res => {
      const {success} = res;
      if (success === true) {
        const {
          token,
          refreshToken,
          enabled2FA,
          lockedDateTime2FA,
          isChangePassword,
        } = res.data;
        const user = {
          email,
          password,
          token,
        };
        if (!enabled2FA) {
          if (isChangePassword) {
            return navigate(
              `${path.AUTH}/${path.CHANGE_PASSWORD}?token=${token}`
            );
          }
          setSession(token, refreshToken);
          dispatch({type: SIGN_IN, payload: {user}});
        }
        return {
          success,
          enabled2FA,
          lockedDateTime2FA,
          token,
          user,
        };
      }
      return {
        success,
      };
    });
  };

  const verification2Fa = async (token: string, code: string, user: object) => {
    return await authService.verification2Fa(token, code).then(async res => {
      const {success, data, errorMessage} = res;
      const {token, refreshToken, attemptsLeft, lockdown, isChangePassword} =
        data || {};
      if (success === true) {
        if (isChangePassword) {
          return navigate(
            `${path.AUTH}/${path.CHANGE_PASSWORD}?token=${token}`
          );
        }
        setSession(token, refreshToken);
        dispatch({type: SIGN_IN, payload: {user}});
        return {
          success,
        };
      }
      return {
        success,
        errorMessage,
        attemptsLeft,
        lockdown,
      };
    });
  };

  const signOut = async () => {
    return await authService
      .signOut({Authorization: `Bearer ${getAccessToken()}`})
      .then(async data => {
        if (data.success === true) {
          setSession(null, null);
          dispatch({type: SIGN_OUT});
          return true;
        }
        return false;
      });
  };

  const getUser = () => {
    return state.user;
  };

  const getAccessToken = () => window.sessionStorage.getItem('accessToken');

  const getRefreshToken = () => window.sessionStorage.getItem('refreshToken');
  const getHomeUrlFromUser = (user: any) => {
    if (isEmpty(user)) return `/${path.AUTH}/${path.SIGN_IN}`;
    if (user.data.type === +envRole) {
      return +envRole === USER_TYPE.ADMINISTRATOR
        ? `/${path.ISLAND_MEMBERS}`
        : `/${path.ISLAND_PARTNERS}/${user.data.merchantId}`;
    }
    return null;
  };

  const awaitGetHomeUrl = async () => {
    const user = await updateUser();
    return getHomeUrlFromUser(user);
  };

  const getHomeUrl = () => {
    const user = getUser();
    return getHomeUrlFromUser(user);
  };

  const lockedLogin = (lockedLoginTime: any) => {
    dispatch({
      type: LOCKED_LOGIN,
      payload: {
        lockedLoginTime,
      },
    });
  };

  const getLockedLoginTime = () => {
    return state.lockedLoginTime;
  };

  /* -------------------------------- ends here ------------------------------- */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [, setLoginUser] = useState<any>();
  const [, setIsDone] = useState(false);

  const updateUser = async () => {
    return await userService
      .getAll(null)
      .then(user => {
        setIsDone(true);
        setLoginUser(user);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              email: user?.data?.email,
              merchantId: user?.data?.merchantId,
              role:
                user?.data?.type === USER_TYPE.ADMINISTRATOR
                  ? 'administrator'
                  : 'merchant',
            },
          },
        });
        return user;
      })
      .catch(() => {
        setIsDone(true);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return null;
      });
  };

  const initialize = async () => {
    // let loginUser;
    try {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      /**
       * Check user exit and validate user
       * [2022/02/09]: don't have api to validate user, so check accessToken instead, will update later
       */
      if (accessToken && refreshToken) {
        setSession(accessToken, refreshToken);
        await updateUser();
      } else {
        setIsDone(true);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      setIsDone(true);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    // if (isDone) {
    //   if (!loginUser && window.location.pathname !== '/auth/sign-in') {
    //     navigate('/auth/sign-in');
    //   }
    // }
    const lockedLoginTime = window.localStorage.getItem('lockedLoginTime');
    if (lockedLoginTime) {
      dispatch({
        type: LOCKED_LOGIN,
        payload: {
          lockedLoginTime:
            new Date(lockedLoginTime).getTime() > new Date().getTime()
              ? lockedLoginTime
              : null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        showNotification,
        getUser,
        updateUser,
        verification2Fa,
        lockedLogin,
        getLockedLoginTime,
        getAccessToken,
        getHomeUrl,
        awaitGetHomeUrl,
      }}
    >
      {notification && <Notification {...notification} />}
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
