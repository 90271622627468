import BaseApiService from '../baseApi.service';

export default class MobileAssetsService extends BaseApiService {
  readonly basePath: string = 'mobile-asset/v1';
  static DeleteFileInRedis: any;
  constructor(props = null) {
    super(props);
  }

  GetFileInRedis = async (params: any) => {
    return await this.get('getitemsfromredis', params).then(res => res.data);
  };

  ValidateFileNameInRedis = async (params: any) => {
    return await this.post('validateItemInRedis', params).then(res => res.data);
  };

  DeleteFileInRedis = async (id: string) => {
    return await this.delete(`removeitem/${id}`).then(res => res);
  };

  StoreFileInRedis = async (
    file: any,
    onPostProgress: (progressEvent: any, key: string | null) => void
  ) => {
    const keyName = file.name;
    const formData = new FormData();
    formData.append('files', file);
    return this.postProgress(
      'storeiteminredis',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      keyName,
      onPostProgress
    ).then(async res => res);
  };
}
