/* eslint-disable prettier/prettier */
import styled from 'styled-components/macro';
import {Chip} from '@mui/material';
import {spacing} from '@mui/system';
import {blue, grey} from '@mui/material/colors';

const StyledChip = styled(Chip)`
  ${spacing};

  height: ${props => props.size === 'medium' && '38px'};
  width: ${props => props.size === 'medium' && '140px'};
  font-size: ${props => props.size === 'medium' && '14px'};
  font-size: ${props => props.size === 'small' && '10px'};
  font-weight: 500,

  /* primary */
  background: ${props => props.color === 'primary' && blue[900]};
  color: ${props =>
    props.color === 'primary' && props.theme.palette.common.white};

  /* default */
  background: ${props => props.color === 'default' && grey[500]};
  color: ${props =>
    props.color === 'default' && props.theme.palette.common.white};

  border-radius: 20px;

  .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
  }
`;

const StyledSizeLabel = {
  '& .MuiChip-label': {
    paddingBottom: '1px',
  },
};

export {StyledChip, StyledSizeLabel};
