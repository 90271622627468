export const TYPES = {
  UNSPECIFIED: 1,
  WEB: 2,
  APP: 3,
  SYSTEM: 4,
};

export const REDEMPTION_PLATFORMS = ['Unspecified', 'Web', 'App', 'System'];

export const REDEMPTION_PLATFORM = {
  [TYPES.UNSPECIFIED]: REDEMPTION_PLATFORMS[TYPES.UNSPECIFIED - 1],
  [TYPES.WEB]: REDEMPTION_PLATFORMS[TYPES.WEB - 1],
  [TYPES.APP]: REDEMPTION_PLATFORMS[TYPES.APP - 1],
  [TYPES.SYSTEM]: REDEMPTION_PLATFORMS[TYPES.SYSTEM - 1],
};
