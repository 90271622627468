import React, {Suspense, useEffect} from 'react';
import {useRoutes} from 'react-router-dom';
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {create} from 'jss';
import {ThemeProvider} from 'styled-components/macro';
import {CircularProgress} from '@mui/material';
import {StyledEngineProvider} from '@mui/styled-engine-sc';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';

import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';

import AuthProvider from './contexts/AuthContext';
import {envRole} from './utils';
import {USER_TYPE} from './constants/UserType';
// import {useNavigate} from 'react-router-dom';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!,
});

function App() {
  const content = useRoutes(routes);
  const {theme} = useTheme();

  /**
   * (Pseudo-root): Route to path on page mount
   */
  useEffect(() => {
    // navigate(`/${path.DASHBOARD}`);
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${
          +envRole === USER_TYPE.ADMINISTRATOR
            ? 'Sentosa Administrative Portal'
            : 'Sentosa Island Partner Portal'
        }`}
        defaultTitle="Sentosa - Administrative Portal"
      />
      <AuthProvider>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <Suspense fallback={<CircularProgress />}>{content}</Suspense>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
