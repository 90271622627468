/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseApiService from '../baseApi.service';

export default class VoucherService extends BaseApiService {
  readonly basePath: string = 'tickets/v1';
  constructor(props = null) {
    super(props);
  }

  exportCSV = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'exportCSV/voucher',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  uploadImage = async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    return await this.post(`${id}/uploadImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(async res => res.data);
  };

  arrangerFeaturedVoucher = async (params: object) => {
    return await this.post('arrange-featured-ticket', params).then(
      async res => res
    );
  };
}
