import styled from 'styled-components/macro';
import {Select} from '@mui/material';

const StyledSelect = styled(Select)`
  /* margin-bottom: 18px; */
`;

const StyledPlaceholder = styled.div`
  color: #aaa;
  font-weight: 100;
`;

export {StyledSelect, StyledPlaceholder};
