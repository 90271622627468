export type ColumnsType = {
  dataIndex: string;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  title: string;
  quantity: number;
  type: number;
  usageType: number;
  expireEndDateTime: string;
  expireStartDateTime: string;
  description: string;
  excerpt: string;
  cost: number;
  status: number;
};

export type DataDetail = {
  title?: string;
  cost?: number;
  excerpt?: string;
  description?: string;
  image?: string;
  usageType?: number;
  expireStartDateTime?: string;
  expireEndDateTime?: string;
  onlinePurchaseURL?: string;
  quantity?: number;
  unlimitedQuantity?: boolean;
  amountOffset?: number;
  type?: number;
  usageCount?: number;
  redemptionCount?: number;
  priority?: boolean;
  orderFeaturedTicket?: number | null;
  isExclusive?: number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean
) {
  return {name, path, isCurrentPage};
}

export const voucherCategoryList = [
  {title: 'Attraction', value: 1},
  {title: 'Dining', value: 2},
  {title: 'Events & Tours', value: 3},
  {title: 'Spas', value: 4},
  {title: 'Shops & Services', value: 5},
  {title: 'Meeting, Incentives, Conventions & Exhibitions', value: 6},
  {title: 'Weddings & Solemnisations', value: 7},
];
