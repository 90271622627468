import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Box, CssBaseline} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlobalStyle from '../styles/global-style';
import Navbar from '../components/Navbar/Navbar';
import Sidebar from '../components/Sidebar/Sidebar';
import Footer from '../components/Footer/Footer';
import dashboardItems from '../routes/DashboardNavRoutes';
import breakpoints from '../theme/breakpoints';

// styles
import {Root, Drawer, AppContent, MainContent} from './Dashboard.style';
import {useWindowDimensions} from '../utils';

const drawerWidth = 258;

const Dashboard: React.FC = ({children}) => {
  const {width} = useWindowDimensions();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState(width);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setContentWidth(
      width > breakpoints.values.md ? width - drawerWidth : width
    );
  }, [width]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer className="no-print">
        <Box sx={{display: {xs: 'block', lg: 'none'}}}>
          <Sidebar
            PaperProps={{style: {width: drawerWidth}}}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <Sidebar
            PaperProps={{style: {width: drawerWidth}}}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <Box width={contentWidth}>
          <MainContent p={isLgUp ? 12 : 5}>
            {children}
            <Outlet />
          </MainContent>
        </Box>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
