/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
// style
import {StyledInput} from './Input.style';

interface InputProps {
  className?: string;
  name?: string;
  label?: string;
  value?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  key?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  variant?: 'filled' | 'outlined' | 'standard';
  placeholder?: string;
  defaultValue?: string | number;
  inputComponent?: ReactNode | any;
  inputMode?: string | any;
}

function Input({
  className,
  name,
  label,
  value,
  disabled,
  type,
  size,
  key,
  fullWidth,
  onChange,
  onBlur,
  error,
  helperText,
  color = 'primary',
  variant = 'standard',
  readOnly = false,
  placeholder,
  inputComponent,
  inputMode,
  onKeyPress,
  defaultValue,
  ...other
}: InputProps) {
  return (
    <>
      <StyledInput
        label={label}
        key={key}
        name={name}
        defaultValue={defaultValue}
        variant={variant}
        className={className}
        value={value}
        disabled={disabled}
        onChange={onChange}
        fullWidth={fullWidth}
        type={type}
        size={size}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        color={color}
        placeholder={placeholder}
        InputProps={{
          readOnly: readOnly,
          inputComponent: inputComponent,
          inputMode: inputMode,
          onKeyPress: onKeyPress,
        }}
        {...other}
      />
    </>
  );
}
export default Input;
