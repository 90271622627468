import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ModalConfirm, ModalSuccess} from '../../../../components';
import {path} from '../../../../routes/path';

// styles
import {Typography} from './PartnershipDetail.style';

interface AllModalProps {
  isModalConfirmDelete?: boolean;
  isModalSuccessDelete?: boolean;
  toggleModalConfirmDelete?: () => void;
  toggleModalSuccessDelete?: () => void;
  setModal?: (any) => void;
  isLoading?: boolean;
}

export default function AllModal({
  setModal,
  isModalConfirmDelete,
  toggleModalConfirmDelete,
  toggleModalSuccessDelete,
  isModalSuccessDelete,
  isLoading,
}: AllModalProps) {
  const navigate = useNavigate();
  return (
    <>
      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">You are deleting Partnership</Typography>
        }
        // subTitle={<Typography>Voucher will not be able to login.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={toggleModalSuccessDelete}
        isLoading={isLoading}
      />

      <ModalSuccess
        visible={isModalSuccessDelete}
        title={<Typography variant="h4">Delete successfully</Typography>}
        subTitle={<Typography>Partnership deleted.</Typography>}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: false,
          }));
          navigate(`/${path.REFERRAL_PROGRAMME}/${path.PARTNERSHIP}`);
        }}
      />
    </>
  );
}
