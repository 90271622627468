/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, useMemo} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Checkbox, FormControl, Grid} from '@mui/material';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import {stateToHTML} from 'draft-js-export-html';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {
  convertFromHTML,
  ContentState,
  ContentBlock,
  convertToRaw,
  EditorState,
} from 'draft-js';

import {
  Modal,
  Button,
  Input,
  DateTimePicker,
  TextArea,
  RadioGroups,
  MultipleSelectCheckbox,
  Tooltips,
  AspectSelectImage,
} from '../../../../components';
import VoucherService from '../../../../services/Voucher/voucher.service';
import IslanderService from '../../../../services/Islander/islander.service';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {formatDateTime} from '../../../../utils';
import VoucherCategoryService from '../../../../services/Voucher/voucher-category.service';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNewVoucher.style';

const radioOptions = [
  {
    key: 'Single',
    value: 1,
  },
  {
    key: 'Unlimited',
    value: 2,
  },
];

interface ModalAddNewProps {
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

interface DateTimeRange {
  startOfDateTime?: Date | null;
  endOfDateTime?: Date | null;
}

function ModalAddNewVoucher({visible, onClose, reloadPage}: ModalAddNewProps) {
  const {showNotification} = useAuth();
  const formatTime = 'DD/MM/YYYY HH:mm';

  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startOfDateTime: null,
    endOfDateTime: null,
  });

  // states
  const [initialValues, setInitialValues] = useState({
    title: '',
    description: '',
    excerpt: '',
    usageType: 1,
    cost: 0,
    quantity: 0,
    unlimitedQuantity: false,
    onlinePurchaseURL: '',
    amountOffset: 0,
    type: 1,
    priority: false,
    startOfDateTime: '',
    endOfDateTime: '',
    isExclusive: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [optionIslandPartners, setOptionIslandPartners] = useState([]);
  const [islandPartners, setOnChangeMultipleSelect] = useState([]);
  const [optionVoucherCategory, setOptionVoucherCategory] = useState([]);
  const [voucherCategories, setVoucherCategories] = useState([]);
  const [categorySelectCheckbox, setCategorySelectCheckbox] = useState([]);
  const [defaultValue, setDefaultValue] = useState('');
  const [isEditorError, setEditorError] = useState(false);
  const selectIPPlaceholder = useMemo(() => {
    return Array.isArray(islandPartners) && islandPartners.length > 0
      ? 'Select IP'
      : 'All IP';
  }, [islandPartners]);
  const selectCategoryPlaceholder = useMemo(() => {
    return Array.isArray(categorySelectCheckbox) &&
      categorySelectCheckbox.length > 0
      ? 'Select Categories'
      : 'All';
  }, [categorySelectCheckbox]);
  const [disabledQty, setDisabledQty] = useState(false);
  const [isEmptyDate, setEmptyDate] = useState(false);
  const [voucherImages, setVoucherImages] = useState([]);

  const addNewVoucher = values => {
    if (
      valueRangePicker.startOfDateTime !== null &&
      valueRangePicker.endOfDateTime !== null
    ) {
      setIsLoading(true);
      setEmptyDate(false);
      new VoucherService()
        .create({
          ...values,
          expireStartDateTime: formatDateTime(
            valueRangePicker.startOfDateTime,
            formatTime
          ),
          expireEndDateTime: formatDateTime(
            valueRangePicker.endOfDateTime,
            formatTime
          ),
          merchantIds: islandPartners,
          ticketCategoryIds: voucherCategories,
        })
        .then(res => {
          if (res && res?.success) {
            if (voucherImages.length > 0) {
              new VoucherService()
                .uploadImage(
                  res.data.id,
                  new File(voucherImages, 'voucherImage.jpeg')
                )
                .then(resImg => {
                  if (resImg) {
                    setIsLoading(false);
                    onClose();
                    reloadPage();
                    showNotification({
                      message: success.CREATE_SUCCESS,
                    });
                  }
                });
            } else {
              setIsLoading(false);
              onClose();
              reloadPage();
              showNotification({
                message: success.CREATE_SUCCESS,
              });
            }
          } else {
            setIsLoading(false);
            showNotification({
              message: res.errorMessage,
              variation: 'error',
            });
          }
        });
    } else {
      setEmptyDate(true);
    }
  };

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService()
      .getAll({_size: 2147483647, _sort: 'name:asc'})
      .then(res => {
        if (res?.data && Array.isArray(res?.data?.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionIslandPartners(newData);
          setIsLoading(false);
        }
      });
  };

  const getVoucherCategory = () => {
    setIsLoading(true);
    new VoucherCategoryService()
      .getAll({_size: 2147483647, status_eq: 1})
      .then(res => {
        if (res?.data && Array.isArray(res?.data?.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.title || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionVoucherCategory(newData);
          setIsLoading(false);
        }
      });
  };

  const viewSourceHtmlEditor = (data: string) => {
    let contentString = '';

    const contentHTML = convertFromHTML(data);
    const states = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    JSON.parse(
      defaultValue !== '' ? defaultValue : JSON.stringify(convertToRaw(states))
    ).blocks.map(item => (contentString = contentString + item.text));
    if (contentString.search('</') !== -1) {
      const contentHTML = convertFromHTML(data);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      setDefaultValue(JSON.stringify(convertToRaw(state)));
    } else {
      const state = ContentState.createFromText(data);
      setDefaultValue(JSON.stringify(convertToRaw(state)));
    }
  };

  useEffect(() => {
    setDisabledQty(initialValues.unlimitedQuantity);
  }, [initialValues.unlimitedQuantity]);

  useEffect(() => {
    if (visible) {
      getIslanderPartners();
      getVoucherCategory();
      setValueRangePicker({startOfDateTime: null, endOfDateTime: null});
      setVoucherCategories([]);
      setCategorySelectCheckbox([]);
      setVoucherImages([]);
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="sm">
      <>
        <StyledModalTitle>Add New Voucher</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Voucher Title is required').max(40),
              excerpt: Yup.string()
                .required('Voucher Short Description is required')
                .max(85),
              onlinePurchaseURL: Yup.string()
                .matches(
                  /(https:\/\/|http:\/\/|mailto:)+[a-zA-Z0-9-_]/,
                  'Online purchase url is invalid url'
                )
                .notRequired(),
              description: Yup.string().required('T&Cs is required'),
              quantity: Yup.number()
                .integer()
                .moreThan(
                  initialValues.unlimitedQuantity ? -2 : -1,
                  'Quantity must be greater than or equal to 0'
                ),
            })}
            onSubmit={addNewVoucher}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Voucher Title (40 Max Char.){' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <Input
                        type="text"
                        name="title"
                        placeholder="Enter Voucher Title"
                        size="small"
                        variant="outlined"
                        value={values.title}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                        onBlur={handleBlur}
                        error={Boolean(touched.title && errors.title)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Voucher Short Description (85 Max Char.){' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <TextArea
                        label=""
                        placeholder="Enter Voucher Short Description"
                        maxRows={2}
                        name="excerpt"
                        value={values.excerpt}
                        helperText={touched.excerpt && errors.excerpt}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.excerpt && errors.excerpt)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Points Required
                      </Typography>
                      <Input
                        placeholder="Enter Points"
                        size="small"
                        name="cost"
                        type="number"
                        value={values.cost}
                        variant="outlined"
                        helperText={touched.cost && errors.cost}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.cost && errors.cost)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Voucher Cash Value
                      </Typography>
                      <Input
                        placeholder="Enter Amount Used"
                        size="small"
                        name="amountOffset"
                        type="number"
                        value={values.amountOffset}
                        variant="outlined"
                        helperText={touched.amountOffset && errors.amountOffset}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.amountOffset && errors.amountOffset
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Qty
                      </Typography>
                      <Input
                        placeholder="Enter Usable Quantity"
                        size="small"
                        name="quantity"
                        type="number"
                        value={values.quantity}
                        variant="outlined"
                        helperText={touched.quantity && errors.quantity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.quantity && errors.quantity)}
                        disabled={disabledQty}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl fullWidth margin="dense">
                      <div>
                        <Typography variant="inherit">Unlimited Qty</Typography>
                        <Checkbox
                          sx={{
                            paddingLeft: 0,
                          }}
                          name="unlimitedQuantity"
                          checked={values.unlimitedQuantity}
                          value={values.unlimitedQuantity}
                          onChange={e => {
                            handleChange(e);
                            setInitialValues({
                              ...values,
                              quantity: e.target.checked ? -1 : 0,
                              unlimitedQuantity: e.target.checked,
                            });
                          }}
                          inputProps={{'aria-label': 'controlled'}}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl fullWidth margin="dense">
                      <div>
                        <Typography variant="inherit">Exclusive</Typography>
                        <Checkbox
                          sx={{
                            paddingLeft: 0,
                          }}
                          name="isExclusive"
                          checked={values.isExclusive ? true : false}
                          value={values.isExclusive}
                          onChange={e => {
                            handleChange(e);
                            setInitialValues({
                              ...values,
                              isExclusive: e.target.checked ? 1 : 0,
                            });
                          }}
                          inputProps={{'aria-label': 'controlled'}}
                        />
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item md={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2" mb={2}>
                        T&amp;Cs <span className="mandatory-label">(*)</span>
                      </Typography>
                      <RichTextEditor
                        label="Enter T&amp;Cs"
                        helperText={touched.description && errors.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        defaultValue={defaultValue}
                        controls={[
                          'title',
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          'undo',
                          'redo',
                          'link',
                          'media',
                          'numberList',
                          'bulletList',
                          'source',
                        ]}
                        customControls={[
                          {
                            name: 'source',
                            icon: <SourceOutlinedIcon />,
                            type: 'callback',
                            onClick: () => {
                              viewSourceHtmlEditor(initialValues?.description);
                            },
                          },
                        ]}
                        onChange={e => {
                          let stringContent = '';
                          e.getCurrentContent()
                            .getBlocksAsArray()
                            .map(
                              item =>
                                (stringContent = stringContent + item.text)
                            );
                          let content =
                            stringContent.search('</') === -1
                              ? stateToHTML(e.getCurrentContent())
                              : stringContent;
                          content = content.replace('<strong>', '<b>');
                          content = content.replace('</strong>', '</b>');
                          handleChange(
                            content === '<p><br></p>' ? '' : content
                          );
                          setInitialValues({
                            ...values,
                            description:
                              content === '<p><br></p>' ? '' : content,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Online Purchase URL
                      </Typography>
                      <Input
                        type="text"
                        name="onlinePurchaseURL"
                        placeholder="Enter Online Purchase URL"
                        size="small"
                        variant="outlined"
                        value={values.onlinePurchaseURL}
                        onChange={handleChange}
                        helperText={
                          touched.onlinePurchaseURL && errors.onlinePurchaseURL
                        }
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.onlinePurchaseURL && errors.onlinePurchaseURL
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2" mb={2}>
                        Select IP
                      </Typography>
                      <MultipleSelectCheckbox
                        placeholder={selectIPPlaceholder}
                        onChange={(e, values) => {
                          const newData = values.map(item => item.value);
                          setOnChangeMultipleSelect(newData);
                        }}
                        options={optionIslandPartners}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Voucher Category
                      </Typography>
                      <MultipleSelectCheckbox
                        placeholder={selectCategoryPlaceholder}
                        value={categorySelectCheckbox}
                        onChange={(e, values) => {
                          const newData = values.map(item => item.value);
                          setCategorySelectCheckbox([...values]);
                          setVoucherCategories(newData);
                        }}
                        options={optionVoucherCategory}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Start Date Time{' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <DateTimePicker
                        required
                        isEmptyValue={isEmptyDate}
                        value={valueRangePicker.startOfDateTime}
                        helperText="Start Date Time is required"
                        onChange={e => {
                          if (e >= valueRangePicker.endOfDateTime) {
                            setValueRangePicker({
                              ...valueRangePicker,
                              startOfDateTime: e,
                              endOfDateTime: null,
                            });
                            setInitialValues({
                              ...initialValues,
                              startOfDateTime: formatDateTime(e, formatTime),
                              endOfDateTime: null,
                            });
                          } else {
                            setValueRangePicker({
                              ...valueRangePicker,
                              startOfDateTime: formatDateTime(e, formatTime),
                            });
                            setInitialValues({
                              ...initialValues,
                              startOfDateTime: formatDateTime(e, formatTime),
                            });
                          }
                        }}
                      />
                      {/* <RangePicker
                        required
                        isEmptyValue={isEmptyDate}
                        value={valueRangePicker}
                        onChange={onChangeRangePicker}
                      /> */}
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        End Date Time{' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <DateTimePicker
                        required
                        isEmptyValue={isEmptyDate}
                        disabled={valueRangePicker.startOfDateTime === null}
                        value={valueRangePicker.endOfDateTime}
                        helperText="End Date Time is required"
                        minDate={initialValues?.startOfDateTime || new Date()}
                        onChange={e => {
                          setValueRangePicker({
                            ...valueRangePicker,
                            endOfDateTime: e,
                          });
                          setInitialValues({
                            ...initialValues,
                            endOfDateTime: formatDateTime(e, formatTime),
                          });
                        }}
                      />
                      {/* <RangePicker
                        required
                        isEmptyValue={isEmptyDate}
                        value={valueRangePicker}
                        onChange={onChangeRangePicker}
                      /> */}
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Voucher Type
                      </Typography>
                      <RadioGroups
                        name="usageType"
                        options={radioOptions}
                        values={values.usageType}
                        onChange={e => {
                          handleChange(e);
                          setInitialValues({
                            ...values,
                            usageType: +e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Voucher Image
                      </Typography>
                      <AspectSelectImage
                        defaultImages={voucherImages}
                        setDefaultImages={setVoucherImages}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Tooltips
                      title="When this is checked, it will bump the voucher to the top of the voucher listing page in B2C"
                      arrow={false}
                    >
                      <FormControl fullWidth margin="dense">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="inherit">Priority</Typography>
                          <Checkbox
                            sx={{
                              paddingLeft: 0,
                              marginLeft: 1,
                            }}
                            name="priority"
                            checked={values.priority}
                            value={values.priority}
                            onChange={e => {
                              handleChange(e);
                              setInitialValues({
                                ...values,
                                priority: e.target.checked,
                              });
                            }}
                            inputProps={{'aria-label': 'controlled'}}
                          />
                        </div>
                      </FormControl>
                    </Tooltips>
                  </Grid>
                </Grid>
                <Divider variant="middle" pt={8} />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button
                      variant="text"
                      type="button"
                      onClick={onClose}
                      loading={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      Add Voucher
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNewVoucher;
