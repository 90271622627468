import styled from 'styled-components';
// import {FileUploader} from 'react-drag-drop-files';
import {spacing} from '@mui/system';

import {Typography as MuiTypography} from '@mui/material';

const StyledFileUploader = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 0px;
  height: 36px;
`;
const Typography = styled(MuiTypography)`
  ${spacing};
  font-size: 12px;
  font-weight: 500;
`;

const StyledButtonUploadFile = styled.div`
  background: #c4c4c4;
  border-radius: 10px;
  width: 92px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

const StyledLabelUploadFile = styled.div`
  margin-bottom: 8px;
`;

const StyledContentInput = styled.div`
  padding: 5px 10px 5px 10px;
`;

const StyledInput = styled('input')({
  display: 'none',
});

export {
  StyledFileUploader,
  StyledButtonUploadFile,
  Typography,
  StyledLabelUploadFile,
  StyledContentInput,
  StyledInput,
};
