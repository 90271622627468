import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {Typography as MuiTypography, DialogActions} from '@mui/material';
const StyledIconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
`;

const Typography = styled(MuiTypography)(spacing);
const StyledModalActions = styled(DialogActions)`
  padding-bottom: 40px;
`;

export {StyledIconClose, Typography, StyledModalActions};
