/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import isEmpty from 'lodash/isEmpty';
import QRCode from 'react-qr-code';
import {Helmet} from 'react-helmet-async';
import WarningIcon from '@mui/icons-material/Warning';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {CalendarTodaySharp} from '@mui/icons-material';

import {path} from '../../../routes/path';
import {Grid, FormControl, Alert} from '@mui/material';
import {typeRoutes, DataDetail} from '../../../types/typeIslandersPartners';
import useAuth from '../../../hooks/useAuth';
import {images} from '../../../constants/image';
import IslanderService from '../../../services/Islander/islander.service';
import success from '../../../constants/success';
import TableStaff from '../components/TableStaff/TableStaff';
import {AllModal} from './IslandPartnerDetail.method';
import {
  envRole,
  formatLocalDateFromUTCTime,
  hasPermission,
} from '../../../utils';
import {
  Breadcrumbs,
  FooterDetail,
  Input,
  Stats,
  Button,
  ModalConfirm,
} from '../../../components';
import queryString from 'query-string';
import {USER_TYPE} from '../../../constants/UserType';
import ModalMonthSelection from '../components/ModalMonthSelection/ModalMonthSelection';
import ModalSelectExpiredDate from '../components/ModalSelectExpiredDate/ModalSelectExpiredDate';
import ModalConfirmCashout from '../components/ModalConfirmCashout/ModalConfirmCashout';

// style
import {
  Typography,
  Card,
  Divider,
  StyledQR,
  TableStaffWrapper,
} from './IslandPartnerDetail.style';

const islandPartnerTitle = `Island Partner${
  +envRole === USER_TYPE.ADMINISTRATOR ? 's' : ''
}`;

function IslandPartnerDetail() {
  const location = useLocation();
  const paramsUrl = useParams();
  const idDetail = paramsUrl?.id;
  const navigate = useNavigate();
  const {showNotification, getUser} = useAuth();
  const format = 'DD/MM/YYYY';
  const formatDateTime = 'DD/MM/YYYY hh:mm A';
  const QRCodeFormat = 'YYYY-MM-DD';
  const {data, email} = getUser() || {};
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canRead = hasPermission(path.ISLAND_PARTNERS, 'read');
  const canCreate = hasPermission(path.ISLAND_PARTNERS, 'create');
  const canUpdate = hasPermission(path.ISLAND_PARTNERS, 'update');
  const canDelete = hasPermission(path.ISLAND_PARTNERS, 'delete');
  const STATUS_CODE_BAD_REQUEST = 400;
  const userType = +envRole;

  // states
  const [modal, setModal] = useState({
    isModalConfirm: false,
    isModalConfirmDelete: false,
    isModalSuccess: false,
    isModalSuccessDelete: false,
    isModalEditTier: false,
    isModalEditPoints: false,
  });
  const [initialValues, setInitialValues] = useState<DataDetail>({
    contactEmail: '',
    additionalEmail: '',
    contactName: '',
    name: '',
  });
  const [dataDetail, setDataDetail] = useState<DataDetail>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('Sentosa');
  const [isOpenExpiredDateSelection, setIsOpenExpiredDateSelection] =
    useState(false);
  const [isOpenMonthSelection, setIsOpenMonthSelection] = useState(false);
  const [cashoutPeriod, setCashoutPeriod] = useState<string[]>([]);
  const [monthSelections, setMonthSelections] = useState<string[]>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenConfirmRefresh, setIsOpenConfirmRefresh] = useState(false);

  const toggleModalConfirm = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirm: !modal.isModalConfirm,
    }));
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalSuccess = () => {
    toggleModalConfirm();
    setModal(preState => ({
      ...preState,
      isModalSuccess: !modal.isModalSuccess,
    }));
  };

  const toggleModalSuccessDelete = () => {
    new IslanderService().softDelete(idDetail).then(res => {
      setIsLoading(true);
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirmDelete();
        setModal(preState => ({
          ...preState,
          isModalSuccessDelete: !modal.isModalSuccessDelete,
        }));
      }
    });
  };

  const onEdit = values => {
    new IslanderService().update(idDetail, {...values}).then(res => {
      setIsLoading(true);
      if (res.success) {
        getDetailIslandPartner();
        setIsLoading(false);
        showNotification({
          message: success.UPDATE_SUCCESS,
        });
      } else {
        setIsLoading(false);
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const getDetailIslandPartner = () => {
    setIsLoading(true);
    new IslanderService().getOne(idDetail).then((res: any) => {
      if (res && res.data && res.data.data) {
        setIsLoading(false);
        const data = res?.data?.data;
        setDataDetail(data);
        setInitialValues({
          ...initialValues,
          contactEmail: data?.contactEmail,
          additionalEmail: data?.additionalEmail ?? '',
          contactName: data?.contactName,
          name: data?.name,
        });
      }
      if (res?.data?.code === STATUS_CODE_BAD_REQUEST && role === 'merchant') {
        navigate(`/${path.ISLAND_PARTNERS}/${data?.merchantId}`);
      }
      if (res?.code === STATUS_CODE_BAD_REQUEST && role === 'administrator') {
        navigate(`/${path.ISLAND_MEMBERS}`);
      }
    });
  };

  const refreshQRCode = () => {
    toggleModalConfirmRefresh();
    setIsLoading(true);
    const refreshedDate = new Date();
    new IslanderService()
      .update(idDetail, {
        refreshedDate: refreshedDate.toISOString(),
      })
      .then(res => {
        if (res.success) {
          setIsLoading(false);
          setDataDetail({
            ...dataDetail,
            refreshedDate: refreshedDate.toISOString(),
          });
          showNotification({
            message: 'Refresh QR Code Success!',
            variation: 'success',
          });
          setIsAlert(false);
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const toggleModalSelectExpiredDate = () => {
    setIsOpenExpiredDateSelection(!isOpenExpiredDateSelection);
  };

  const toggleModalMonthSelection = () => {
    setIsOpenMonthSelection(!isOpenMonthSelection);
    getCashoutPeriod();
  };
  const toggleModalConfirmCashout = () => {
    setIsOpenConfirm(!isOpenConfirm);
  };

  const toggleModalConfirmRefresh = () => {
    setIsOpenConfirmRefresh(!isOpenConfirmRefresh);
  };

  const cashout = () => {
    setIsLoading(true);
    new IslanderService()
      .cashout(`${idDetail}/cashout`, {
        timePeriods: monthSelections,
        exportCSV_userType: userType,
        email: email,
      })
      .then(res => {
        if (res.success) {
          setIsLoading(false);
          showNotification({
            message: 'Cash Out Success!',
            variation: 'success',
          });
          getDetailIslandPartner();
          setIsAlert(false);
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const getCashoutPeriod = () => {
    new IslanderService().cashoutPeriod(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setCashoutPeriod(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (role) {
      getDetailIslandPartner();
    }
  }, [idDetail, role]);

  useEffect(() => {
    if (!isEmpty(dataDetail)) {
      const {qrExpiredDate = null} = dataDetail;
      if (
        !qrExpiredDate ||
        new Date(qrExpiredDate).getTime() < new Date().getTime()
      ) {
        setIsAlert(true);
      }

      setQrCodeValue(
        `${process.env.REACT_APP_B2C_DOMAIN}/earn-points?id=${dataDetail.id}${
          dataDetail.refreshedDate
            ? `%26refreshedDate=${formatLocalDateFromUTCTime(
                dataDetail.refreshedDate,
                QRCodeFormat
              )}`
            : ''
        }`
      );
    } else {
      setQrCodeValue('Sentosa');
    }
  }, [dataDetail]);

  return (
    <>
      <Helmet title="Island Partner" />

      <AllModal
        isModalConfirm={modal.isModalConfirm}
        toggleModalConfirm={toggleModalConfirm}
        isModalConfirmDelete={modal.isModalConfirmDelete}
        isModalSuccessDelete={modal.isModalSuccessDelete}
        isModalSuccess={modal.isModalSuccess}
        toggleModalConfirmDelete={toggleModalConfirmDelete}
        toggleModalSuccessDelete={toggleModalSuccessDelete}
        toggleModalSuccess={toggleModalSuccess}
        setModal={setModal}
        isLoading={isLoading}
      />

      <ModalMonthSelection
        visible={isOpenMonthSelection}
        onClose={toggleModalMonthSelection}
        cashoutPeriod={cashoutPeriod}
        onMonthSelected={values => {
          setIsOpenConfirm(true);
          setMonthSelections(values);
        }}
      />

      <ModalSelectExpiredDate
        merchantId={idDetail}
        onAlert={setIsAlert}
        visible={isOpenExpiredDateSelection}
        onClose={toggleModalSelectExpiredDate}
        reloadPage={getDetailIslandPartner}
      />

      <ModalConfirmCashout
        visible={isOpenConfirm}
        onCancel={toggleModalConfirmCashout}
        onConfirm={value => {
          if (value) {
            cashout();
          }
        }}
      />

      <ModalConfirm
        visible={isOpenConfirmRefresh}
        title={
          <Typography variant="h4">
            Are you sure you want to refresh this QR Code? Once it's refreshed,
            the old QR code will NOT be valid.
          </Typography>
        }
        subTitle={
          <Typography color="red">This action cannot be undone.</Typography>
        }
        onClose={toggleModalConfirmRefresh}
        onConfirm={refreshQRCode}
        isLoading={isLoading}
      />

      <Grid
        justifyContent="space-between"
        container
        spacing={2}
        columns={16}
        className="no-print"
      >
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Island Partner Detail
          </Typography>
          <Breadcrumbs
            routes={[
              // typeRoutes('Dashboard', `/${path.DASHBOARD}`),
              typeRoutes(
                islandPartnerTitle,
                `/${path.ISLAND_PARTNERS}?${queryString.stringify(
                  location.state
                )}`
              ),
              typeRoutes('Detail', null, true),
            ]}
          />
        </Grid>
      </Grid>
      <Divider my={6} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          contactEmail: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          additionalEmail: Yup.string().email('Must be a valid email').max(255),
          name: Yup.string().required('Island Partner Name is required'),
          contactName: Yup.string().required('Contact Name is required'),
        })}
        onSubmit={onEdit}
        className="no-print"
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} className="no-print">
            <Grid container spacing={6} className="no-print">
              <Grid item xs={4} md={4}>
                <Card
                  px={6}
                  pt={3}
                  pb={5}
                  sx={{height: '100%'}}
                  className="no-print"
                >
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    mb={10}
                    className="no-print"
                  >
                    Island Partner information
                  </Typography>
                  {isAlert && (
                    <Alert
                      variant="filled"
                      severity="warning"
                      icon={<WarningIcon />}
                      onClose={() => {
                        setIsAlert(false);
                      }}
                      className="no-print"
                    >
                      QR code is expired!
                    </Alert>
                  )}
                  <StyledQR className="no-print">
                    <QRCode
                      value={qrCodeValue}
                      size={230}
                      className="no-print"
                    />
                  </StyledQR>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Typography variant="caption" fontWeight="500">
                      QR Code Expiry Date:{' '}
                      {formatLocalDateFromUTCTime(
                        dataDetail?.qrExpiredDate,
                        formatDateTime
                      )}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Button
                      mx={2}
                      disabled={+envRole === USER_TYPE.MERCHANT}
                      type="button"
                      startIcon={<RefreshIcon />}
                      onClick={toggleModalConfirmRefresh}
                    >
                      Refresh
                    </Button>
                    <Button
                      type="button"
                      startIcon={<PrintIcon />}
                      onClick={() => window.print()}
                    >
                      Download
                    </Button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Button
                      type="button"
                      disabled={+envRole === USER_TYPE.MERCHANT}
                      startIcon={<CalendarTodaySharp />}
                      onClick={() => toggleModalSelectExpiredDate()}
                    >
                      Edit Expiry
                    </Button>
                  </div>
                  {!canUpdate && (
                    <>
                      <Typography variant="body1" fontSize="12px" pt={5}>
                        Name
                      </Typography>
                      <Typography variant="body1" fontSize="14px" mb={5}>
                        {values.name}
                      </Typography>
                      <Typography variant="body1" fontSize="12px">
                        Contact Name
                      </Typography>
                      <Typography variant="body1" fontSize="14px" mb={5}>
                        {values.contactName}
                      </Typography>
                      <Typography variant="body1" fontSize="12px">
                        Email
                      </Typography>
                      <Typography variant="body1" fontSize="14px" mb={5}>
                        {values.contactEmail}
                      </Typography>
                      <Typography variant="body1" fontSize="12px">
                        Additional Email (Optional)
                      </Typography>
                      <Typography variant="body1" fontSize="14px">
                        {values.additionalEmail}
                      </Typography>
                    </>
                  )}
                  {!!canUpdate && (
                    <>
                      <FormControl
                        variant="standard"
                        fullWidth
                        className="no-print"
                      >
                        <Input
                          label="Island Partner Name"
                          type="text"
                          name="name"
                          value={values.name}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.name && errors.name)}
                          className="mandatory-field"
                        />
                      </FormControl>
                      <FormControl
                        variant="standard"
                        fullWidth
                        className="no-print"
                      >
                        <Input
                          label="Contact Name"
                          type="text"
                          name="contactName"
                          value={values.contactName}
                          helperText={touched.contactName && errors.contactName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.contactName && errors.contactName
                          )}
                          className="mandatory-field"
                        />
                      </FormControl>
                      <FormControl
                        variant="standard"
                        fullWidth
                        className="no-print"
                      >
                        <Input
                          type="email"
                          name="contactEmail"
                          label="Email"
                          value={values.contactEmail}
                          helperText={
                            touched.contactEmail && errors.contactEmail
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.contactEmail && errors.contactEmail
                          )}
                          className="mandatory-field"
                        />
                      </FormControl>
                      <FormControl
                        variant="standard"
                        fullWidth
                        className="no-print"
                      >
                        <Input
                          type="email"
                          name="additionalEmail"
                          label="Additional Email (Optional)"
                          value={values.additionalEmail}
                          helperText={
                            touched.additionalEmail && errors.additionalEmail
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.additionalEmail && errors.additionalEmail
                          )}
                        />
                      </FormControl>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid item xs={8} md={8} className="no-print">
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={6} md={6}>
                        <Stats
                          title="Total no. of vouchers used"
                          amount={dataDetail?.totalTicketsUsed || 0}
                          icon={images.icon_transactional}
                          isManage={false}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Stats
                          title="Unredeemed Cash Voucher from SDC"
                          amount={dataDetail?.cashoutBalance || 0}
                          icon={images.icon_tier}
                          isManage={false}
                          isShowCashSign
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={6}>
                      <Grid item xs={6} md={6}>
                        <Stats
                          title="Total Tier Points Issued"
                          helperText="The sum of all merchant's point values with the operation of add (i.e. operation = 1)"
                          amount={dataDetail?.totalTierPoints || 0}
                          icon={images.icon_points}
                          isManage={false}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Stats
                          title="Total Cash Voucher Used at IP to date"
                          amount={dataDetail?.totalCashout || 0}
                          icon={images.icon_points}
                          isManage={false}
                          isShowCashSign
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TableStaffWrapper>
                      <TableStaff
                        idMerchant={idDetail}
                        canDelete={!canDelete}
                        canCreate={!canCreate}
                      />
                    </TableStaffWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FooterDetail
              isCashOut
              isTransactions
              isVoucherTransaction
              isEdit={idDetail ? true : false}
              canDelete={!canDelete}
              canRead={!canRead}
              canUpdate={!canUpdate}
              toggleModalConfirm={toggleModalConfirm}
              toggleModalConfirmDelete={toggleModalConfirmDelete}
              titleDelete="Delete Island Partner"
              loading={isLoading}
              isResetPassword={false}
              onClickTransactions={() => {
                navigate(
                  `/${path.ALL_TRANSACTION}?merchantId=${idDetail}&merchantId_label=${values.name}`,
                  {
                    state: {
                      merchantId: dataDetail?.id,
                    },
                  }
                );
              }}
              onClickVoucherTransaction={() => {
                navigate(
                  `/${path.VOUCHER_TRANSACTION_HISTORY}?merchantId=${idDetail}&merchantTicketPointId_label=${values.name}`,
                  {
                    state: {
                      merchantId: dataDetail?.id,
                    },
                  }
                );
              }}
              onClickCashOut={toggleModalMonthSelection}
            />
          </form>
        )}
      </Formik>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="show-print"
        >
          <QRCode
            value={qrCodeValue}
            size={420}
            className="show-print"
            style={{marginBottom: '20px'}}
          />
          <Typography className="show-print" variant="caption" fontWeight="500">
            IP Name: {dataDetail?.name}
          </Typography>
          <Typography className="show-print" variant="caption" fontWeight="500">
            QR Expired Date:{' '}
            {formatLocalDateFromUTCTime(dataDetail?.qrExpiredDate, format)}
          </Typography>
        </Grid>
      </div>
    </>
  );
}

export default IslandPartnerDetail;
