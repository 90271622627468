import BaseApiService from '../baseApi.service';

export default class TagsService extends BaseApiService {
  readonly basePath: string = 'tags/v1';
  constructor(props = null) {
    super(props);
  }

  assignToMembership = async (params: object) => {
    return await this.post('assign', params).then(async res => res.data);
  };
}
