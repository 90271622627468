/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, {useState, useContext} from 'react';
import {withTheme} from 'styled-components/macro';
import {Power} from 'react-feather';
import {Grid, Toolbar, Tooltip, Menu, MenuItem} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {AuthContext} from '../../contexts/AuthContext';

// styles
import {AppBar, IconButton, MenuItemLink, Typography} from './Navbar.style';
import {path} from '../../routes/path';

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({onDrawerToggle}) => {
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const {signOut} = useAuth();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    if (signOut()) {
      navigate(`/${path.AUTH}/${path.SIGN_IN}`);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{display: {xs: 'block', md: 'none'}}}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <div>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  fontWeight="500"
                >
                  Logged in as {context?.getUser()?.email}
                </Typography>
                <Tooltip title="Account">
                  <IconButton
                    aria-owns={anchorMenu ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                    size="large"
                  >
                    <Power />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorMenu}
                  open={Boolean(anchorMenu)}
                  onClose={closeMenu}
                >
                  <MenuItem>
                    <MenuItemLink to={`/${path.USER}/${path.RESET_PASSWORD}`}>
                      Change password
                    </MenuItemLink>
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
