/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  Input,
  DateTimePicker,
} from '../../../../components';
import {formatDateTime} from '../../../../utils';
import IslandPartnerService from '../../../../services/Islander/islander.service';
import {MultipleSelectCheckbox} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
import VoucherCategoryService from '../../../../services/Voucher/voucher-category.service';

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

interface ModalFilterProps {
  visible: boolean;
  setOptionVoucherCategory?: () => void;
  optionVoucherCategory?: any;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  nameContainsList?: any;
  setNameContainsList?: (any) => void;
  valueRangePicker?: any;
  setValueRangePicker?: (any) => void;
  ticketCategoryContain?: any;
  setTicketCategoryContain?: () => void;
  ticketCategoryLabel?: any;
  setTicketCategoryLabel?: () => void;
  deleteSearchParams?: () => void;
  optionOrderFeaturedTicket?: any;
  orderFeaturedTicket_isnull?: boolean;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter,
  nameContainsList,
  setNameContainsList,
  valueRangePicker,
  setValueRangePicker,
  ticketCategoryContain,
  setTicketCategoryContain,
  setTicketCategoryLabel,
  deleteSearchParams,
  orderFeaturedTicket_isnull,
}: ModalFilterProps) {
  const [voucherCategories, setVoucherCategories] = useState([]);
  const [optionVoucherCategory, setOptionVoucherCategory] = useState([]);
  const formatTime = 'DD/MM/YYYY HH:mm';

  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSelectMultiple = (items: any, key: string) => {
    setValueFilter({
      ...valueFilter,
      [key]: items.map((item: any) => item.value),
    });

    if (items.length <= 0) {
      deleteSearchParams(`${key}`);
      deleteSearchParams('category_label');
    }
  };

  const getIslandPartners = () => {
    new IslandPartnerService()
      .getAll({
        status_eq: 1,
        _size: 2147483647,
        _sort: 'name:asc',
      })
      .then(res => {
        if (res?.data?.items) {
          setNameContainsList(
            res?.data?.items?.reduce((items, item) => {
              items.push({
                label: item.name || '-',
                value: item.id,
              });
              return items;
            }, [])
          );
        }
      });
  };

  const getVoucherCategory = () => {
    new VoucherCategoryService()
      .getAll({_size: 2147483647, status_eq: 1})
      .then(res => {
        if (res?.data && Array.isArray(res?.data?.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.title || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionVoucherCategory(newData);
          const selectedIds = newData?.filter(item => {
            return ticketCategoryContain?.includes(item.value);
          });
          setVoucherCategories(selectedIds);
        }
      });
  };

  const handleCleanModalForm = () => {
    handleClean();
    setVoucherCategories([]);
  };

  useEffect(() => {
    if (visible) {
      getIslandPartners();
      getVoucherCategory();
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'By TITLE/SHORT DESCRIPTION'}
            </Typography>
            <Input
              type="text"
              name="titleOrExcerpt_eq"
              placeholder="Enter Title or Short Description"
              size="small"
              variant="outlined"
              value={valueFilter.titleOrExcerpt_eq}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY VOUCHER TYPE
            </Typography>
            <Select
              labelId="usageType_eq"
              displayEmpty
              name="usageType_eq"
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.usageType_eq}
              placeholder="Select Voucher Type"
              size="small"
              selectOptions={[
                createData('Single', '1'),
                createData('Unlimited', '2'),
              ]}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY EXCLUSIVITY
            </Typography>
            <Select
              labelId="isExclusive_eq"
              displayEmpty
              name="isExclusive_eq"
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.isExclusive_eq}
              placeholder="Select Exclusive Type"
              size="small"
              selectOptions={[
                createData('Exclusive', 'true'),
                createData('Non-Exclusive', 'false'),
              ]}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY IP
            </Typography>
            <Select
              labelId="merchantIdNumber_eq"
              variant="outlined"
              name="merchantIdNumber_eq"
              displayEmpty
              onChangeSelect={e => onChange(e)}
              value={valueFilter.merchantIdNumber_eq}
              placeholder="Select Island Partner"
              size="small"
              selectOptions={[
                {
                  label: 'All IP',
                  value: 'All IP',
                },
              ].concat(nameContainsList)}
            />
          </FormControl>
          <FormControl margin="dense" fullWidth>
            <Typography variant="subtitle2" mb={2}>
              BY START DATE TIME
            </Typography>
            <DateTimePicker
              required
              value={valueRangePicker.startOfDateTime}
              onChange={e => {
                if (e >= valueRangePicker.endOfDateTime) {
                  setValueRangePicker({
                    ...valueRangePicker,
                    startOfDateTime: e,
                    endOfDateTime: null,
                  });
                } else {
                  setValueRangePicker({
                    ...valueRangePicker,
                    startOfDateTime: formatDateTime(e, formatTime),
                  });
                }
              }}
            />
          </FormControl>
          <FormControl margin="dense" fullWidth>
            <Typography variant="subtitle2" mb={2}>
              BY END DATE TIME
            </Typography>
            <DateTimePicker
              required
              disabled={valueRangePicker.startOfDateTime === null}
              value={valueRangePicker.endOfDateTime}
              minDate={valueRangePicker.startOfDateTime || new Date()}
              onChange={e => {
                setValueRangePicker({
                  ...valueRangePicker,
                  endOfDateTime: e,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY VOUCHER PRIORITY
            </Typography>
            <Select
              labelId="priority_eq"
              displayEmpty
              name="priority_eq"
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.priority_eq}
              placeholder="Select Voucher Priority"
              size="small"
              selectOptions={[
                createData('Priority', 'true'),
                createData('Non-priority', 'false'),
              ]}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <Typography variant="subtitle2" mb={2} mt={3}>
              BY VOUCHER CATEGORY
            </Typography>
            <MultipleSelectCheckbox
              placeholder="Select by Voucher Category"
              value={voucherCategories}
              onChange={(e, values) => {
                setVoucherCategories([...values]);
                onSelectMultiple(values, 'ticketCategoryIds_contains');
                setTicketCategoryContain(values.map(item => item.value));
                setTicketCategoryLabel(values.map(item => item.title));
              }}
              options={optionVoucherCategory}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <Typography variant="subtitle2" mb={2} mt={3}>
              BY FEATURED VOUCHER
            </Typography>
            <Select
              labelId="orderFeaturedTicket_isnull"
              displayEmpty
              name="orderFeaturedTicket_isnull"
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.orderFeaturedTicket_isnull}
              placeholder="Select to display only featured voucher"
              size="small"
              selectOptions={[createData('Yes', '2'), createData('No', '1')]}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleCleanModalForm}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={
                !valueFilter.usageType_eq &&
                !valueFilter.merchantIdNumber_eq &&
                !valueFilter.titleOrExcerpt_eq &&
                !valueRangePicker.startOfDateTime &&
                !valueRangePicker.endOfDateTime &&
                !valueFilter.priority_eq &&
                !ticketCategoryContain &&
                !valueFilter.orderFeaturedTicket_isnull &&
                !valueFilter.isExclusive_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
