/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import MUIRichTextEditor from 'mui-rte';
// style
import {
  StyledRichTextEditorWrapper,
  StyledRichTextEditor,
  StyledErrorText,
} from './RichTextEditor.style';

interface RichTextEditorProps {
  label?: string;
  defaultValue?: any;
  error?: boolean;
  readOnly?: boolean;
  key?: string | number;
  helperText?: React.ReactNode;
  onSave?: (data: string) => void;
  onChange?: (state: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

function RichTextEditor({
  label,
  defaultValue,
  error,
  readOnly,
  key,
  helperText,
  onSave,
  onChange,
  onFocus,
  onBlur,
  ...other
}: RichTextEditorProps) {
  const [value, setValue] = useState('');
  useEffect(() => {
    // this effect to force reload this component when defaultValue is changing
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <>
      <StyledRichTextEditorWrapper>
        <StyledRichTextEditor error={error}>
          <MUIRichTextEditor
            label={label}
            key={key}
            defaultValue={value}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onSave={onSave}
            {...other}
          />
        </StyledRichTextEditor>
        {error && <StyledErrorText>{helperText}</StyledErrorText>}
      </StyledRichTextEditorWrapper>
    </>
  );
}
export default RichTextEditor;
