import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Typography} from '@mui/material';
import {path} from '../../../routes/path';

// styles
import {Button, Wrapper} from './ResetPasswordExpired.style';

function ResetPasswordExpired() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Helmet title="Reset Password Link Expired" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Oops!
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Your password reset link has expired.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        Please initiate a new request.
      </Typography>

      <Button
        onClick={() => navigate(`/${path.AUTH}/${path.FORGOT_PASSWORD}`)}
        variant="contained"
        color="secondary"
        mt={2}
      >
        Request New Password Reset
      </Button>
    </Wrapper>
  );
}

export default ResetPasswordExpired;
