const success = {
  CREATE_SUCCESS: 'Create success!',
  UPDATE_SUCCESS: 'Update success!',
  DELETE_SUCCESS: 'Delete success!',
  APPROVAL_SUCCESS: 'Approval success!',
  DECLINED_SUCCESS: 'Declined success!',
  DELETE_SUCCESS: 'Delete success!',
  EXPORT_SUCCESS: 'Export success! Please check your email',
  ADD_FEATURED: 'Added to featured voucher list',
};

export default success;
