/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {DateRange} from '@mui/lab/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {TRANSACTION_FILTER_TYPE_LIST} from '../../constants/transaction';
import {ADD, BONUS} from '../../constants/operation';
import {ColorType} from '../../types/typeChip';
import {Breadcrumbs, Button, Chip, Table, Tooltips} from '../../components';
import {path} from '../../routes/path';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import ModalFilter from './components/ModalFilter/ModalFilter';
import AllTransactionService from '../../services/AllTransaction/all-transaction.service';
import IslandPartnerService from '../../services/Islander/islander.service';
import PointMultiplierEventService from '../../services/PointMultiplierEvent/pointMultiplierEvent.service';
import {
  envRole,
  formatDate,
  formatDateTime,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
  formatLocalDateFromUTCTime,
} from '../../utils';
import {
  POINT_SUCCESS_COLOR,
  POINT_SUCCESS_LABEL,
  POINT_SUCCESS,
} from '../../constants/status';
import useAuth from '../../hooks/useAuth';
import {ROLE_SDC_ADMIN, USER_TYPE} from '../../constants/UserType';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import queryString from 'query-string';
import success from '../../constants/success';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledStack,
  StyledAction,
  StyledIcon,
  StyledColorPoints,
} from './AllTransaction.style';

function AllTransaction() {
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const membershipId = locationUrl?.state?.membershipId;
  const merchantID = locationUrl?.state?.merchantId;

  const format = 'DD/MM/YYYY';
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const {getUser, showNotification} = useAuth();
  const user = getUser() || {};
  const {merchantId, email} = user;
  const userType = +envRole;

  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [optionByStatus, setOptionByStatus] = useState([]);
  const [nameContainsList, setNameContainsList] = useState([]);

  const searchParamsObject = queryString.parse(searchParams.toString());

  const [valueFilter, setValueFilter] = useState({
    merchantId_eq: (merchantID || searchParams.get('merchantId_eq')) ?? '',
    processId_eq: searchParams.get('processId_eq') ?? '',
    createdDateTime_lte: searchParams.get('createdDateTime_lte') ?? '',
    createdDateTime_gte: searchParams.get('createdDateTime_gte') ?? '',
    pointMultiplierEventCreationId_eq:
      searchParams.get('pointMultiplierEventCreationId_eq') ?? '',
    pointMultiplierEventTitle_contains:
      searchParams.get('pointMultiplierEventTitle_contains') ?? '',
    transactionFilterType_by:
      searchParamsObject?.transactionFilterType_by !== undefined
        ? searchParamsObject?.transactionFilterType_by
            .split(',')
            .reduce((transactions, key) => {
              transactions.push({
                title: TRANSACTION_FILTER_TYPE_LIST[key],
                value: key,
              });
              return transactions;
            }, [])
        : undefined,
  });
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [params, setParams] = useState({
    ...{
      _page: 0,
      _size: 10,
      merchantId_eq: merchantID || undefined,
      membershipId_eq: membershipId || undefined,
      pointMultiplierEventCreationId_eq:
        searchParamsObject?.pointMultiplierEventCreationId_eq || undefined,
      pointMultiplierEventTitle_contains:
        searchParamsObject?.pointMultiplierEventTitle_contains || undefined,
      transactionFilterType_by:
        searchParamsObject?.transactionFilterType_by !== undefined
          ? searchParamsObject?.transactionFilterType_by
          : undefined,
    },
    ...{
      merchantId_eq: searchParamsObject.merchantId_eq ?? undefined,
      processId_eq: searchParamsObject.processId_eq ?? undefined,
      createdDateTime_lte: searchParamsObject.createdDateTime_lte ?? undefined,
      createdDateTime_gte: searchParamsObject.createdDateTime_gte ?? undefined,
    },
    ...(role === 'merchant' && merchantId
      ? {
          merchantId_eq: merchantId,
        }
      : {}),
    ...(role !== 'merchant' && merchantID
      ? {
          merchantId_eq: merchantID,
        }
      : {}),
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'transactionNo',
      numeric: false,
      disablePadding: false,
      label: 'Transaction No',
    },
    {
      dataIndex: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: 'Transacted Date Time',
    },
    {
      dataIndex: 'expiryDate',
      numeric: false,
      disablePadding: false,
      label: 'Points Expiry Date',
    },
    {
      dataIndex: 'membershipId',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {
      dataIndex: 'limitedEventId',
      numeric: false,
      disablePadding: false,
      label: 'Limited Event Id',
    },
    {
      dataIndex: 'limitedEventTitle',
      numeric: false,
      disablePadding: false,
      label: 'Limited Event Title',
    },
    {
      dataIndex: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount Spent',
    },
    {
      dataIndex: 'points',
      numeric: false,
      disablePadding: false,
      label: 'Points',
    },
    {
      dataIndex: 'transactional_type',
      numeric: false,
      disablePadding: false,
      label: 'Transactional Type',
    },
    {
      dataIndex: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Transaction Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onClickIconButton = idTransaction => {
    if (membershipId) {
      return navigate(
        `/${path.ALL_TRANSACTION}/${idTransaction}?membershipId=${membershipId}`,
        {
          state: {
            membershipId: membershipId,
            filterParams: {
              ...queryString.parse(searchParams.toString()),
            },
          },
        }
      );
    }
    if (merchantID) {
      return navigate(
        `/${path.ALL_TRANSACTION}/${idTransaction}?merchantId=${merchantID}`,
        {
          state: {
            merchantId: merchantID,
            filterParams: {
              ...queryString.parse(searchParams.toString()),
            },
          },
        }
      );
    }
    return navigate(`/${path.ALL_TRANSACTION}/${idTransaction}`, {
      state: {
        membershipId: null,
        merchantId: null,
        filterParams: {
          ...queryString.parse(searchParams.toString()),
        },
      },
    });
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  onClickIconButton(value);
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                onClickIconButton(value);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (value) {
      let renderColor = POINT_SUCCESS_COLOR[value.processId];
      let renderLabel = POINT_SUCCESS_LABEL[value.processId];
      const expiredDate = value.expiryDateTime;
      const now = new Date();
      if (
        value.status === POINT_SUCCESS.PENDING &&
        new Date(expiredDate).getTime() < now.getTime()
      ) {
        renderColor = POINT_SUCCESS_COLOR[4];
        renderLabel = POINT_SUCCESS_LABEL[6];
      }
      return <Chip label={renderLabel} color={renderColor as ColorType} />;
    }
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'date') {
      deleteSearchParams('createdDateTime_lte');
      deleteSearchParams('createdDateTime_gte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        createdDateTime_lte: '',
        createdDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDateTime_lte: undefined,
        createdDateTime_gte: undefined,
      }));
    }

    if (type === 'merchantId_eq') {
      deleteSearchParams('merchantId_eq');
      deleteSearchParams('merchantId_label');
      setValueFilter({
        ...valueFilter,
        merchantId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        merchantId_eq: undefined,
        ...(role === 'merchant' && merchantId
          ? {
              merchantId_eq: merchantId,
            }
          : {}),
      }));
    }

    if (type === 'pointMultiplierEventTitle_contains') {
      deleteSearchParams('pointMultiplierEventTitle_contains');
      setValueFilter({
        ...valueFilter,
        pointMultiplierEventTitle_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointMultiplierEventTitle_contains: undefined,
      }));
    }

    if (type === 'pointMultiplierEventCreationId_eq') {
      deleteSearchParams('pointMultiplierEventCreationId_eq');
      deleteSearchParams('pointMultiplierEventCreationId_label');
      setValueFilter({
        ...valueFilter,
        pointMultiplierEventCreationId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        pointMultiplierEventCreationId_eq: undefined,
      }));
    }
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const renderProcessLabelFilter = () => {
    const object = optionByStatus.find(
      item => item.value === valueFilter?.processId_eq
    );
    if (object === undefined || object === null) {
      return searchParams.get('processId_label') ?? undefined;
    }
    return object?.label;
  };

  const renderMerchantLabel = () => {
    if (
      nameContainsList.length === 0 &&
      searchParams.get('merchantId_label') !== null
    ) {
      return searchParams.get('merchantId_label');
    } else {
      return valueFilter?.merchantId_eq !== ''
        ? nameContainsList?.find(
            item => item.value === valueFilter?.merchantId_eq
          )?.label
        : undefined;
    }
  };

  const renderFieldPoints = value => {
    if (value) {
      if (value.pointValue > 0) {
        return (
          <StyledColorPoints className={`points points--${value.operation}`}>
            {value.operation === ADD || value.operation === BONUS
              ? `+ ${value.pointValue}`
              : `- ${value.pointValue}`}
          </StyledColorPoints>
        );
      }
      return <StyledColorPoints>{value.pointValue}</StyledColorPoints>;
    }
  };

  const handleClean = () => {
    setValueRangePicker([null, null]);
    setValueFilter({
      processId_eq: '',
      transactionFilterType_by: undefined,
      merchantId_eq: '',
      createdDateTime_lte: '',
      createdDateTime_gte: '',
      pointMultiplierEventCreationId_eq: '',
      pointMultiplierEventTitle_contains: '',
    });
  };

  const handleFilter = () => {
    if (valueFilter?.processId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        processId_eq: valueFilter?.processId_eq,
      }));

      if (valueFilter?.processId_eq === POINT_SUCCESS.EXPIRED) {
        setParams(preState => ({
          ...preState,
          _page: 0,
          status_eq: 2,
          expireDateTime_lt: formatDateTime(new Date(), formatDateTimes),
        }));
      } else {
        setParams(preState => ({
          ...preState,
          _page: 0,
          status_eq: 1,
          expireDateTime_lt: undefined,
        }));
      }
    }

    if (valueRangePicker[0] && valueRangePicker[1]) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: getEndOfDate(valueRangePicker[1], format),
        createdDateTime_gte: getStartOfDate(valueRangePicker[0], format),
      }));
    } else if (
      valueFilter?.createdDateTime_lte !== '' &&
      valueFilter?.createdDateTime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: valueFilter?.createdDateTime_lte,
        createdDateTime_gte: valueFilter?.createdDateTime_gte,
      }));
    }

    if (valueFilter?.transactionFilterType_by) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        transactionFilterType_by: valueFilter?.transactionFilterType_by
          .map(item => item.value)
          .toString(),
      }));
    }

    if (valueFilter?.pointMultiplierEventTitle_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointMultiplierEventTitle_contains:
          valueFilter?.pointMultiplierEventTitle_contains,
      }));
    }

    if (valueFilter?.merchantId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        merchantId_eq: valueFilter?.merchantId_eq,
      }));
    }

    if (valueFilter?.pointMultiplierEventCreationId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        pointMultiplierEventCreationId_eq:
          valueFilter?.pointMultiplierEventCreationId_eq,
      }));
    }
    toggleModal();
  };

  const renderTransactionType = dataDetail => {
    if (
      dataDetail?.operation === 1 &&
      dataDetail?.workflowId !== null &&
      dataDetail?.pointMultiplierEventCreationId !== null
    ) {
      return `Bonus Points associated with Transaction No: ${dataDetail?.workflowId}`;
    }

    if (
      dataDetail?.operation === 1 &&
      dataDetail?.pointValue > 0 &&
      dataDetail?.ticketId === null
    ) {
      return `Earn points at ${dataDetail.islandPartnerName}`;
    }

    if (dataDetail?.operation === 2 && dataDetail?.islandPartnerName === null) {
      return `Get voucher ID: ${dataDetail.ticketNumberId}${
        dataDetail?.secondTicketNumberId
          ? ', ' + dataDetail?.secondTicketNumberId
          : ''
      }`;
    }

    if (
      dataDetail?.islandPartnerName !== null &&
      dataDetail?.ticketId !== null
    ) {
      return `Used voucher ID: ${dataDetail.ticketNumberId}${
        dataDetail?.secondTicketNumberId
          ? ', ' + dataDetail?.secondTicketNumberId
          : ''
      }`;
    }
  };

  const getListAllTransaction = () => {
    setIsLoading(true);
    if (role) {
      new AllTransactionService()
        .getAll({
          ...params,
          processId_eq:
            valueFilter?.processId_eq === POINT_SUCCESS.EXPIRED
              ? undefined
              : params.processId_eq,
        })
        .then(res => {
          if (res?.data && Array.isArray(res.data.items)) {
            setDataSource(res.data.items);
            setTotalPage(res?.data?.total);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          // Get api error => show notification or no items listing
          setError(error);
        });
    }
  };

  useEffect(() => {
    if (role === ROLE_SDC_ADMIN[USER_TYPE.ADMINISTRATOR]) {
      if (merchantID || membershipId) {
        // populate nameContainsList
        new IslandPartnerService()
          .getAll({
            id_eq: role === 'merchant' ? merchantId : undefined,
            status_eq: 1,
            _size: 2147483647,
          })
          .then(res => {
            if (res?.data?.items) {
              setNameContainsList(
                res?.data?.items?.reduce((items, item) => {
                  items.push({
                    label: item.name || '-',
                    value: item.id,
                  });
                  return items;
                }, [])
              );
            }
          });
      }
    }
  }, []);

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelAllTransaction({
        ...params,
        email: email,
        exportCSV_userType: userType,
        processId_eq:
          valueFilter?.processId_eq === POINT_SUCCESS.EXPIRED
            ? undefined
            : params.processId_eq,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        processId_label:
          valueFilter?.processId_eq !== ''
            ? renderProcessLabelFilter()
            : undefined,
        merchantId_label: renderMerchantLabel(),
      })
    );
    getListAllTransaction();
  }, [params, role]);

  return (
    <React.Fragment>
      <Helmet title="All Transaction" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        valueRangePicker={valueRangePicker}
        setValueFilter={setValueFilter}
        setValueRangePicker={setValueRangePicker}
        setOptionByStatus={setOptionByStatus}
        optionByStatus={optionByStatus}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            All Transaction
          </Typography>
          <Breadcrumbs routes={[typeRoutes('All Transaction', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('createdDateTime_lte') !== null &&
              searchParams.get('createdDateTime_gte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('createdDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('createdDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}
            {searchParams.get('pointMultiplierEventCreationId_eq') !== null && (
              <Chip
                label={`${searchParams.get(
                  'pointMultiplierEventCreationId_eq'
                )}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointMultiplierEventCreationId_eq');
                }}
              />
            )}

            {searchParams.get('pointMultiplierEventTitle_contains') !==
              null && (
              <Chip
                label={`${searchParams.get(
                  'pointMultiplierEventTitle_contains'
                )}`}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('pointMultiplierEventTitle_contains');
                }}
              />
            )}

            {role === ROLE_SDC_ADMIN[USER_TYPE.ADMINISTRATOR] &&
              searchParams.get('merchantId_label') !== null && (
                <Chip
                  label={searchParams.get('merchantId_label')}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('merchantId_eq');
                  }}
                />
              )}

            {searchParams.get('processId_label') !== null && (
              <Chip
                label={searchParams.get('processId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('processId_eq');
                }}
              />
            )}

            {searchParams.get('transactionFilterType_by') &&
              valueFilter?.transactionFilterType_by?.map((item, index) => (
                <Chip
                  label={item.title}
                  color={'default' as ColorType}
                  onDelete={() => {
                    valueFilter?.transactionFilterType_by?.splice(index, 1);
                    setValueFilter({
                      ...valueFilter,
                      transactionFilterType_by:
                        valueFilter?.transactionFilterType_by,
                    });
                    setParams(preState => ({
                      ...preState,
                      _page: 0,
                      _size: 10,
                      transactionFilterType_by:
                        valueFilter?.transactionFilterType_by.length > 0
                          ? valueFilter?.transactionFilterType_by
                              .map(item => item.value)
                              .toString()
                          : undefined,
                    }));
                  }}
                />
              ))}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no all transaction(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTimes
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(row.expiryDateTime, format)}
                  </TableCell>
                  <TableCell align="left">{row.customerId}</TableCell>
                  <TableCell align="left">
                    {row.pointMultiplierEventCreationId}
                  </TableCell>
                  <TableCell align="left">
                    {row?.pointMultiplierEventTitle}
                  </TableCell>
                  <TableCell align="left">{`$ ${parseFloat(
                    row.spentAmount
                  ).toFixed(2)}`}</TableCell>
                  <TableCell align="left">{renderFieldPoints(row)}</TableCell>
                  <TableCell align="left">
                    {renderTransactionType(row)}
                  </TableCell>
                  <TableCell align="left">{renderStatus(row)}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default AllTransaction;
