import React, {useState, useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {images} from '../../constants/image';
import LinearProgress from '@mui/material/LinearProgress';

import {
  StyledWrap,
  TypographyFirst,
  TypographySecond,
  LinkUpload,
  Sub,
  StyledImage,
  SectionWrap,
  ComponentWrap,
  SectionWrapItem,
  NormalText,
  Icon,
  SubText,
  SectionWrapItemFail,
} from './InputFile.style';
import {Box, Typography} from '@mui/material';
import Button from '../Button/Button';

// services
import MobileAssetsService from '../../services/MobileAssets/mobile-assets.service';

const InputFile = props => {
  const {acceptProps = null} = props;
  const [fileInfos, setFileInfos] = useState<any>([]);
  const [progress, setProgress] = useState({});
  const [errorProgressMessage, setErrorProgressMessage] = useState({});

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setProgress({});
    setErrorProgressMessage({});
    let errorItems = {};
    acceptedFiles.forEach(file => {
      if (file && file.size >= 20971520) {
        errorItems = {
          ...errorItems,
          [file.name]: {label: 'Uploaded file is too large', type: 'select'},
        };
      }
    });

    setErrorProgressMessage(errorItems);
    setFileInfos(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} =
    useDropzone({
      onDrop,
      accept: acceptProps,
    });

  let objectProgress = {};
  const onPostProgress = (data, key) => {
    objectProgress = {
      ...objectProgress,
      [key]: Math.floor((100 * data.loaded) / data.total),
    };
    setProgress(objectProgress);
  };

  // let objectProgressAgain = progress;
  // const onPostProgressAgain = (data, key) => {
  //   objectProgressAgain = {
  //     ...objectProgressAgain,
  //     [key]: Math.floor((100 * data.loaded) / data.total),
  //   };
  //   setProgress(objectProgressAgain);
  // };

  const removeFile = (key: any) => {
    setFileInfos(fileInfos.filter(item => item.name !== key));
  };

  let errorItems = errorProgressMessage;
  const actionStoreFileInRedis = (
    file: any,
    onPostProgress = (data: any, key: any) => {}
  ) => {
    new MobileAssetsService()
      .StoreFileInRedis(file, onPostProgress)
      .then(res => {
        if (res && !res.success) {
          errorItems = {
            ...errorItems,
            [file.name]: {label: res.errorMessage, type: 'uploading'},
          };
          setErrorProgressMessage(errorItems);
        }
      })
      .catch(ex => {
        errorItems = {
          ...errorItems,
          [file.name]: {
            label: 'Upload failed, please try again',
            type: 'uploading',
          },
        };

        setErrorProgressMessage(errorItems);
      });
  };

  // const validateFileName = () => {
  //   const fileNames = fileInfos.map(file => file?.name);
  //   new MobileAssetsService()
  //     .ValidateFileNameInRedis({fileName: fileNames})
  //     .then(res => {
  //       console.log(res);
  //     });
  // };

  const handleUploadToRedis = () => {
    // await validateFileName();
    acceptedFiles.forEach((file, index) => {
      if (errorProgressMessage[file.name] || progress[file.name] === 100)
        return;
      if (fileInfos.find(item => item.name === file.name)) {
        actionStoreFileInRedis(file, onPostProgress);
      }
    });
  };

  // const handleTryAgain = (key: any) => {
  //   acceptedFiles.forEach((file, index) => {
  //     if (
  //       fileInfos.find(item => item.name === file.name) &&
  //       key === file.name
  //     ) {
  //       const errorProgressMessageNew = errorProgressMessage;
  //       delete errorProgressMessageNew[key];
  //       setErrorProgressMessage(errorProgressMessageNew);
  //       actionStoreFileInRedis(file, onPostProgressAgain);
  //     }
  //   });
  // };

  const handleCancel = (key: any) => {
    const errorProgressMessageNew = errorProgressMessage;
    delete errorProgressMessageNew[key];
    setErrorProgressMessage(errorProgressMessageNew);
    setFileInfos(fileInfos.filter(item => item.name !== key));
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <ComponentWrap>
      <SectionWrap className="container">
        <StyledWrap {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <StyledImage src={images.icon_upload} width={84} height={70} />
          <TypographyFirst>Drag and drop files</TypographyFirst>
          <TypographySecond>or</TypographySecond>
          <TypographyFirst>
            <LinkUpload>Browse</LinkUpload> to upload
          </TypographyFirst>
          <Sub>Maximum upload file size: 20 MB</Sub>
        </StyledWrap>
      </SectionWrap>
      {fileInfos.map(item =>
        !errorProgressMessage[item?.name] ? (
          <SectionWrapItem key={item?.id}>
            <Icon src={images.icon_page} />
            <Box sx={{width: '100%'}}>
              <NormalText>{item?.name}</NormalText>
              <SubText>{formatBytes(item?.size, 2)}</SubText>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{width: '80%', mr: 2}}>
                  <LinearProgress
                    variant="determinate"
                    value={progress?.[item?.name] || 0}
                    className={
                      progress?.[item?.name] && progress?.[item?.name] === 100
                        ? 'linear__progress--completed'
                        : 'linear__progress'
                    }
                  />
                </Box>
                <Box sx={{minWidth: 35}}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(progress?.[item?.name] || 0)}%`}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{position: 'absolute', top: '10px', right: '10px'}}>
              {progress?.[item?.name] === 100 ? (
                <Icon src={images.icon_upload_success} />
              ) : (
                <Icon
                  src={images.icon_trash}
                  onClick={() => removeFile(item?.name)}
                />
              )}
            </Box>
          </SectionWrapItem>
        ) : (
          <SectionWrapItemFail key={item?.id}>
            <Icon src={images.icon_page_error} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <NormalText className="error-title">{item?.name}</NormalText>
              <SubText className="error-sub">
                {errorProgressMessage[item?.name].label}
              </SubText>
              {/* {errorProgressMessage[item?.name].type === 'uploading' ? (
                <Box
                  className="error-btn"
                  onClick={() => handleTryAgain(item?.name)}
                >
                  Try again
                </Box>
              ) : ( */}
              <Box
                className="error-btn"
                onClick={() => handleCancel(item?.name)}
              >
                Cancel
              </Box>
              {/* )} */}
            </Box>
            <Box sx={{position: 'absolute', top: '10px', right: '10px'}}>
              <Icon
                onClick={() => removeFile(item?.name)}
                src={images.icon_trash_error}
                className="error-trash"
              />
            </Box>
          </SectionWrapItemFail>
        )
      )}
      <Box
        sx={{padding: '30px 0px', display: 'flex', justifyContent: 'flex-end'}}
      >
        <Button onClick={handleUploadToRedis}>Upload to App’s Storage</Button>
      </Box>
    </ComponentWrap>
  );
};

export default InputFile;
