/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement, ReactNode} from 'react';
// style
import {StyledTooltips} from './Tooltips.style';

interface TooltipProps {
  title: ReactNode;
  children: ReactElement<any, any>;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  disableInteractive?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  arrow?: boolean;
}

function Tooltips({
  title,
  children,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  disableInteractive,
  placement,
  open,
  onOpen,
  onClose,
  arrow = true,
  ...other
}: TooltipProps) {
  return (
    <StyledTooltips
      title={title}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      disableInteractive={disableInteractive}
      placement={placement}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      arrow={arrow}
      {...other}
    >
      {children}
    </StyledTooltips>
  );
}
export default Tooltips;
