/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {DialogContent, Grid, Stack} from '@mui/material';
import {Modal, Button, Select, TextArea, Input} from '../../../../components';
import {images} from '../../../../constants/image';
import FormControl from '@mui/material/FormControl';

// style
import {
  StyledModalActions,
  StyledIconClose,
  StyledModalTitle,
  Divider,
  Typography,
  StyledTitle,
} from './ModalEditPoints.style';

interface ModalEditPointsProps {
  visible: boolean;
  onClose?: () => void;
  onEditPoints?: () => void;
}

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

function ModalEditPoints({
  visible,
  onClose,
  onEditPoints,
}: ModalEditPointsProps) {
  const [valuePoints, setValuePoints] = useState('');

  const onChange = (e: any) => {
    setValuePoints(e.target.value);
  };

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="xs">
      <>
        <StyledModalTitle>Manage Points</StyledModalTitle>
        <Typography>Add or remove member’s points</Typography>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <DialogContent sx={{marginLeft: '23px', marginRight: '23px'}}>
          <FormControl fullWidth margin="normal">
            <StyledTitle>Action</StyledTitle>
            <Select
              labelId="by-tier"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valuePoints}
              placeholder="Select by tier"
              size="small"
              selectOptions={[
                createData('Add', 'Add'),
                createData('Deduct', 'Deduct'),
              ]}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <StyledTitle>Points</StyledTitle>
            <Input placeholder="Enter Points" size="small" variant="outlined" />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <StyledTitle>Note</StyledTitle>
            <TextArea fullWidth maxRows={4} />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="outlined" onClick={onClose} width="125px">
                  Deny
                </Button>
                <Button
                  variant="contained"
                  onClick={onEditPoints}
                  width="125px"
                >
                  OK
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalEditPoints;
