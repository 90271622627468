import styled from 'styled-components/macro';
import {spacing, display} from '@mui/system';
import {
  Paper,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';

const ResetPasswordRoot = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};
  min-width: 456px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
  .btn-reset_password {
    margin-top: 10px;
  }
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const StyledDivBreadcrumbs = styled.div`
  margin-top: 10px;
`;

export {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
  ResetPasswordRoot,
  Wrapper,
};
