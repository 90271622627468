import {getHmacHashCode} from '../../utils';
import BaseApiService from '../baseApi.service';

export default class GuestsService extends BaseApiService {
  readonly domain: string | undefined | null =
    process.env.REACT_APP_APIM_DOMAIN;
  readonly basePath: string = 'guest/v1';
  constructor(props = null) {
    super(props);
  }

  resetPassword = async (email: string) => {
    const objPayLoad = {
      email: email,
      action: '',
    };
    const hCode = getHmacHashCode(objPayLoad);
    return await this.post('forgotpassword', objPayLoad, {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_B2C_SUBSCRIPTION_KEY,
      hCode,
    })
      .then(res => res)
      .catch(error => {
        const res = error.toJSON();
        return {
          code: res.status,
          message: res.message,
          errorMessage: res.message,
          data: res,
          success: false,
        };
      });
  };
}
