/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Box, Grid, IconButton} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {Tooltips} from '../../components';
import {formatDate, formatLocalDateFromUTCTime} from '../../utils';

// style
import {
  Card,
  Typography,
  CardContent,
  StyledImage,
  StyledIcon,
} from './Stats.style';

type StatsProps = {
  title: string;
  amount: string | number;
  icon: any;
  illustration?: string;
  date?: string;
  tierExpiryDate?: string;
  onClick?: () => void;
  isManage?: boolean;
  isShowCashSign?: boolean;
  helperText?: string | null;
};

const Stats: React.FC<StatsProps> = ({
  title,
  amount,
  icon,
  illustration,
  date,
  tierExpiryDate,
  onClick,
  isManage = true,
  isShowCashSign = false,
  helperText,
}) => {
  const format = 'DD/MM/YYYY';

  const [isEdit, setIsEdit] = useState(false);

  return (
    <Card
      illustration={illustration}
      onMouseEnter={() => {
        setIsEdit(!isEdit);
      }}
      onMouseLeave={() => {
        setIsEdit(false);
      }}
    >
      <CardContent>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item pl={1}>
            <StyledImage>
              <img src={icon} />
            </StyledImage>
          </Grid>
          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pr={1}
          >
            <Typography variant="h2" textAlign="right">
              <Box
                style={{
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {isEdit && isManage && (
                  <Tooltips title="Manage">
                    <IconButton size="medium" onClick={onClick}>
                      <Edit style={StyledIcon} />
                    </IconButton>
                  </Tooltips>
                )}
                {isShowCashSign ? `$${amount}` : `${amount}`}
              </Box>
            </Typography>
            <Typography variant="subtitle2" textAlign="right">
              {title}
            </Typography>
            {helperText ? (
              <Typography component="em" display="block" textAlign="right">
                {helperText}
              </Typography>
            ) : (
              ''
            )}
            {date ? (
              <>
                <Typography variant="inherit" textAlign="right">
                  Promoted Date: {formatLocalDateFromUTCTime(date, format)}
                </Typography>
                <Typography variant="inherit" textAlign="right">
                  Expected Expiry Date:{' '}
                  {formatLocalDateFromUTCTime(tierExpiryDate, format)}
                </Typography>
              </>
            ) : (
              <Typography variant="inherit" textAlign="right">
                &nbsp;
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;
