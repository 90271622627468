/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Box, FormControl, Grid, Grow, TextField} from '@mui/material';
import {Modal, Button, Chip} from '../../../../components';
import {ColorType} from '../../../../types/typeChip';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalMonthSelection.style';
import {range} from 'lodash';
import {grey} from '@mui/material/colors';
import {Event} from '@mui/icons-material';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

interface ModalMonthSelectionProps {
  visible: boolean;
  onClose: () => void;
  cashoutPeriod: string[];
  onMonthSelected?: (any) => void;
}

const getCurrentMonthValue = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setFullYear(date.getFullYear());
  return date;
};

const getMinMonthValue = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return date;
};

const getYearRange = () => {
  return range(
    getMinMonthValue().getFullYear(),
    getCurrentMonthValue().getFullYear() + 1
  );
};

function ModalMonthSelection({
  visible,
  onClose,
  cashoutPeriod,
  onMonthSelected,
}: ModalMonthSelectionProps) {
  const [monthSelections, setMonSelections] = useState<string[]>([]);
  const [disabledCashout, setDisabledCashout] = useState(false);

  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [yearSelected, setYearSelected] = useState<number>();
  const [monthSelected, setMonthSelected] = useState<string>();
  const [monthValue, setMonthValue] = useState<string>();

  const onConfirm = () => {
    if (onMonthSelected) {
      setMonSelections(monthSelections);
      onMonthSelected(monthSelections);
      onClose();
    }
  };

  const onMonthPickerChanged = value => {
    const newMonths: string[] = [...monthSelections];
    if (!newMonths.includes(`${value}-${yearSelected}`.toUpperCase())) {
      newMonths.push(`${value}-${yearSelected}`.toUpperCase());
      setMonSelections(newMonths);
      setMonthSelected(value);
      setMonthValue(`${value}-${yearSelected}`.toUpperCase());
    }
    setMonthPickerOpen(false);
    setYearPickerOpen(false);
  };

  const handleDeleteMonth = value => {
    const months: string[] = [...monthSelections];
    const index = months.indexOf(value);
    if (index !== -1) {
      months.splice(index, 1);
      setMonSelections(months);
    }
  };

  const checkValidMonth = index => {
    if (yearSelected === getMinMonthValue().getFullYear()) {
      return index >= getMinMonthValue().getMonth();
    } else if (yearSelected === getCurrentMonthValue().getFullYear()) {
      return index <= getCurrentMonthValue().getMonth();
    }
    return true;
  };

  useEffect(() => {
    if (visible) {
      setMonSelections([]);
      setYearPickerOpen(false);
      setMonthPickerOpen(false);
    }
  }, [visible]);

  useEffect(() => {
    setDisabledCashout(
      monthSelections.some(r => cashoutPeriod.indexOf(r) < 0) ||
        monthSelections.length === 0
    );
  }, [monthSelections]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="xs">
      <>
        <StyledModalTitle>Month Selection</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sx={{width: '100%'}}>
              <FormControl variant="standard" fullWidth>
                <Typography variant="body2" mb={2} mt={3}>
                  Month selection <span className="mandatory-label">(*)</span>
                </Typography>
                <TextField
                  value={monthSelections.length > 0 ? monthValue : ''}
                  onClick={() => {
                    setYearSelected(getCurrentMonthValue().getFullYear());
                    setMonthSelected('');
                    setYearPickerOpen(true);
                  }}
                  InputProps={{
                    endAdornment: (
                      <Event
                        sx={{
                          color: grey[700],
                        }}
                      />
                    ),
                  }}
                />
                {isYearPickerOpen && (
                  <Grow in>
                    <Grid
                      container
                      spacing={{xs: 2, md: 3}}
                      columns={{xs: 4, sm: 8, md: 12}}
                      marginTop={2}
                    >
                      {getYearRange().map(item => (
                        <Grid item xs={2} sm={4} md={4} marginTop={2}>
                          <Typography
                            sx={{
                              backgroundColor: `${
                                item === yearSelected ? '#376fd0' : '#ffffff'
                              }`,
                              fontSize: 15,
                              color: `${
                                item === yearSelected ? '#ffffff' : '#000000'
                              }`,
                              ':hover': {
                                backgroundColor: `${
                                  item === yearSelected ? '#2052c2' : '#ffffff'
                                }`,
                              },
                              borderRadius: 10,
                              padding: 2,
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setYearSelected(item);
                              setMonthPickerOpen(true);
                            }}
                          >
                            {item}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grow>
                )}
                {isMonthPickerOpen && (
                  <Grow in>
                    <Box marginTop={5}>
                      <Grid
                        container
                        spacing={{xs: 2, md: 3}}
                        columns={{xs: 4, sm: 8, md: 12}}
                      >
                        {months.map((item, index) => (
                          <Grid item xs={2} sm={4} md={4} key={index}>
                            <Typography
                              sx={{
                                backgroundColor: `${
                                  item === monthSelected ? '#376fd0' : '#ffffff'
                                }`,
                                fontSize: 15,
                                color: `${
                                  checkValidMonth(index)
                                    ? item === monthSelected
                                      ? '#ffffff'
                                      : '#000000'
                                    : grey[500]
                                }`,
                                ':hover': {
                                  backgroundColor: `${
                                    item === monthSelected
                                      ? '#2052c2'
                                      : '#ffffff'
                                  }`,
                                },
                                borderRadius: 10,
                                padding: 2,
                                textAlign: 'center',
                                cursor: `${
                                  checkValidMonth(index) ? 'pointer' : 'cursor'
                                }`,
                              }}
                              onClick={() => {
                                if (checkValidMonth(index)) {
                                  onMonthPickerChanged(item);
                                }
                              }}
                            >
                              {item}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grow>
                )}
                <Grid container spacing={3} marginTop={2}>
                  {monthSelections.map(item => (
                    <Grid item>
                      <Chip
                        label={
                          cashoutPeriod.includes(item)
                            ? item
                            : `${item} cashed-out`
                        }
                        color={
                          cashoutPeriod.includes(item)
                            ? ColorType.primary
                            : ColorType.error
                        }
                        onDelete={() => {
                          handleDeleteMonth(item);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Divider variant="middle" pt={8} />
          <StyledModalActions>
            <div className="btn-action-1">
              <Button variant="text" type="button" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <div className="btn-action-2">
              <Button
                type="submit"
                onClick={onConfirm}
                disabled={disabledCashout}
              >
                Cash Out
              </Button>
            </div>
          </StyledModalActions>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalMonthSelection;
