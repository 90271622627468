/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {images} from '../../../../constants/image';
import {Modal, Select, Button, Input} from '../../../../components';
import success from '../../../../constants/success';
import {USER_ROLE, USER_TYPE} from '../../../../constants/UserType';
import useAuth from '../../../../hooks/useAuth';
import {DataAddNew} from '../../../../types/typeSDCAdministrators';
import AdministratorsService from '../../../../services/Administrators/administrators.service';
import IslanderService from '../../../../services/Islander/islander.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';

interface ModalAddNewProps {
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

function ModalAddNew({visible, onClose, reloadPage}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  // states
  const [islandPartners, setIslandPartners] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params] = useState({
    _page: 0,
    _size: 99999, // temp, check with be team if there is a value for all items
    status_eq: 1,
    _sort: 'name:asc',
  });
  const [initialValues] = useState<DataAddNew>({
    firstName: '',
    lastName: '',
    designation: '',
    email: '',
    // type: USER_TYPE.ADMINISTRATOR,
    type: '',
    islandPartner: '',
    phoneNumber: '',
  });

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService().getAll(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const items = res.data.items.map(item => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setIslandPartners(items);
        setIsLoading(false);
      }
    });
  };

  const onAddNew = values => {
    onClose();
    const newParams = {
      ...values,
      merchantId:
        values?.type.toString() === USER_TYPE.MERCHANT.toString()
          ? values.islandPartner
          : '',
      phoneNumber: values.phoneNumber.replace('+', ''),
    };
    new AdministratorsService().create(newParams).then(res => {
      if (res.success) {
        reloadPage();
        showNotification({
          message: success.CREATE_SUCCESS,
        });
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  useEffect(() => {
    if (visible) {
      getIslanderPartners();
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Add New SDC Administrators</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              firstName: Yup.string()
                .required('First name is required')
                .matches(
                  /^[a-zA-Z0-9 ]*$/,
                  'Please enter only alphabets and numbers for this field'
                ),
              lastName: Yup.string()
                .required('Last name is required')
                .matches(
                  /^[a-zA-Z0-9 ]*$/,
                  'Please enter only alphabets and numbers for this field'
                ),
              type: Yup.number().required('Type is required'),
              islandPartner: Yup.string().when('type', {
                is: USER_TYPE.MERCHANT,
                then: Yup.string().required('Island Partner is required'),
              }),
              phoneNumber: Yup.string()
                .required('Phone number is required')
                .matches(
                  /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}/,
                  'Phone number is invalid'
                ),
            })}
            onSubmit={onAddNew}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    First name <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="firstName"
                    name="firstName"
                    placeholder="Enter first name"
                    size="small"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Last name <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="lastName"
                    name="lastName"
                    placeholder="Enter last name"
                    size="small"
                    variant="outlined"
                    value={values.lastName}
                    onChange={handleChange}
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    error={Boolean(touched.lastName && errors.lastName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Designation
                  </Typography>
                  <Input
                    type="designation"
                    name="designation"
                    placeholder="Enter designation"
                    size="small"
                    variant="outlined"
                    value={values.designation}
                    onChange={handleChange}
                    helperText={touched.designation && errors.designation}
                    onBlur={handleBlur}
                    error={Boolean(touched.designation && errors.designation)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Phone number <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="phone"
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    size="small"
                    variant="outlined"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    onBlur={handleBlur}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Email <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    size="small"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: '20px'}}>
                  <Typography variant="body2" mb={2}>
                    Type <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Select
                    labelId="type"
                    name="type"
                    size="small"
                    variant="outlined"
                    displayEmpty
                    placeholder="Select Type"
                    onChangeSelect={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.type && errors.type}
                    error={Boolean(touched.type && errors.type)}
                    value={values.type.toString()}
                    selectOptions={Object.keys(USER_ROLE).reduce(
                      (roles, key) => {
                        roles.push({
                          label: USER_ROLE[key],
                          value: key,
                        });
                        return roles;
                      },
                      []
                    )}
                  />
                </FormControl>

                {values.type.toString() === '2' && (
                  <FormControl fullWidth sx={{marginBottom: '20px'}}>
                    <Typography variant="body2" mb={2}>
                      Island Partner{' '}
                      <span className="mandatory-label">(*)</span>
                    </Typography>
                    <Select
                      labelId="islandPartner"
                      placeholder="Select island partner"
                      name="islandPartner"
                      displayEmpty
                      size="small"
                      variant="outlined"
                      onChangeSelect={handleChange}
                      value={values.islandPartner}
                      selectOptions={islandPartners}
                      onBlur={handleBlur}
                      helperText={touched.islandPartner && errors.islandPartner}
                      error={Boolean(
                        touched.islandPartner && errors.islandPartner
                      )}
                    />
                  </FormControl>
                )}
                <Divider variant="fullWidth" />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button
                      type="submit"
                      loading={isLoading}
                      // disabled={disabled}
                    >
                      Add New
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
