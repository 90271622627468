import styled from 'styled-components/macro';
import {Toolbar} from '@mui/material';

const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledHeader = styled.div`
  background: white;
  border-start-end-radius: 4px;
  border-start-start-radius: 4px;
`;

const StyledToolbar = styled(Toolbar)``;
const StyleItemFilter = styled.div`
  background: #f0f0f0;
  padding: 10px 49px 10px 49px;
  border-radius: 50px;
  height: 41px;
  &.active {
    background: #1565c0;
    color: rgba(255, 255, 255, 0.87);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &.inactive {
    background: #f0f0f0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
  :hover {
    cursor: pointer;
  }
`;

export {Spacer, StyledHeader, StyledToolbar, StyleItemFilter};
