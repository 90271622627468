/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
import {Link} from '@mui/material';

// style
import {StyledBreadcrumbs, Typography} from './Breadcrumbs.style';

type RoutesProps = {
  name: string;
  path?: string;
  isCurrentPage?: boolean;
  state?: object;
};

interface BreadcrumbsProps {
  separator?: ReactNode;
  routes: Array<RoutesProps>;
}

function Breadcrumbs({separator, routes, ...other}: BreadcrumbsProps) {
  const mapRoutes = routes.find(item => item.isCurrentPage);
  return (
    <StyledBreadcrumbs aria-label="Breadcrumb" separator={separator} {...other}>
      {routes?.map((item, index) => {
        if (item?.path) {
          return (
            <Link
              underline="hover"
              color="primary"
              component={NavLink}
              to={item.path || '#'}
              key={index}
              state={item.state}
            >
              {item.name || '--'}
            </Link>
          );
        }
        return (
          <Typography
            key={index}
            color={`${mapRoutes?.isCurrentPage && 'text.primary'}`}
          >
            {item.name || '--'}
          </Typography>
        );
      })}
    </StyledBreadcrumbs>
  );
}
export default Breadcrumbs;
