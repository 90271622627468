import styled from 'styled-components/macro';
import {spacing} from '@mui/system';

import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  Stack,
  TableRow,
} from '@mui/material';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const StyledStack = styled(Stack)`
  margin-right: 10px;
`;
const StyledTableRow = styled(TableRow)``;

const StyledAction = styled.div``;

const StyledIcon = {
  width: '18px',
  height: '18px',
};

const StylePre = styled.pre`
  margin: 0px;
  &.obj1 {
    ::before {
      font-weight: 600;
      content: 'change to: ';
    }
  }
`;

export {
  StylePre,
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
};
