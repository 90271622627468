/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {useSearchParams, useNavigate} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {Typography} from '@mui/material';

import {images} from '../../../constants/image';
import {InputPassword, Loader} from '../../../components';
import ResetPasswordService from '../../../services/User/reset_password.service';
import PasswordValidator from '../../../components/PasswordValidator/PasswordValidator';
import validatePasswordPattern from '../../../components/PasswordValidator/validatePasswordPattern';

// styles
import {Wrapper, Alert, StyledImage} from './ResetPassword.style';
import {path} from '../../../routes/path';

function ResetPassword(props) {
  const {isChangePassword} = props;
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get('token');
  const isCreated =
    search.get('created') !== null ? search.get('created') === 'true' : false;

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [enablePage, setEnablePage] = useState(false);

  const [initialValues] = useState({
    newPassword: '',
    confirmPassword: '',
    submit: false,
  });

  const onSubmit = async (values, {setErrors, setStatus, setSubmitting}) => {
    setIsLoading(true);
    verifyToken();
    if (values?.newPassword === values?.confirmPassword) {
      onResetPassword(values, {setErrors, setStatus, setSubmitting});
    } else {
      setIsLoading(false);
      setErrors({submit: "Confirm password doesn't match New password"});
    }
  };

  const onResetPassword = async (
    values,
    {setErrors, setStatus, setSubmitting}
  ) => {
    try {
      await new ResetPasswordService()
        .post('resetPassword', {
          newPassword: values.newPassword,
          token,
        })
        .then(res => {
          setIsLoading(false);
          if (res?.success) {
            navigate(`/${path.AUTH}/${path.CHANGE_PASSWORD_SUCCESS}`, {
              state: {messageSuccess: res?.Message},
            });
          } else {
            setIsLoading(false);
            setErrors({submit: res?.errorMessage});
          }
        });
    } catch (error: any) {
      const message = error.message || 'Something went wrong';
      setIsLoading(false);
      setStatus({success: false});
      setErrors({submit: message});
      setSubmitting(false);
    }
  };

  const verifyToken = async () => {
    await new ResetPasswordService()
      .post('verifyResetPassword', {token: token})
      .then(res => {
        if (res?.data?.isVerified) {
          setDisabled(false);
          setEnablePage(true);
        } else {
          setDisabled(true);
          navigate(`/${path.AUTH}/${path.PASSWORD_RESET_EXPIRED}`);
        }
      });
  };

  const onChangeValidatePasswordPattern = (passwordStr: string): void => {
    setDisabled(!validatePasswordPattern(passwordStr).isPasswordValid);
  };

  useEffect(() => {
    if (token) {
      verifyToken();
    } else {
      navigate(`/${path.AUTH}/${path.PASSWORD_RESET_EXPIRED}`);
    }
  }, [token]);

  return (
    <React.Fragment>
      {!enablePage ? (
        <Loader />
      ) : (
        <>
          <StyledImage>
            <img src={images.logo_sentosa} />
          </StyledImage>
          <Wrapper>
            <Helmet
              title={isCreated ? 'Password Creation' : 'Reset Password'}
            />
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {isChangePassword
                ? 'Change Password'
                : isCreated
                ? 'Password Creation'
                : 'Reset Password'}
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              {isCreated
                ? 'Enter your password to create your account'
                : `Enter your password new to ${
                    isChangePassword ? 'change' : 'reset'
                  } your password
          `}
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string().required(
                  'Enter password is required'
                ),
                confirmPassword: Yup.string().required(
                  'Confirm password is required'
                ),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}

                    <InputPassword
                      name="newPassword"
                      label="Enter Password"
                      value={values.newPassword}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      onBlur={handleBlur}
                      onChange={evt => {
                        const {
                          target: {value},
                        } = evt;

                        onChangeValidatePasswordPattern(value);
                        handleChange(evt);
                      }}
                    />

                    <PasswordValidator passwordStr={values.newPassword} />

                    <InputPassword
                      name="confirmPassword"
                      label="Confirm Password"
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      fullWidth
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <LoadingButton
                      type="submit"
                      fullWidth
                      className="btn-reset_password"
                      variant="contained"
                      color="primary"
                      disabled={
                        isSubmitting ||
                        disabled ||
                        values.confirmPassword === ''
                      }
                      loading={isLoading}
                    >
                      Submit
                    </LoadingButton>
                    {disabled && (
                      <LoadingButton
                        type="submit"
                        fullWidth
                        className="btn-reset_password"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          navigate(`/${path.AUTH}/${path.SIGN_IN}`);
                        }}
                        loading={isLoading}
                      >
                        Back to Login
                      </LoadingButton>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
}

export default ResetPassword;
