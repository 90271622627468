/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {NavLink} from 'react-router-dom';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {path} from '../../routes/path';
import ModalFilter from './components/ModalFilter/ModalFilter';
import {Breadcrumbs, Table, Chip, Tooltips} from '../../components';
import MembersService from '../../services/Members/members.service';
import {ColorType} from '../../types/typeChip';
import {hasPermission} from '../../utils';

// style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './Dashboard.style';

function Dashboard() {
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  // states
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [dataFilter, setDataFilter] = useState({
    tierId_eq: undefined,
    email_eq: undefined,
  });
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [optionTier, setOptionTier] = useState([]);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
  });
  const [tierId_eq, setTierId_eq] = useState('');
  const [email_eq, setEmail_eq] = useState('');

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {dataIndex: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {
      dataIndex: 'point',
      numeric: false,
      disablePadding: false,
      label: 'Point',
    },
    {
      dataIndex: 'membership_tier',
      numeric: false,
      disablePadding: false,
      label: 'Membership Tier',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a: RowType, b: RowType, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: 'desc' | 'asc', orderBy: string) {
    return order === 'desc'
      ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
      : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(
    array: Array<RowType>,
    comparator: (a: RowType, b: RowType) => number
  ) {
    const stabilizedThis = array?.map((el: RowType, index: number) => ({
      el,
      index,
    }));
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a.el, b.el);
      if (order !== 0) return order;
      return a.index - b.index;
    });
    return stabilizedThis?.map(element => element.el);
  }

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleDeleteChip = type => {
    if (type === 'email_eq') {
      delete dataFilter.email_eq;
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'tierId_eq') {
      delete dataFilter.tierId_eq;
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                component={NavLink}
                to={`/${path.ISLAND_MEMBERS}/${value}`}
                size="medium"
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              component={NavLink}
              to={`/${path.ISLAND_MEMBERS}/${value}`}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const handleClean = () => {
    setEmail_eq('');
    setTierId_eq('');
  };

  const handleFilter = () => {
    if (email_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_eq: email_eq,
      }));
      setDataFilter(preState => ({
        ...preState,
        email_eq: email_eq,
      }));
    }
    if (tierId_eq) {
      setDataFilter(preState => ({
        ...preState,
        tierId_eq: tierId_eq,
      }));
      setParams(preState => ({
        ...preState,
        _page: 0,
        tierId_eq: tierId_eq,
      }));
    }
    renderLabelFilter();
    toggleModal();
    handleClean();
  };

  const renderLabelFilter = () => {
    const object = optionTier.find(item => item.id === tierId_eq);
    setDataFilter(preState => ({
      ...preState,
      tierId_eq: object?.label,
    }));
  };

  const getMembers = () => {
    setIsLoading(true);
    new MembersService().getAll(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getMembers();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        optionTier={optionTier}
        setOptionTier={setOptionTier}
        tierId_eq={tierId_eq}
        email_eq={email_eq}
        setTierId_eq={setTierId_eq}
        setEmail_eq={setEmail_eq}
      />
      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Dashboard', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {dataFilter?.email_eq && (
              <Chip
                label={dataFilter?.email_eq}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_eq');
                }}
              />
            )}
            {dataFilter?.tierId_eq && (
              <Chip
                label={dataFilter?.tierId_eq}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('tierId_eq');
                }}
              />
            )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
        rowsPerPage={params._size}
        textNodata="There are no islander member(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {stableSort(dataSource, getComparator(order, orderBy))?.map(
            (row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" id={labelId} scope="row">
                    {row.fullName}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.totalPoints}</TableCell>
                  <TableCell align="left">
                    {row.tierDescription ?? row.tierName}
                  </TableCell>
                  <TableCell align="right" style={{padding: '0 13px 0 0'}}>
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default Dashboard;
