/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Typography, Button} from '@mui/material';
import {images} from '../../../constants/image';
import {Link, useLocation} from 'react-router-dom';
import {path} from '../../../routes/path';

// styles
import {Wrapper, StyledImage, StyledImg} from './ChangePasswordSuccess.style';

function ResetPassword() {
  const location = useLocation();
  const {messageSuccess} = location?.state;

  return (
    <React.Fragment>
      <StyledImage>
        <img src={images.logo_sentosa} />
      </StyledImage>
      <Wrapper sx={{width: '456px'}}>
        <Helmet title="Reset password success" />
        <StyledImg>
          <img src={images.icon_reset_password_success} />
        </StyledImg>

        <Typography
          component="h1"
          align="center"
          gutterBottom
          fontSize="14px"
          fontWeight="bold"
        >
          {messageSuccess}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          component={Link}
          to={`/${path.AUTH}/${path.SIGN_IN}`}
        >
          Done
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
