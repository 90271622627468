/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {images} from '../../../../constants/image';
import {Modal, Button, Input} from '../../../../components';
import IslanderService from '../../../../services/Islander/islander.service';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNewStaff.style';

interface ModalAddNewProps {
  visible: boolean;
  idMerchant?: string | number;
  onClose?: () => void;
  reloadPage?: () => void;
  idStaff?: string | number;
  dataDetail?: any;
}

function ModalAddNewStaff({
  visible,
  onClose,
  reloadPage,
  idMerchant,
  idStaff,
  dataDetail,
}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  // states
  const [isLoading, setIsloading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
  });

  const onAddNew = values => {
    new IslanderService()
      .createByID(`${idMerchant}/employees`, {
        ...values,
        contactNumber:
          values?.contactNumber === '' ? null : values?.contactNumber,
      })
      .then(res => {
        onClose();
        setIsloading(false);
        if (res && res?.success) {
          reloadPage();
          showNotification({
            message: success.CREATE_SUCCESS,
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onUpdate = values => {
    new IslanderService()
      .patch(`${idMerchant}/employees/${idStaff}`, {
        ...values,
      })
      .then(res => {
        onClose();
        setIsloading(false);
        if (res && res?.success) {
          reloadPage();
          showNotification({
            message: success.UPDATE_SUCCESS,
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onSubmit = values => {
    setIsloading(true);
    if (idStaff) {
      onUpdate(values);
    }
    if (!idStaff) {
      onAddNew(values);
    }
  };

  const getStaffDetail = () => {
    setIsloading(true);
    new IslanderService()
      .getOne(`${idMerchant}/employees/${idStaff}`)
      .then(res => {
        setIsloading(false);
        if (res) {
          setInitialValues({
            firstName: dataDetail?.firstName,
            lastName: dataDetail?.lastName,
            email: dataDetail?.email,
            contactNumber: dataDetail?.contactNumber || '',
          });
        }
      });
  };

  useEffect(() => {
    if (visible && idStaff) {
      getStaffDetail();
    }
  }, [visible, idStaff]);

  useEffect(() => {
    if (!visible) {
      setInitialValues({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
      });
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>
          {!idStaff ? 'Add New Staff' : 'Update Staff'}
        </StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255),
              contactNumber: Yup.string()
                .min(0)
                .max(8, 'Please enter a phone number under 8 characters'),
              firstName: Yup.string()
                .required('First name is required')
                .matches(
                  /^[a-zA-Z0-9 ]*$/,
                  'Please enter only alphabets and numbers for this field'
                ),
              lastName: Yup.string()
                .required('Last name is required')
                .matches(
                  /^[a-zA-Z0-9 ]*$/,
                  'Please enter only alphabets and numbers for this field'
                ),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    First Name <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="Enter First Name"
                    size="small"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Last Name <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Enter Last Name"
                    size="small"
                    variant="outlined"
                    value={values.lastName}
                    onChange={handleChange}
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    error={Boolean(touched.lastName && errors.lastName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Email
                  </Typography>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    size="small"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Mobile number
                  </Typography>
                  <Input
                    type="number"
                    name="contactNumber"
                    placeholder="Enter Mobile Number"
                    size="small"
                    variant="outlined"
                    value={values.contactNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.contactNumber && errors.contactNumber}
                    error={Boolean(
                      touched.contactNumber && errors.contactNumber
                    )}
                  />
                </FormControl>

                <Divider variant="middle" />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button
                      variant="text"
                      type="button"
                      onClick={onClose}
                      loading={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      {!idStaff ? 'Add New' : 'Update'}
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNewStaff;
