/* eslint-disable @typescript-eslint/no-explicit-any */

import ApiService from './api.service';
import TokenService from './User/token.service';
import {
  setStorageList,
  removeStorageList,
  getStorage,
  removeStorage,
} from '../utils/index';
import {path} from '../routes/path';

const MAX_REFRESH_TOKEN_ATTEMPT = 5;

export default class BaseApiService extends ApiService {
  constructor(props: any = null) {
    super(props);
  }

  getHeaders = (): object => ({
    ContentType: 'application/json; charset=UTF-8',
    Authorization: `Bearer ${getStorage('accessToken')}`,
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
  });

  readonly handleStatusCode = async (statusCode: any, response: any = null) => {
    if (statusCode === this.STATUS_CODE_SUCCESS) {
      removeStorage('refreshTokenAttempt');
      return response;
    } else if (statusCode === this.STATUS_CODE_FOBIDDEN) {
      window.location.href = path.DEFAULT;
    } else if (statusCode === this.STATUS_CODE_UNAUTHORIZED) {
      const accessToken = getStorage('accessToken');
      const refreshToken = getStorage('refreshToken');
      const attempt = parseInt(getStorage('refreshTokenAttempt') || 0);
      if (attempt >= MAX_REFRESH_TOKEN_ATTEMPT) {
        await removeStorageList([
          'accessToken',
          'refreshToken',
          'refreshTokenAttempt',
        ]);
        window.location.pathname = `/${path.AUTH}/${path.SIGN_IN}`;
      } else {
        await new TokenService()
          .getRefeshToken({
            accessToken: accessToken,
            refreshToken: refreshToken,
          })
          .then(async res => {
            if (res?.code === this.STATUS_CODE_INTERNAL_SERVER_ERROR) {
              removeStorageList(['accessToken', 'refreshToken']);
              window.location.pathname = `/${path.AUTH}/${path.SIGN_IN}`;
            } else if (res?.data) {
              const {token, refreshToken} = res?.data;
              await setStorageList([
                {
                  key: 'accessToken',
                  value: token,
                },
                {
                  key: 'refreshToken',
                  value: refreshToken,
                },
                {
                  key: 'refreshTokenAttempt',
                  value: attempt + 1,
                },
              ]);
              window.location.reload();
            }
          })
          .catch(() => {
            removeStorageList([
              'accessToken',
              'refreshToken',
              'refreshTokenAttempt',
            ]);
            window.location.pathname = `/${path.AUTH}/${path.SIGN_IN}`;
          });
      }
    } else {
      removeStorageList(['accessToken', 'refreshToken', 'refreshTokenAttempt']);
      window.location.pathname = `/${path.AUTH}/${path.SIGN_IN}`;
    }
  };

  getAll = async (params: object | null) => {
    return await this.get(null, params)
      .then(async res =>
        this.handleStatusCode(this.STATUS_CODE_SUCCESS, res?.data)
      )
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  getAllByID = async (url: string, params: object | null) => {
    return await this.get(url, params)
      .then(async res =>
        this.handleStatusCode(this.STATUS_CODE_SUCCESS, res?.data)
      )
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  getOne = async (id: number | string) => {
    return await this.get(`${id}`)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  update = async (id: number | string, params: object) => {
    return await this.patch(`${id}`, params)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  create = async (params: object) => {
    return await this.post(null, params)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  createByID = async (url: string, params: object) => {
    return await this.post(url, params)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  softDelete = async (id: number | string) => {
    return await this.delete(`${id}`)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  cashout = async (merchantId: string, params: object) => {
    console.log(params);
    return await this.post(merchantId, params)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };

  cashoutPeriod = async (id: string | undefined) => {
    return await this.get(`${id}/cashoutPeriod`)
      .then(async res => this.handleStatusCode(this.STATUS_CODE_SUCCESS, res))
      .catch((error: any | unknown) => {
        const {
          response: {status} = {status: this.STATUS_CODE_INTERNAL_SERVER_ERROR},
        } = error;
        return this.handleStatusCode(status);
      });
  };
}
