export type ColumnsType = {
  dataIndex: string | null;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
};

export type RowType = {
  [key: string]: string | number | boolean;
  id: string;
  name: string;
  description: string;
  pointsReward: number;
  quantity: number;
  noOfReward: number;
  expireEndDateTime: string;
  expireStartDateTime: string;
  status: number;
};

export type RowCodeType = {
  [key: string]: string | number | boolean;
  id: string;
  code: string;
  status: number;
  used: boolean;
};

export type DataDetail = {
  id?: string;
  name?: string;
  description?: string;
  point?: number;
  quantity?: number;
  usedQuantity: number;
  expireStartDateTime?: string;
  expireEndDateTime?: string;
  codeType?: number;
  code?: string;
  numberId?: number;
  referralCampaignMerchantId?: string;
  updatedDateTime?: string;
  createdDateTime?: string;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean
) {
  return {name, path, isCurrentPage};
}
