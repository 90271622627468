import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
// import {Button as MuiButton} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
const StyledButton = styled(LoadingButton)`
  ${spacing};
  height: 36px;
`;

export {StyledButton};
