import styled, {css} from 'styled-components/macro';
import {rgba} from 'polished';

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from '@mui/material';
import {spacing} from '@mui/system';

const Card = styled(MuiCard)<{illustration?: string}>`
  position: relative;
  margin-bottom: ${props => props.theme.spacing(6)};

  ${props =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${props => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${props => props.theme.palette.primary.main};
    `}
`;

const Typography = styled(MuiTypography)`
  ${spacing};
  :hover {
    cursor: default;
  }
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${props => props.theme.spacing(4)};
  }
`;

const StyledImage = styled.div`
  background: #1565c0;
  border-radius: 50%;
  padding: 24px;
  width: 66px;
  height: 66px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 34px;
    height: 34px;
  }
`;

const StyledIcon = {
  width: '18px',
  height: '18px',
};

export {Card, Typography, CardContent, StyledImage, StyledIcon};
