/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import {Helmet} from 'react-helmet-async';
import {Box, CardContent, FormControl, Grid, Checkbox} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {USER_TYPE, USER_ROLE} from '../../../constants/UserType';
import {path} from '../../../routes/path';
import {typeRoutes, DataDetail} from '../../../types/typeSDCAdministrators';
import IslanderService from '../../../services/Islander/islander.service';
import AdministratorsService from '../../../services/Administrators/administrators.service';
import ResetPasswordService from '../../../services/User/reset_password.service';
import {Input, Breadcrumbs, Select, FooterDetail} from '../../../components';
import success from '../../../constants/success';
import {AllModal} from './SDCAdministratorsDetail.method';

// styles
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './SDCAdministratorsDetail.style';

function SDCAdministratorsDetail() {
  const paramsUrl = useParams();
  const idDetail = paramsUrl?.id;
  const {showNotification} = useAuth();

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [optionIslandPartners, setOptionIslandPartners] = useState<any>([]);
  const [isDefaultFreeze, setIsDefaultFreeze] = useState(false);
  const [params] = useState({
    _page: 0,
    _size: 99999, // temp, check with be team if there is a value for all items
    status_eq: 1,
    _sort: 'name:asc',
  });
  const [initialValues, setInitialValues] = useState<DataDetail>({
    firstName: '',
    lastName: '',
    designation: '',
    email: '',
    type: USER_TYPE.ADMINISTRATOR,
    merchantId: '',
    isFreeze: false,
    phoneNumber: '',
  });
  const [modal, setModal] = useState({
    isModalConfirm: false,
    isModalConfirmDelete: false,
    isModalSuccess: false,
    isModalSuccessDelete: false,
    isModalEditTier: false,
    isModalEditPoints: false,
  });

  const toggleModalConfirm = async () => {
    setModal(preState => ({
      ...preState,
      isModalConfirm: !modal.isModalConfirm,
    }));
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalSuccess = async () => {
    setIsLoading(true);
    await new ResetPasswordService()
      .post('requestResetPassword', {email: initialValues?.email})
      .then(res => {
        setIsLoading(false);
        if (res?.success) {
          toggleModalConfirm();
          setModal(preState => ({
            ...preState,
            isModalSuccess: !modal.isModalSuccess,
          }));
        } else {
          showNotification({
            message: 'Reset password failed! Please try again later',
            variation: 'error',
          });
        }
      });
  };

  const toggleModalSuccessDelete = () => {
    setIsLoading(true);
    new AdministratorsService().softDelete(idDetail).then(res => {
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirmDelete();
        setModal(preState => ({
          ...preState,
          isModalSuccessDelete: !modal.isModalSuccessDelete,
        }));
      }
    });
  };

  const onRequestUpdate = newParams => {
    new AdministratorsService().update(idDetail, newParams).then(res => {
      if (res.success) {
        getDataDetail();
        setIsLoading(false);
        showNotification({
          message: success.UPDATE_SUCCESS,
        });
      } else {
        setIsLoading(false);
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onEdit = values => {
    setIsLoading(true);
    const newParams = {
      ...values,
      merchantId:
        values?.type.toString() === USER_TYPE.MERCHANT.toString()
          ? values.merchantId
          : '',
      phoneNumber: values.phoneNumber.replace('+', ''),
    };
    if (!values?.isFreeze && isDefaultFreeze) {
      return onRequestUpdate({
        ...newParams,
        lastUnfreeze: new Date().toISOString(),
      });
    } else {
      return onRequestUpdate(newParams);
    }
  };

  const getIslanderPartners = () => {
    setIsLoading(true);
    new IslanderService().getAll(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const items = res?.data.items.map(item => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setOptionIslandPartners(items);
        setIsLoading(false);
      }
    });
  };

  const getDataDetail = () => {
    setIsLoading(true);
    new AdministratorsService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        const data = res?.data?.data;
        setIsLoading(false);
        setIsDefaultFreeze(res?.data?.data?.isFreeze);
        setInitialValues({
          ...initialValues,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          type: data.type,
          merchantId: data?.merchantId,
          designation: data?.designation,
          isFreeze: data?.isFreeze,
          phoneNumber: data?.phoneNumber ?? '',
        });
      }
    });
  };

  useEffect(() => {
    getDataDetail();
    getIslanderPartners();
  }, []);

  return (
    <>
      <Helmet title="Edit Administrator Detail" />

      <AllModal
        isModalConfirm={modal.isModalConfirm}
        toggleModalConfirm={toggleModalConfirm}
        isModalConfirmDelete={modal.isModalConfirmDelete}
        isModalSuccessDelete={modal.isModalSuccessDelete}
        isModalSuccess={modal.isModalSuccess}
        toggleModalConfirmDelete={toggleModalConfirmDelete}
        toggleModalSuccessDelete={toggleModalSuccessDelete}
        toggleModalSuccess={toggleModalSuccess}
        setModal={setModal}
        isLoading={isLoading}
      />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Edit Administrator Detail
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes('SDC Administrators', `/${path.SDC_ADMINISTRATORS}`),
              typeRoutes('Detail', null, true),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            phoneNumber: Yup.string()
              .required('Phone number is required')
              .matches(
                /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}/,
                'Phone number is invalid'
              ),
          })}
          onSubmit={onEdit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            // isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container mb="30px">
                <Grid item xs={12} lg={12}>
                  <Shadow>
                    <Card px={6} pt={6}>
                      <CardContent>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={10}
                          mb="50px"
                        >
                          <Grid item xs={12} mb="30px">
                            <Typography variant="h5">
                              Administrator information
                            </Typography>
                          </Grid>

                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Input
                                type="text"
                                name="firstName"
                                label="First Name"
                                value={values.firstName}
                                helperText={
                                  touched.firstName && errors.firstName
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.firstName && errors.firstName
                                )}
                                className="mandatory-field"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Input
                                type="text"
                                name="lastName"
                                label="Last Name"
                                value={values.lastName}
                                helperText={touched.lastName && errors.lastName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.lastName && errors.lastName
                                )}
                                className="mandatory-field"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Input
                                type="email"
                                name="email"
                                label="Email"
                                value={values.email}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.email && errors.email)}
                                className="mandatory-field"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth disabled>
                              <Select
                                labelId="type"
                                name="type"
                                label="Type"
                                onChangeSelect={handleChange}
                                value={values.type.toString()}
                                selectOptions={Object.keys(USER_ROLE).reduce(
                                  (roles, key) => {
                                    roles.push({
                                      label: USER_ROLE[key],
                                      value: key,
                                    });
                                    return roles;
                                  },
                                  []
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} display="flex">
                            <FormControl variant="standard" fullWidth>
                              <Input
                                type="text"
                                name="designation"
                                label="Designation"
                                value={values.designation}
                                helperText={
                                  touched.designation && errors.designation
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.designation && errors.designation
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl fullWidth>
                              <Input
                                type="phoneNumber"
                                name="phoneNumber"
                                label="Phone number"
                                placeholder="Enter phone number"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber
                                }
                                onBlur={handleBlur}
                                error={Boolean(
                                  touched.phoneNumber && errors.phoneNumber
                                )}
                                className="mandatory-field"
                              />
                            </FormControl>
                          </Grid>
                          {values.type.toString() ===
                            USER_TYPE.MERCHANT.toString() && (
                            <Grid item md={6}>
                              <FormControl
                                variant="standard"
                                fullWidth
                                disabled
                              >
                                <Select
                                  labelId="merchantId"
                                  name="merchantId"
                                  label="Island Partner"
                                  onChangeSelect={handleChange}
                                  value={values.merchantId}
                                  selectOptions={optionIslandPartners}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item md={6}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '15px',
                              }}
                            >
                              <Typography variant="inherit">
                                Unfreeze Account
                              </Typography>
                              <Checkbox
                                name="isFreeze"
                                checked={values.isFreeze}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                disabled={!values.isFreeze}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Shadow>
                </Grid>
              </Grid>
              <FooterDetail
                isResetPassword
                isEdit={idDetail ? true : false}
                toggleModalConfirm={toggleModalConfirm}
                toggleModalConfirmDelete={toggleModalConfirmDelete}
                titleDelete="Delete Administrator"
                loading={isLoading}
              />
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default SDCAdministratorsDetail;
