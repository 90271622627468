import * as React from 'react';
import {Navigate} from 'react-router-dom';

import HandleRoutes from '../../routes/HandleRouters';
import useAuth from '../../hooks/useAuth';
import {path} from '../../routes/path';

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({children}: AuthGuardType) {
  const {isAuthenticated, isInitialized} = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to={`/${path.AUTH}/${path.SIGN_IN}`} />;
  }

  return (
    <React.Fragment>
      <HandleRoutes content={children} />
    </React.Fragment>
  );
}

export default AuthGuard;
