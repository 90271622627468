/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import {
  Checkbox,
  TableHead,
  TableContainer,
  TableCell,
  TablePagination,
  TableRow,
  // TableSortLabel,
  CircularProgress,
} from '@mui/material';
import {images} from '../../constants/image';
import Tooltips from '../Tooltips/Tooltips';

// styles
import {
  StyledNoData,
  Paper,
  StyledTable,
  StyledMuiTableRowHover,
  TableHeader,
  StyledPaperLoading,
  Typography,
} from './Table.style';

type ColumnsType = {
  dataIndex: string;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  disabled?: boolean;
  subText?: string;
  helperText?: string;
};

type TableHeadProps = {
  columns: Array<ColumnsType>;
  numSelected?: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  isMultiCheckbox: boolean;
  onSelectAllClick?: (e: any) => void;
  // onRequestSort: (e: any, property: string) => void;
};
interface PropsTable {
  dataSource: Array<any>;
  columns: Array<ColumnsType>;
  children: ReactNode;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: any, newPage: number) => void;
  onChangeRowsPerPage?: (event: any) => void;
  onSelectAllClick?: (event: any) => void;
  // onRequestSort: (event: any, property: string) => void;
  selected?: Array<string>;
  order: 'desc' | 'asc';
  orderBy: string;
  isMultiCheckbox?: boolean;
  dense: boolean;
  count?: number;
  isLoading?: boolean;
  textNodata?: string;
  isPagination?: boolean;
}

const CustomTableHead: React.FC<TableHeadProps> = props => {
  const {
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    // onRequestSort,
    isMultiCheckbox = true,
  } = props;

  // const createSortHandler = (property: string) => (event: any) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {isMultiCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
        )}
        {columns.map(headCell =>
          !headCell.disabled ? (
            <TableCell
              key={headCell.dataIndex}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.dataIndex ? order : false}
            >
              {/* <TableSortLabel
              active={orderBy === headCell.dataIndex}
              direction={orderBy === headCell.dataIndex ? order : 'asc'}
              onClick={createSortHandler(headCell.dataIndex)}
            >
              <TableHeader>{headCell.label}</TableHeader>
            </TableSortLabel> */}
              {headCell.subText ? (
                <Tooltips title={headCell.subText} placement="bottom-start">
                  <TableHeader>
                    {headCell.label}
                    {headCell?.helperText ? (
                      <Typography component="em" display="block">
                        {headCell?.helperText}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </TableHeader>
                </Tooltips>
              ) : (
                <TableHeader>
                  {headCell.label}
                  {headCell?.helperText ? (
                    <Typography component="em" display="block">
                      {headCell?.helperText}
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableHeader>
              )}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

function Table({
  dataSource,
  columns,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  onSelectAllClick,
  // onRequestSort,
  selected,
  order,
  orderBy,
  isLoading,
  dense,
  isMultiCheckbox = true,
  children,
  isPagination = true,
  count,
  textNodata = 'No data',
  ...other
}: PropsTable) {
  return (
    <React.Fragment>
      {isLoading ? (
        <Paper sx={StyledPaperLoading}>
          <CircularProgress />
        </Paper>
      ) : (
        <Paper>
          <TableContainer>
            <StyledTable
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
              sx={StyledMuiTableRowHover}
              {...other}
            >
              <CustomTableHead
                columns={columns}
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                isMultiCheckbox={isMultiCheckbox}
                onSelectAllClick={onSelectAllClick}
                rowCount={dataSource?.length}
              />
              {children}
            </StyledTable>
          </TableContainer>

          {dataSource.length === 0 && (
            <StyledNoData>
              <img src={images.icon_no_data} alt="icon_no_data" />
              <Typography>{textNodata}</Typography>
            </StyledNoData>
          )}

          {isPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          )}
        </Paper>
      )}
    </React.Fragment>
  );
}

export default Table;
