import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Stack,
} from '@mui/material';

const StyledAction = styled.div``;

const StyledIcon = {
  width: '18px',
  height: '18px',
};

const StyledStack = styled(Stack)`
  margin-right: 10px;
`;

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export {
  StyledAction,
  Typography,
  Divider,
  Breadcrumbs,
  StyledIcon,
  StyledStack,
};
