import React from 'react';
import InputFile from '../../components/InputFile/InputFile';
import {Helmet} from 'react-helmet-async';
import {Grid} from '@mui/material';
import {typeRoutes} from '../../types/typeAllTransaction';
import {Breadcrumbs} from '../../components';

// styles
import {Divider, Typography} from './UploadNew.style';

const UploadNew = props => {
  const acceptProps = {
    'image/jpeg': ['.jpeg', '.png', '.webp', '.gif', '.svg'],
    'text/json': ['.json'],
    'video/mp4': ['.mp4', '.MP4', '.mov'],
  };
  return (
    <>
      <Helmet title="Upload New" />
      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Upload new files
          </Typography>
          <Breadcrumbs
            routes={[
              typeRoutes(
                'Supports GIF, JPG, JSON, MOV, MP4, PNG, SVG, WEBP',
                null,
                true
              ),
            ]}
          />
        </Grid>
      </Grid>
      <Divider my={6} />
      <InputFile acceptProps={acceptProps} />
    </>
  );
};

export default UploadNew;
