/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode, useEffect, useState} from 'react';
import {IconButton, Tooltip, Typography, Stack} from '@mui/material';
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import Button from '../Button/Button';

// style
import {
  Spacer,
  StyledHeader,
  StyledToolbar,
  StyleItemFilter,
} from './EnhancedTableToolbar.style';

export type ListFilterProps = {
  label?: string;
  key?: string | number;
};

export type ParamsProps = {
  _page?: number;
  _size?: number;
  status_eq?: number;
};

type EnhancedTableToolbarProps = {
  numSelected?: number;
  isHeader?: boolean;
  isFilter?: boolean;
  childrenHeader?: ReactNode;
  isAddNew?: boolean;
  onOpenFilter?: () => void;
  onClickAddNew?: () => void;
  setTypeFilter?: (any) => void;
  titleHeader?: string;
  isLoading?: boolean;
  setParams?: (ParamsProps) => void;
  canCreate?: boolean;
  isDisabledAddNew?: boolean;
  comingLabel?: string;
  enableDeletedTab?: boolean;
  listSortTabs?: any[];
};

function EnhancedTableToolbar({
  numSelected,
  onOpenFilter,
  isHeader,
  titleHeader = '',
  isFilter = false,
  isAddNew = false,
  childrenHeader,
  onClickAddNew,
  isLoading,
  setParams,
  canCreate,
  isDisabledAddNew = false,
  comingLabel = 'Coming',
  enableDeletedTab = false,
  listSortTabs = [],
}: EnhancedTableToolbarProps) {
  const getCurrentDate = new Date().toISOString();

  const [listFilter, setListFilter] = useState<ListFilterProps[]>([
    {
      label: 'All',
      key: 'All',
    },
    {
      label: 'Available',
      key: 'Available',
    },
    {
      label: comingLabel ?? 'Coming',
      key: comingLabel ?? 'Coming',
    },
    {
      label: 'Expired',
      key: 'Expired',
    },
  ]);

  const [activeFilter, setActiveFilter] = useState<ListFilterProps>({
    label: 'All',
    key: 'All',
  });

  const onClickFilter = value => {
    if (value) {
      const findData = listFilter.find(item => item.key === value);
      setActiveFilter(findData);
      switch (findData.key) {
        case 'Available':
          return setParams(preState => ({
            ...preState,
            status_eq: 1,
            startDateTime_lte: getCurrentDate,
            endDateTime_gte: getCurrentDate,
            startDateTime_gt: undefined,
            endDateTime_lt: undefined,
            _sort:
              listSortTabs.find(item => item?.key === findData.key)?.value ||
              preState?._sort,
            // Or
            expireStartDateTime_lte: getCurrentDate,
            expireEndDateTime_gte: getCurrentDate,
            expireStartDateTime_gt: undefined,
            expireEndDateTime_lt: undefined,
          }));
        case 'Coming':
        case 'Upcoming':
          return setParams(preState => ({
            ...preState,
            status_eq: 1,
            startDateTime_lte: undefined,
            endDateTime_gte: undefined,
            startDateTime_gt: getCurrentDate,
            endDateTime_lt: undefined,
            _sort:
              listSortTabs.find(item => item?.key === findData.key)?.value ||
              preState?._sort,
            // Or
            expireStartDateTime_gt: getCurrentDate,
            expireStartDateTime_lte: undefined,
            expireEndDateTime_gte: undefined,
            expireEndDateTime_lt: undefined,
          }));
        case 'Expired':
          return setParams(preState => ({
            ...preState,
            status_eq: 1,
            startDateTime_lte: undefined,
            endDateTime_gte: undefined,
            startDateTime_gt: undefined,
            endDateTime_lt: getCurrentDate,
            _sort:
              listSortTabs.find(item => item?.key === findData.key)?.value ||
              preState?._sort,
            // Or
            expireEndDateTime_lt: getCurrentDate,
            expireStartDateTime_lte: undefined,
            expireEndDateTime_gte: undefined,
            expireStartDateTime_gt: undefined,
          }));
        case 'Deleted':
          return setParams(preState => ({
            ...preState,
            status_eq: 2,
            startDateTime_lte: undefined,
            endDateTime_gte: undefined,
            startDateTime_gt: undefined,
            endDateTime_lt: undefined,
            _sort:
              listSortTabs.find(item => item?.key === findData.key)?.value ||
              preState?._sort,
            // Or
            expireStartDateTime_lte: undefined,
            expireEndDateTime_gte: undefined,
            expireStartDateTime_gt: undefined,
            expireEndDateTime_lt: undefined,
          }));
        default:
          setParams(preState => ({
            ...preState,
            status_eq: enableDeletedTab ? undefined : 1,
            startDateTime_lte: undefined,
            endDateTime_gte: undefined,
            startDateTime_gt: undefined,
            endDateTime_lt: undefined,
            _sort:
              listSortTabs.find(item => item?.key === findData.key)?.value ||
              preState?._sort,
            // Or
            expireStartDateTime_lte: undefined,
            expireEndDateTime_gte: undefined,
            expireStartDateTime_gt: undefined,
            expireEndDateTime_lt: undefined,
          }));
      }
    }
    return null;
  };

  useEffect(() => {
    if (
      enableDeletedTab &&
      listFilter.filter(e => e.key === 'Deleted').length === 0
    ) {
      const list = [...listFilter];
      list.push({
        label: 'Deleted',
        key: 'Deleted',
      });
      setListFilter(list);
    }
  }, []);

  return (
    <StyledHeader>
      {isHeader && (
        <StyledToolbar>
          {isAddNew && !titleHeader && (
            <Stack direction="row" spacing={3} mt={4} mb={4}>
              {listFilter.map((item, key) => (
                <StyleItemFilter
                  key={key}
                  className={`${
                    activeFilter.key === item.key ? 'active' : 'inactive'
                  }`}
                  onClick={() => {
                    onClickFilter(item.key);
                  }}
                >
                  {item.label}
                </StyleItemFilter>
              ))}
            </Stack>
          )}
          <div
            style={{
              width: '100%',
            }}
          >
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="h6" id="tableTitle">
                {titleHeader ? titleHeader : childrenHeader}
              </Typography>
            )}
          </div>
          <Spacer />
          {isFilter && (
            <div>
              {numSelected > 0 ? (
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" size="large">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filter list">
                  <IconButton
                    aria-label="Filter list"
                    size="large"
                    onClick={onOpenFilter}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
          {isAddNew && (
            <div
              style={{
                width: '100%',
                textAlign: 'right',
              }}
            >
              {!!canCreate && (
                <Button
                  onClick={onClickAddNew}
                  type="button"
                  loading={isLoading}
                  disabled={isDisabledAddNew}
                >
                  Add new
                </Button>
              )}
            </div>
          )}
        </StyledToolbar>
      )}
    </StyledHeader>
  );
}
export default EnhancedTableToolbar;
