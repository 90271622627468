/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {Modal, Button, Input} from '../../../../components';
import {Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {AspectSelectImage, DateTimePicker} from '../../../../components';
// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';
import EventService from '../../../../services/Event/event.service';
import {formatDateTime} from '../../../../utils';

interface ModalAddNewProps {
  idEvent?: any;
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

interface DateTime {
  startDateTime?: string | null;
  endDateTime?: string | null;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({
  idEvent,
  visible,
  onClose,
  reloadPage,
}: ModalAddNewProps) {
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const {showNotification} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [eventImages, setEventImages] = useState([]);
  const [isEmptyDate, setEmptyDate] = useState(false);
  const [isEmptyImage, setEmptyImage] = useState(false);
  const [valueRangePicker, setValueRangePicker] = useState<DateTime>({
    startDateTime: null,
    endDateTime: null,
  });

  // states
  const [initialValues, setInitialValues] = useState({
    title: '',
    shortDescription: '',
    uRL: '',
    qrExpiredDate: qrExpiredDate.toISOString(),
    refreshedDate: refreshedDate.toISOString(),
    eventStartDateTime: '',
    eventEndDateTime: '',
  });
  // const [valueFile, setValueFile] = useState();

  // const onChangeUploadFile = (event: any) => {
  //   setValueFile(event.target.files[0]);
  // };

  const getDataDetail = () => {
    if (idEvent) {
      setIsLoading(true);
      new EventService().getOne(idEvent).then(res => {
        if (res && res.data && res.data.data) {
          const data = res?.data?.data;
          setIsLoading(false);
          setInitialValues({
            ...initialValues,
            title: data?.title,
            shortDescription: data?.shortDescription,
            uRL: data?.uRL,
            qrExpiredDate: data?.qrExpiredDate,
            refreshedDate: data?.refreshedDate,
            eventStartDateTime: data?.eventStartDateTime,
            eventEndDateTime: data?.eventEndDateTime,
          });
          setValueRangePicker({
            startDateTime: data?.eventStartDateTime,
            endDateTime: data?.eventEndDateTime,
          });
          if (data?.image !== '') {
            setEventImages([data?.image]);
          }
        }
      });
    }
  };

  const validateForm = () => {
    let errors = 0;
    if (
      valueRangePicker.startDateTime !== null &&
      valueRangePicker.endDateTime !== null
    ) {
      setEmptyDate(false);
    } else {
      setEmptyDate(true);
      errors = errors + 1;
    }

    if (eventImages.length > 0) {
      setEmptyImage(false);
    } else {
      setEmptyImage(true);
      errors = errors + 1;
    }

    return errors === 0;
  };

  const onUpdate = (values, eventId) => {
    if (!validateForm()) {
      return false;
    }
    setIsLoading(true);
    new EventService()
      .update(eventId, {
        ...values,
      })
      .then(res => {
        if (res && res?.success) {
          if (eventImages.length > 0 && typeof eventImages[0] !== 'string') {
            new EventService()
              .uploadImage(
                res.data.id,
                new File(eventImages, 'eventImages.jpeg')
              )
              .then(resImg => {
                if (resImg) {
                  getDataDetail();
                  reloadPage();
                  onClose();
                  setIsLoading(false);
                  showNotification({
                    message: success.UPDATE_SUCCESS,
                  });
                }
              });
          } else {
            getDataDetail();
            setIsLoading(false);
            reloadPage();
            onClose();
            showNotification({
              message: success.UPDATE_SUCCESS,
            });
          }
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onAdd = values => {
    if (!validateForm()) {
      return false;
    }
    setIsLoading(true);
    new EventService().create({...values}).then(res => {
      if (res && res?.success) {
        if (eventImages.length > 0) {
          new EventService()
            .uploadImage(res.data.id, new File(eventImages, 'eventImages.jpeg'))
            .then(resImg => {
              if (resImg) {
                setIsLoading(false);
                onClose();
                reloadPage();
                showNotification({
                  message: success.CREATE_SUCCESS,
                });
              }
            });
        } else {
          setIsLoading(false);
          onClose();
          reloadPage();
          showNotification({
            message: success.CREATE_SUCCESS,
          });
        }
      } else {
        setIsLoading(false);
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const onSubmit = values => {
    if (idEvent) {
      // call api update event
      onUpdate(values, idEvent);
    } else {
      // call api create event
      onAdd(values);
    }
    return;
  };

  const onUploadImages = images => {
    setEventImages(images);
  };

  const handleChangeValues = (value: any, key: string) => {
    setInitialValues({...initialValues, [key]: value});
  };

  const resetForm = () => {
    setInitialValues({
      title: '',
      shortDescription: '',
      uRL: '',
      qrExpiredDate: qrExpiredDate.toISOString(),
      refreshedDate: refreshedDate.toISOString(),
      eventStartDateTime: '',
      eventEndDateTime: '',
    });
    setEventImages([]);
    setValueRangePicker({startDateTime: null, endDateTime: null});
    setEmptyDate(false);
    setEmptyImage(false);
  };

  useEffect(() => {
    if (idEvent) {
      getDataDetail();
      setEmptyDate(false);
      setEmptyImage(false);
    } else {
      resetForm();
    }
  }, [idEvent]);

  return (
    <Modal
      visible={visible}
      onClose={() => {
        onClose();
        resetForm();
      }}
    >
      <>
        <StyledModalTitle>
          {idEvent ? 'Update Event' : 'Add New Event'}
        </StyledModalTitle>
        <StyledIconClose
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .min(1, 'Min 1 char length')
                .max(50, 'Max 50 char length')
                .required('Title is required'),
              shortDescription: Yup.string()
                .min(1, 'Min 1 char length')
                .max(150, 'Max 150 char length')
                .required('Short description is required'),
              uRL: Yup.string()
                .matches(/((https):\/\/)+[a-zA-Z0-9-_]/, 'Url is invalid url')
                .required('Url is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Event Title <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Enter Event Title"
                    size="small"
                    variant="outlined"
                    value={values.title}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'title');
                      handleChange(e);
                    }}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Event Short Description{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="shortDescription"
                    placeholder="Enter Event Short Description"
                    size="small"
                    variant="outlined"
                    value={values.shortDescription}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'shortDescription');
                      handleChange(e);
                    }}
                    helperText={
                      touched.shortDescription && errors.shortDescription
                    }
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.shortDescription && errors.shortDescription
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Event URL <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="uRL"
                    placeholder="Enter Url"
                    size="small"
                    variant="outlined"
                    value={values.uRL}
                    onChange={e => {
                      handleChangeValues(e.target.value, 'uRL');
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    helperText={touched.uRL && errors.uRL}
                    error={Boolean(touched.uRL && errors.uRL)}
                  />
                </FormControl>
                {/* <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    Event Date Range{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <RangePicker
                    required
                    isEmptyValue={isEmptyDate}
                    value={valueRangePicker}
                    onChange={onChangeRangePicker}
                  />
                </FormControl> */}
                <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    Start Date Time <span className="mandatory-label">(*)</span>
                  </Typography>
                  <DateTimePicker
                    required
                    isEmptyValue={isEmptyDate}
                    value={valueRangePicker.startDateTime}
                    helperText="Start Date Time is required"
                    onChange={e => {
                      if (e >= valueRangePicker.endDateTime) {
                        setValueRangePicker({
                          ...valueRangePicker,
                          startDateTime: formatDateTime(e, formatDateTimes),
                          endDateTime: null,
                        });
                        setInitialValues({
                          ...initialValues,
                          eventStartDateTime: formatDateTime(
                            e,
                            formatDateTimes
                          ),
                          eventEndDateTime: null,
                        });
                      } else {
                        setValueRangePicker({
                          ...valueRangePicker,
                          startDateTime: formatDateTime(e, formatDateTimes),
                        });
                        setInitialValues({
                          ...initialValues,
                          eventStartDateTime: formatDateTime(
                            e,
                            formatDateTimes
                          ),
                        });
                      }
                    }}
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <Typography variant="body2" mb={2}>
                    End Date Time <span className="mandatory-label">(*)</span>
                  </Typography>
                  <DateTimePicker
                    required
                    isEmptyValue={isEmptyDate}
                    disabled={valueRangePicker.startDateTime === null}
                    value={valueRangePicker.endDateTime}
                    helperText="End Date Time is required"
                    minDate={initialValues?.eventStartDateTime || new Date()}
                    onChange={e => {
                      setValueRangePicker({
                        ...valueRangePicker,
                        endDateTime: e,
                      });
                      setInitialValues({
                        ...initialValues,
                        eventEndDateTime: formatDateTime(e, formatDateTimes),
                      });
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2} mt={3}>
                    Event Image <span className="mandatory-label">(*)</span>
                  </Typography>
                  <AspectSelectImage
                    required
                    showError={isEmptyImage}
                    defaultImages={eventImages}
                    setDefaultImages={onUploadImages}
                  />
                </FormControl>
                <Divider variant="middle" pt={8} />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      {idEvent ? 'Update' : 'Add Event'}
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
