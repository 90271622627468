/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import success from '../../constants/success';
import {path} from '../../routes/path';
import ModalFilter from './components/ModalFilter/ModalFilter';
import {Breadcrumbs, Table, Chip, Tooltips, Button} from '../../components';
import MembersService from '../../services/Members/members.service';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import {ColorType} from '../../types/typeChip';
import {formatLocalDateFromUTCTime, hasPermission} from '../../utils';
import useAuth from '../../hooks/useAuth';
import queryString from 'query-string';

// style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './IslanderMembers.style';

function IslanderMembers() {
  const navigate = useNavigate();
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};

  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const format = 'DD/MM/YYYY';

  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);

  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [optionTier, setOptionTier] = useState([]);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'createdDateTime:asc',
    status_eq: searchParamsObject.status_eq ?? 1,
    ...{
      tierId_eq: searchParamsObject.tierId_eq ?? undefined,
      email_contains: searchParamsObject.email_contains ?? undefined,
      membershipId_contains:
        searchParamsObject.membershipId_contains ?? undefined,
      mobileNumber_contains:
        searchParamsObject.mobileNumber_contains ?? undefined,
      supportTierIds_isnull:
        searchParamsObject.supportTierIds_isnull !== undefined
          ? searchParamsObject.supportTierIds_isnull === '1'
          : undefined,
      tagId_contains: searchParamsObject.tagId_contains ?? undefined,
      interestId_contains: searchParamsObject.interestId_contains ?? undefined,
      numberOfReferrals_eq:
        searchParamsObject.numberOfReferrals_eq ?? undefined,
      referralCode_contains:
        searchParamsObject.referralCode_contains ?? undefined,
      totalPoints_lte: searchParamsObject.totalPoints_lte ?? undefined,
      totalPoints_gte: searchParamsObject.totalPoints_gte ?? undefined,
      totalTierPoints_lte: searchParamsObject.totalTierPoints_lte ?? undefined,
      totalTierPoints_gte: searchParamsObject.totalTierPoints_gte ?? undefined,
    },
  });
  const [statusSelected, setStatusSelected] = useState('');
  const [status_eq, setStatus_eq] = useState<string | number>(
    searchParams.get('status_eq') ?? ''
  );
  const [referralCode_contains, setReferralCode_contains] = useState(
    searchParams.get('referralCode_contains') ?? ''
  );
  const [numberOfReferrals_eq, setNumberOfReferrals_eq] = useState<
    number | string
  >(searchParams.get('numberOfReferrals_eq') ?? '');
  const [totalPoints_lte, setTotalPoints_lte] = useState(
    searchParams.get('totalPoints_lte') ?? ''
  );
  const [totalPoints_gte, setTotalPoints_gte] = useState(
    searchParams.get('totalPoints_gte') ?? ''
  );
  const [totalTierPoints_lte, setTotalTierPoints_lte] = useState(
    searchParams.get('totalTierPoints_lte') ?? ''
  );
  const [totalTierPoints_gte, setTotalTierPoints_gte] = useState(
    searchParams.get('totalTierPoints_gte') ?? ''
  );
  const [tierId_eq, setTierId_eq] = useState(
    searchParams.get('tierId_eq') ?? ''
  );
  const [email_contains, setEmail_eq] = useState(
    searchParams.get('email_contains') ?? ''
  );
  const [membershipId_contains, setMembershipId_eq] = useState(
    searchParams.get('membershipId_contains') ?? ''
  );
  const [mobileNumber_contains, setPhoneNumber_eq] = useState(
    searchParams.get('mobileNumber_contains') ?? ''
  );
  const [supportTierIds_isnull, setSupportTierIds_isnull] = useState(
    searchParams.get('supportTierIds_isnull') ?? ''
  );
  const [status_label, setStatus_label] = useState<string | string[]>(
    searchParamsObject?.status_label !== undefined
      ? searchParamsObject?.status_label?.includes(',')
        ? searchParamsObject?.status_label?.split(',').reduce((tags, key) => {
            tags.push(+key);
            return tags;
          }, [])
        : [searchParamsObject?.status_label]
      : null
  );
  const [tagId_contains, setTagId_contains] = useState<number[]>(
    searchParamsObject?.tagId_contains !== undefined
      ? searchParamsObject?.tagId_contains?.includes(',')
        ? searchParamsObject?.tagId_contains?.split(',').reduce((tags, key) => {
            tags.push(+key);
            return tags;
          }, [])
        : [+searchParamsObject?.tagId_contains]
      : null
  );

  const [tagId_label, setTagId_label] = useState<string[]>(
    searchParamsObject?.tagId_label !== undefined
      ? searchParamsObject?.tagId_label?.includes(',')
        ? searchParamsObject?.tagId_label?.split(',').reduce((tags, key) => {
            tags.push(key);
            return tags;
          }, [])
        : [searchParamsObject?.tagId_label]
      : null
  );

  const [interestId_contains, setInterestId_contains] = useState<number[]>(
    searchParamsObject?.interestId_contains !== undefined
      ? searchParamsObject?.interestId_contains?.includes(',')
        ? searchParamsObject?.interestId_contains
            ?.split(',')
            .reduce((tags, key) => {
              tags.push(+key);
              return tags;
            }, [])
        : [+searchParamsObject?.interestId_contains]
      : null
  );

  const [interestId_label, setInterestId_label] = useState<string[]>(
    searchParamsObject?.interestId_label !== undefined
      ? searchParamsObject?.interestId_label?.includes(',')
        ? searchParamsObject?.interestId_label
            ?.split(',')
            .reduce((tags, key) => {
              tags.push(key);
              return tags;
            }, [])
        : [searchParamsObject?.interestId_label]
      : null
  );

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      dataIndex: 'snAppId',
      numeric: false,
      disablePadding: false,
      label: 'Account ID',
    },
    {dataIndex: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {
      dataIndex: 'numberOfReferrals',
      numeric: false,
      disablePadding: false,
      label: 'No. of Referral Transactions',
    },
    {
      dataIndex: 'point',
      numeric: false,
      disablePadding: false,
      label: 'Point',
    },
    {
      dataIndex: 'membership_tier',
      numeric: false,
      disablePadding: false,
      label: 'Membership Tier',
    },
    {
      dataIndex: 'tierExpireDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Demoted Date',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = type => {
    if (type === 'status_eq') {
      deleteSearchParams('status_eq');
      deleteSearchParams('status_label');
      setStatus_eq(1);
      setStatusSelected('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: 1,
      }));
    }

    if (type === 'numberOfReferrals_eq') {
      deleteSearchParams('numberOfReferrals_eq');
      setNumberOfReferrals_eq(0);
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: 1,
      }));
    }

    if (type === 'referralCode_contains') {
      deleteSearchParams('referralCode_contains');
      setReferralCode_contains('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: 1,
      }));
    }

    if (type === 'email_contains') {
      deleteSearchParams('email_contains');
      setEmail_eq('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'totalPoints') {
      deleteSearchParams('totalPoints_gte');
      deleteSearchParams('totalPoints_lte');
      setTotalPoints_lte('');
      setTotalPoints_gte('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        totalPoints_lte: undefined,
        totalPoints_gte: undefined,
      }));
    }
    if (type === 'totalTierPoints') {
      deleteSearchParams('totalTierPoints_gte');
      deleteSearchParams('totalTierPoints_lte');
      setTotalTierPoints_lte('');
      setTotalTierPoints_gte('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        totalTierPoints_gte: undefined,
        totalTierPoints_lte: undefined,
      }));
    }
    if (type === 'tierId_eq') {
      deleteSearchParams('tierId_eq');
      deleteSearchParams('tier_label');
      setTierId_eq('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
        _sort: 'createdDateTime:asc',
      }));
    }
    if (type === 'membershipId_contains') {
      deleteSearchParams('membershipId_contains');
      setMembershipId_eq('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'mobileNumber_contains') {
      deleteSearchParams('mobileNumber_contains');
      setPhoneNumber_eq('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'supportTierIds_isnull') {
      deleteSearchParams('supportTierIds_isnull');
      setSupportTierIds_isnull('');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'tagId_contains') {
      deleteSearchParams('tagId_contains');
      deleteSearchParams('tagId_label');
      setTagId_contains(null);
      setTagId_label(null);
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
    if (type === 'interestId_contains') {
      deleteSearchParams('interestId_contains');
      deleteSearchParams('interestId_label');
      setInterestId_contains(null);
      setInterestId_label(null);
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        [type]: undefined,
      }));
    }
  };

  const renderAction = (value: string) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  navigate(`/${path.ISLAND_MEMBERS}/${value}`, {
                    state: {
                      ...queryString.parse(searchParams.toString()),
                    },
                  });
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                navigate(`/${path.ISLAND_MEMBERS}/${value}`, {
                  state: {
                    ...queryString.parse(searchParams.toString()),
                  },
                });
              }}
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const handleClean = () => {
    setStatus_eq(1);
    setNumberOfReferrals_eq(0);
    setReferralCode_contains('');
    setTotalPoints_gte('');
    setTotalPoints_lte('');
    setTotalTierPoints_lte('');
    setTotalTierPoints_gte('');
    setStatus_label('');
    setStatusSelected('');
    setEmail_eq('');
    setTierId_eq('');
    setMembershipId_eq('');
    setPhoneNumber_eq('');
    setSupportTierIds_isnull('');
    setTagId_contains(null);
    setTagId_label(null);
    setInterestId_contains(null);
    setInterestId_label(null);
  };

  const handleFilter = () => {
    if (membershipId_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        membershipId_contains: membershipId_contains,
      }));
    }
    if (mobileNumber_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        mobileNumber_contains: mobileNumber_contains,
      }));
    }
    if (email_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_contains: email_contains,
      }));
    }
    if (status_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        status_eq: status_eq,
      }));
    }
    if (totalPoints_gte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        totalPoints_gte: totalPoints_gte,
      }));
    }
    if (totalPoints_lte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        totalPoints_lte: totalPoints_lte,
      }));
    }
    if (totalTierPoints_gte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        totalTierPoints_gte: totalTierPoints_gte,
      }));
    }
    if (totalTierPoints_lte) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        totalTierPoints_lte: totalTierPoints_lte,
      }));
    }
    if (numberOfReferrals_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        numberOfReferrals_eq: numberOfReferrals_eq,
      }));
    }
    if (referralCode_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        referralCode_contains: referralCode_contains,
      }));
    }
    if (tierId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        tierId_eq: tierId_eq,
        _sort: 'promotedDateTime:desc',
      }));
    }
    if (supportTierIds_isnull) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        supportTierIds_isnull: supportTierIds_isnull === '1',
      }));
    }
    if (tagId_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        tagId_contains: tagId_contains.toString(),
      }));
    }
    if (interestId_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        interestId_contains: interestId_contains.toString(),
      }));
    }
    toggleModal();
    // handleClean();
  };

  const renderLabelFilter = tierId => {
    const object = optionTier.find(item => item.id === tierId);
    if (object === undefined || object === null) {
      return searchParams.get('tier_label') ?? undefined;
    }
    return object?.label;
  };

  const getMembers = () => {
    setIsLoading(true);
    new MembersService().getAll(params).then(res => {
      if (Array.isArray(res?.data?.items)) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      } else {
        setDataSource([]);
        setTotalPage(0);
        setIsLoading(false);
      }
    });
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelMemberShip({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        supportTierIds_isnull:
          supportTierIds_isnull !== '' ? supportTierIds_isnull : undefined,
        tier_label: renderLabelFilter(tierId_eq),
        tagId_label: tagId_label !== null ? tagId_label.toString() : undefined,
        status_label: Number(status_eq) === 2 ? 'Deleted' : undefined,
        interestId_label:
          interestId_label !== null ? interestId_label.toString() : undefined,
      })
    );
    getMembers();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Islander Members" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        optionTier={optionTier}
        setOptionTier={setOptionTier}
        tierId_eq={tierId_eq}
        email_contains={email_contains}
        setTierId_eq={setTierId_eq}
        setEmail_eq={setEmail_eq}
        membershipId_contains={membershipId_contains}
        setMembershipId_eq={setMembershipId_eq}
        mobileNumber_contains={mobileNumber_contains}
        setPhoneNumber_eq={setPhoneNumber_eq}
        supportTierIds_isnull={supportTierIds_isnull}
        setSupportTierIds_isnull={setSupportTierIds_isnull}
        tagId_contains={tagId_contains}
        setTagId_contains={setTagId_contains}
        setTagId_label={setTagId_label}
        status_eq={status_eq}
        status_label={status_label}
        setStatus_eq={setStatus_eq}
        setNumberOfReferrals_eq={setNumberOfReferrals_eq}
        numberOfReferrals_eq={numberOfReferrals_eq}
        setReferralCode_contains={setReferralCode_contains}
        referralCode_contains={referralCode_contains}
        setTotalPoints_lte={setTotalPoints_lte}
        totalPoints_lte={totalPoints_lte}
        setTotalPoints_gte={setTotalPoints_gte}
        totalPoints_gte={totalPoints_gte}
        setTotalTierPoints_lte={setTotalTierPoints_lte}
        totalTierPoints_lte={totalTierPoints_lte}
        setTotalTierPoints_gte={setTotalTierPoints_gte}
        totalTierPoints_gte={totalTierPoints_gte}
        setStatus_label={setStatus_label}
        setStatusSelected={setStatusSelected}
        statusSelected={statusSelected}
        interestId_contains={interestId_contains}
        setInterestId_contains={setInterestId_contains}
        setInterestId_label={setInterestId_label}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Islander Members
          </Typography>

          <Breadcrumbs routes={[typeRoutes('Islander Members', null, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('email_contains') !== null && (
              <Chip
                label={searchParams.get('email_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_contains');
                }}
              />
            )}
            {renderLabelFilter(tierId_eq) && (
              <Chip
                label={renderLabelFilter(tierId_eq)}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('tierId_eq');
                }}
              />
            )}
            {searchParams.get('mobileNumber_contains') !== null && (
              <Chip
                label={searchParams.get('mobileNumber_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('mobileNumber_contains');
                }}
              />
            )}
            {searchParams.get('membershipId_contains') !== null && (
              <Chip
                label={searchParams.get('membershipId_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('membershipId_contains');
                }}
              />
            )}
            {searchParams.get('supportTierIds_isnull') !== null && (
              <Chip
                label={
                  searchParams.get('supportTierIds_isnull') === '0'
                    ? 'SC/PR'
                    : '-'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('supportTierIds_isnull');
                }}
              />
            )}
            {searchParams.get('status_label') !== null && (
              <Chip
                label={searchParams.get('status_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('status_eq');
                }}
              />
            )}
            {searchParams.get('numberOfReferrals_eq') !== null && (
              <Chip
                label={searchParams.get('numberOfReferrals_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('numberOfReferrals_eq');
                }}
              />
            )}
            {searchParams.get('referralCode_contains') !== null && (
              <Chip
                label={searchParams.get('referralCode_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('referralCode_contains');
                }}
              />
            )}
            {searchParams.get('totalPoints_gte') !== null &&
              searchParams.get('totalPoints_lte') !== null && (
                <Chip
                  label={`${searchParams.get(
                    'totalPoints_gte'
                  )} - ${searchParams.get('totalPoints_lte')}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('totalPoints');
                  }}
                />
              )}
            {searchParams.get('totalTierPoints_gte') !== null &&
              searchParams.get('totalTierPoints_lte') !== null && (
                <Chip
                  label={`${searchParams.get(
                    'totalTierPoints_gte'
                  )} - ${searchParams.get('totalTierPoints_lte')}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('totalTierPoints');
                  }}
                />
              )}
            {searchParams.get('tagId_label') !== null && (
              <Chip
                label={searchParams.get('tagId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('tagId_contains');
                }}
              />
            )}
            {searchParams.get('interestId_label') !== null && (
              <Chip
                label={searchParams.get('interestId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('interestId_contains');
                }}
              />
            )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        orderBy={orderBy}
        textNodata="There are no islander member(s) matching the filter."
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {dataSource?.map(row => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell component="th" scope="row">
                  {`${row.firstName} ${row.lastName}`}
                </TableCell>
                <TableCell align="left">{row.snAppId}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.numberOfReferrals}</TableCell>
                <TableCell align="left">{row.totalPoints}</TableCell>
                <TableCell align="left">
                  {row.tierDescription ?? row.tierName}
                </TableCell>
                <TableCell align="left">
                  {formatLocalDateFromUTCTime(row.tierExpireDateTime, format)}
                </TableCell>
                <TableCell
                  align="right"
                  padding="checkbox"
                  style={{paddingRight: '10px'}}
                >
                  {renderAction(row.id)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default IslanderMembers;
