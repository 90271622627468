import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {envRole, hasPermission} from '../utils';
import useAuth from '../hooks/useAuth';
import {path} from './path';
import {USER_TYPE} from '../constants/UserType';

const HandleRoutes = props => {
  const [hasAccess, setHasAccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location?.pathname;
  let controller = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  if (controller.includes('/')) {
    controller = controller.substring(0, controller.indexOf('/'));
  }
  const {signOut, getHomeUrl} = useAuth();
  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  useEffect(() => {
    setHasAccess(false);
    if (role) {
      if (!hasPermission(`/${controller}`, 'read')) {
        const pathRedirect = getHomeUrl();
        if (pathRedirect === pathname) {
          if (signOut()) {
            navigate(`/${path.AUTH}/${path.SIGN_IN}`);
          }
        } else {
          navigate(pathRedirect);
        }
      } else {
        setHasAccess(true);
      }
    }
  }, [role, pathname]);
  return hasAccess ? props?.content : null;
};

export default HandleRoutes;
