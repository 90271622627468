/* eslint-disable node/no-extraneous-import */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {SortOutlined} from '@mui/icons-material';

import {ColumnsType, typeRoutes} from '../../types/typeIslandersMembers';
import {envRole, formatLocalDateFromUTCTime} from '../../utils';
import {Breadcrumbs, Table} from '../../components';

//style
import {Typography, Divider, StyledTableRow} from './ChangeLog.style';
import {appVersion} from '../../config';
import ChangelogService from '../../services/Changelog/changelog.service';
import {USER_TYPE} from '../../constants/UserType';

function ChangeLog() {
  const formatDateTime = 'HH:mm DD/MM/YYYY';

  // states
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    platform_eq: +envRole === USER_TYPE.ADMINISTRATOR ? 'AP' : 'IP',
    _sort: `createdDateTime:${order}`,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'version',
      numeric: false,
      disablePadding: false,
      label: 'Version',
    },
    {
      dataIndex: 'platform',
      numeric: false,
      disablePadding: false,
      label: 'Platform',
    },
    {
      dataIndex: 'content',
      numeric: false,
      disablePadding: false,
      label: 'Content',
    },
    {
      dataIndex: 'updatedTimeAndDate',
      numeric: false,
      disablePadding: false,
      label: 'Updated Time and Date',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const getListChangelog = () => {
    setIsLoading(true);
    new ChangelogService()
      .getAll(params)
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res.data.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setError(error);
      });
  };

  useEffect(() => {
    getListChangelog();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title={`${appVersion} - Chagelog`} />
      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            {appVersion} - Changelog
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Changelog', undefined, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setOrder(order === 'desc' ? 'asc' : 'desc');
                setParams(preState => ({
                  ...preState,
                  _sort: `createdDateTime:${order}`,
                }));
              }}
            >
              <SortOutlined />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no change log."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        isLoading={isLoading}
        count={totalPage}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    {row.version}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.platform}
                  </TableCell>
                  <TableCell sx={{wordBreak: 'break-word'}} align="left">
                    <div dangerouslySetInnerHTML={{__html: row.content}}></div>
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(
                      row.createdDateTime,
                      formatDateTime
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default ChangeLog;
