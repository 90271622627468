import styled from 'styled-components/macro';
import {spacing} from '@mui/system';

import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  TableRow,
  Stack,
} from '@mui/material';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const StyledTableRow = styled(TableRow)``;
const StyledStack = styled(Stack)`
  margin-right: 10px;
`;
const StyledAction = styled.div``;

const StyledIcon = {
  width: '18px',
  height: '18px',
};

const StyledColorPoints = styled.div`
  font-weight: bold;
  color: #00000;

  &.points {
    &--1 {
      color: #4caf50;
    }
    &--2 {
      color: #b71c1c;
    }
  }
`;

export {
  Typography,
  Divider,
  StyledTableRow,
  StyledStack,
  StyledAction,
  StyledIcon,
  StyledColorPoints,
};
