import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  Typography as MuiTypography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
} from '@mui/material';

const StyledIconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  cursor: pointer;
`;

const Typography = styled(MuiTypography)(spacing);

const StyledModalTitle = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 900;
`;
const Divider = styled(MuiDivider)`
  ${spacing};
  margin: 10px 0px 12px 0px;
`;
const StyledModalActions = styled(DialogActions)`
  padding-bottom: 25px;
  padding-right: 50px;
  .btn-action-1 {
    width: 167px;
    display: grid;
  }
  .btn-action-2 {
    width: 167px;
    display: grid;
  }
`;

export {
  StyledIconClose,
  Typography,
  StyledModalTitle,
  StyledModalActions,
  Divider,
};
