/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {Edit} from '@mui/icons-material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {DateRange} from '@mui/lab/DateRangePicker';

import {ColumnsType, RowType, typeRoutes} from '../../../types/typePartnership';
import {path} from '../../../routes/path';
import {
  Breadcrumbs,
  Table,
  Chip,
  Tooltips,
  EnhancedTableToolbar,
  Button,
} from '../../../components';
import ModalFilter from './components/Modalfilter/ModalFilter';
import ModalAddNewPartner from './components/ModalAddNewPartner/ModalAddNewPartner';
import {
  formatDate,
  formatLocalDateFromUTCTime,
  getEndOfDate,
  getStartOfDate,
} from '../../../utils';
import {ColorType} from '../../../types/typeChip';
import queryString from 'query-string';
import ReferralPartnershipService from '../../../services/ReferralProgramme/referral-partnership.service';

//style
import {
  Typography,
  Divider,
  StyledStack,
  StyledTableRow,
  StyledAction,
  StyledIcon,
} from './Partnership.style';
import ExportExcelService from '../../../services/ExportExcel/ExportExcel';
import useAuth from '../../../hooks/useAuth';
import success from '../../../constants/success';

function Partnership() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};

  const format = 'DD/MM/YYYY';
  const formatDateTime = 'DD/MM/YYYY HH:mm A';

  // states
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [focusId, setFocusId] = useState(null);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAddPartner, setOpenModalAddPartner] = useState(false);
  const [nameContainsList, setNameContainsList] = useState([]);
  const [sort, setSort] = useState<string>('createdDateTime:desc');
  const [valueFilter, setValueFilter] = useState({
    codeType_eq: searchParams.get('codeType_eq') ?? '',
    referralCode_contains: searchParams.get('referralCode_contains') ?? '',
    nameOrDescription_contains:
      searchParams.get('nameOrDescription_contains') ?? '',
    expireStartDateTime_gte: searchParams.get('expireStartDateTime_gte') ?? '',
    expireEndDateTime_lte: searchParams.get('expireEndDateTime_lte') ?? '',
  });
  const searchParamsObject = queryString.parse(searchParams.toString());

  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'createdDateTime:desc',
    referralType_ne: 2,
    codeType_eq: searchParamsObject.codeType_eq ?? undefined,
    referralCode_contains:
      searchParamsObject.referralCode_contains ?? undefined,
    nameOrDescription_contains:
      searchParamsObject.nameOrDescription_contains ?? undefined,
    expireStartDateTime_gte:
      searchParamsObject.expireStartDateTime_gte ?? undefined,
    expireEndDateTime_lte:
      searchParamsObject.expireEndDateTime_lte ?? undefined,
  });

  const [listSortTabs, setListSortTabs] = useState<any[]>([
    {
      value: 'createdDateTime:desc',
      key: 'All',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Available',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Coming',
    },
    {
      value: 'createdDateTime:desc',
      key: 'Expired',
    },
    {
      value: 'updatedDateTime:desc',
      key: 'Deleted',
    },
  ]);

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'numberId',
      numeric: false,
      disablePadding: false,
      label: 'Referral Creation ID',
    },
    {
      dataIndex: 'referralPartnerName',
      numeric: false,
      disablePadding: false,
      label: 'Referral Partner Name',
    },
    {
      dataIndex: 'referralCode',
      numeric: false,
      disablePadding: false,
      label: 'Referral Code',
    },
    {
      dataIndex: 'referralPointsReward',
      numeric: false,
      disablePadding: false,
      label: 'Referral Points Reward',
    },
    {
      dataIndex: 'referralQuantity',
      numeric: false,
      disablePadding: false,
      label: 'Referral Quantity',
    },
    {
      dataIndex: 'noReferral',
      numeric: false,
      disablePadding: false,
      label: 'No. of Referral',
    },
    {
      dataIndex: 'referralPeriod',
      numeric: false,
      disablePadding: false,
      label: 'Referral Period',
    },
    {
      dataIndex: 'createdDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Creation DateTime',
    },
    {
      dataIndex: 'updatedDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Last Edited DateTime',
    },
    {
      dataIndex: 'referralCodeStatus',
      numeric: false,
      disablePadding: false,
      label: 'Referral Code Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const handleClean = () => {
    setValueRangePicker([null, null]);
    setValueFilter({
      codeType_eq: '',
      referralCode_contains: '',
      nameOrDescription_contains: '',
      expireEndDateTime_lte: '',
      expireStartDateTime_gte: '',
    });
  };

  const handleFilter = () => {
    if (valueFilter?.nameOrDescription_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        nameOrDescription_contains: valueFilter?.nameOrDescription_contains,
      }));
    }
    if (valueFilter?.referralCode_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        referralCode_contains: valueFilter?.referralCode_contains,
      }));
    }
    if (valueFilter?.codeType_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        codeType_eq: valueFilter?.codeType_eq,
      }));
    }
    if (valueRangePicker[0] && valueRangePicker[1]) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        expireEndDateTime_lte: getEndOfDate(valueRangePicker[1]),
        expireStartDateTime_gte: getStartOfDate(valueRangePicker[0]),
      }));
    } else if (
      valueFilter?.expireStartDateTime_gte !== '' &&
      valueFilter?.expireEndDateTime_lte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        expireEndDateTime_lte: valueFilter?.expireEndDateTime_lte,
        expireStartDateTime_gte: valueFilter?.expireStartDateTime_gte,
      }));
    }
    toggleModalFilter();
    // handleClean();
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'nameOrDescription_contains') {
      deleteSearchParams('nameOrDescription_contains');
      setValueFilter({
        ...valueFilter,
        nameOrDescription_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        nameOrDescription_contains: undefined,
      }));
    }
    if (type === 'referralCode_contains') {
      deleteSearchParams('referralCode_contains');
      setValueFilter({
        ...valueFilter,
        referralCode_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        referralCode_contains: undefined,
      }));
    }
    if (type === 'codeType_eq') {
      deleteSearchParams('codeType_eq');
      setValueFilter({
        ...valueFilter,
        codeType_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        codeType_eq: undefined,
      }));
    }
    if (type === 'date') {
      deleteSearchParams('expireStartDateTime_gte');
      deleteSearchParams('expireEndDateTime_lte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        expireEndDateTime_lte: '',
        expireStartDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        expireStartDateTime_gte: undefined,
        expireEndDateTime_lte: undefined,
      }));
    }
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                navigate(
                  `/${path.REFERRAL_PROGRAMME}/${path.PARTNERSHIP}/${value}`,
                  {
                    state: {
                      ...queryString.parse(searchParams.toString()),
                    },
                  }
                );
              }}
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const renderStatus = value => {
    if (value.status === 2) {
      return <Chip label="Deleted" color={'default' as ColorType} />;
    } else {
      if (
        new Date().toISOString() <=
          new Date(value?.expireEndDateTime).toISOString() &&
        new Date().toISOString() >=
          new Date(value?.expireStartDateTime).toISOString()
      ) {
        return <Chip label="Available" color={'success' as ColorType} />;
      }
      if (
        new Date(value?.expireStartDateTime).toISOString() >=
        new Date().toISOString()
      ) {
        return <Chip label="Upcoming" color={'warning' as ColorType} />;
      }
      if (
        new Date(value?.expireEndDateTime).toISOString() <=
        new Date().toISOString()
      ) {
        return <Chip label="Expired" color={'error' as ColorType} />;
      }
    }
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const toggleModalAdd = () => {
    setOpenModalAddPartner(!openModalAddPartner);
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportReferralPartner({
        ...params,
        email: email,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const renderDate = values => {
    if (values) {
      return (
        <>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(
              values.expireStartDateTime,
              formatDateTime
            )}{' '}
            -
          </Typography>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(
              values.expireEndDateTime,
              formatDateTime
            )}
          </Typography>
        </>
      );
    }
  };

  const renderSingleDate = values => {
    if (values) {
      return (
        <>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(values, formatDateTime)}
          </Typography>
        </>
      );
    }
  };

  const getListPartnership = () => {
    setIsLoading(true);
    new ReferralPartnershipService().getAll(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    getListPartnership();
  }, [params]);

  useEffect(() => {
    setParams(prevState => ({
      ...prevState,
      _sort: sort,
    }));
  }, [sort]);

  return (
    <React.Fragment>
      <Helmet title="Partership" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
        valueRangePicker={valueRangePicker}
        setValueRangePicker={setValueRangePicker}
      />

      <ModalAddNewPartner
        visible={openModalAddPartner}
        reloadPage={getListPartnership}
        onClose={toggleModalAdd}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Referral Programme
          </Typography>

          <Breadcrumbs routes={[typeRoutes('Partnership', undefined, true)]} />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('nameOrDescription_contains') !== null && (
              <Chip
                label={searchParams.get('nameOrDescription_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('nameOrDescription_contains');
                }}
              />
            )}
            {searchParams.get('referralCode_contains') !== null && (
              <Chip
                label={searchParams.get('referralCode_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('referralCode_contains');
                }}
              />
            )}
            {searchParams.get('codeType_eq') !== null && (
              <Chip
                label={
                  searchParams.get('codeType_eq') === '1'
                    ? 'Standard'
                    : 'Unique'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('codeType_eq');
                }}
              />
            )}

            {searchParams.get('expireStartDateTime_gte') !== null &&
              searchParams.get('expireEndDateTime_lte') !== null && (
                <Chip
                  label={`${formatDate(
                    searchParams.get('expireStartDateTime_gte'),
                    format
                  )} -
                    ${formatDate(
                      searchParams.get('expireEndDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}
          </StyledStack>
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <Button onClick={toggleModalAdd} loading={isLoading}>
            Add New
          </Button>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <EnhancedTableToolbar
        isHeader={true}
        isLoading={isLoading}
        isAddNew
        setParams={setParams}
        comingLabel="Upcoming"
        enableDeletedTab
        listSortTabs={listSortTabs}
      />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        textNodata="There are no referral partner(s) matching the filter."
        orderBy={orderBy}
        isLoading={isLoading}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {dataSource?.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell align="left">{row.numberId}</TableCell>
                <TableCell component="th" id={labelId} scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.prefix}</TableCell>
                <TableCell align="left">{row.point}</TableCell>
                <TableCell align="left">
                  {row.quantity === -1 ? <div>&#8734;</div> : row.quantity}
                </TableCell>
                <TableCell align="left">{row.usedQuantity}</TableCell>
                <TableCell align="left">{renderDate(row)}</TableCell>
                <TableCell align="left">
                  {renderSingleDate(row.createdDateTime)}
                </TableCell>
                <TableCell align="left">
                  {renderSingleDate(row.updatedDateTime)}
                </TableCell>
                <TableCell align="left">{renderStatus(row)}</TableCell>
                <TableCell
                  align="right"
                  padding="checkbox"
                  style={{paddingRight: '10px'}}
                >
                  {renderAction(row.id)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default Partnership;
