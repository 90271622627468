import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  DialogActions,
  DialogTitle,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';
const StyledIconClose = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
`;

const StyledModalActions = styled(DialogActions)`
  padding-bottom: 40px;
`;
const StyledModalTitle = styled(DialogTitle)`
  font-size: 24px;
  font-weight: 900;
  margin: 20px 20px 0px 20px;
`;
const Divider = styled(MuiDivider)`
  ${spacing};
  margin: 10px 25px 12px 25px;
`;
const Typography = styled(MuiTypography)`
  ${spacing};
  margin-left: 45px;
  margin-bottom: 10px;
`;
const StyledTitle = styled.div`
  margin-bottom: 8px;
  color: '#000000';
  font-weight: '500';
`;

export {
  StyledIconClose,
  StyledModalActions,
  StyledModalTitle,
  Divider,
  Typography,
  StyledTitle,
};
