import styled from 'styled-components/macro';
import {
  Paper,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from '@mui/material';
import {spacing} from '@mui/system';

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};
  min-width: 456px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
  .btn-reset_password {
    margin-top: 10px;
  }
`;
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{my?: number}>(spacing);

const StyledImage = styled.div`
  img {
    max-width: 427px;
    max-height: 187px;
  }
`;
export {Wrapper, Alert, TextField, StyledImage};
