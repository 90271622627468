/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import useAuth from '../../../hooks/useAuth';
import {Helmet} from 'react-helmet-async';
import {Box, CardContent, FormControl, Grid} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {typeRoutes} from '../../../types/typeSDCAdministrators';
import {Breadcrumbs, InputPassword} from '../../../components';
import PasswordValidator from '../../../components/PasswordValidator/PasswordValidator';
import validatePasswordPattern from '../../../components/PasswordValidator/validatePasswordPattern';
import ResetPasswordService from '../../../services/User/reset_password.service';

// styles
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
  ResetPasswordRoot,
  Wrapper,
} from './ResetPassword.style';

function ResetPassword() {
  const {showNotification, getAccessToken} = useAuth();

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [initialValues] = useState({
    newPassword: '',
    confirmPassword: '',
    password: '',
  });

  const onEdit = (values, {resetForm}) => {
    setIsLoading(true);
    new ResetPasswordService()
      .post('resetPassword', {
        newPassword: values?.newPassword,
        password: values?.password,
        token: getAccessToken(),
      })
      .then(res => {
        setIsLoading(false);
        if (res && res?.success) {
          resetForm({});
          showNotification({
            message: res?.Message,
            variation: 'success',
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangeValidatePasswordPattern = (passwordStr: string): void => {
    setDisabled(!validatePasswordPattern(passwordStr).isPasswordValid);
  };

  // const getToken = () => {
  //   setIsLoading(true);
  //   new ResetPasswordService()
  //     .post('selfRequestResetPassword', {email: user?.email})
  //     .then(res => {
  //       if (res?.data) {
  //         setIsLoading(false);
  //         setNewToken(res?.data?.token);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (user?.email) {
  //     getToken();
  //   }
  // }, [user?.email]);

  return (
    <>
      <Helmet title="Edit Administrator Detail" />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Change password
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes('User', null, true),
              typeRoutes('Change password', null, true),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            newPassword: Yup.string().required('Enter password is required'),
            password: Yup.string().required(
              'Enter current password is required'
            ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('newPassword'), null], 'Password does not match!')
              .required('Confirm password is required'),
          })}
          onSubmit={onEdit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container mb="30px">
                <Grid item xs={12} lg={12}>
                  <Shadow>
                    <Card px={6} pt={6}>
                      <CardContent>
                        <ResetPasswordRoot>
                          <Wrapper>
                            <Grid
                              container
                              rowSpacing={0}
                              columnSpacing={10}
                              mb="50px"
                            >
                              <Grid item xs={12} mb="30px">
                                <Typography
                                  variant="h4"
                                  align="center"
                                  gutterBottom
                                >
                                  Reset Password
                                </Typography>
                                <Typography variant="body1" align="center">
                                  Enter your password new to reset your password
                                </Typography>
                              </Grid>

                              <FormControl variant="standard" fullWidth>
                                <InputPassword
                                  className="mandatory-field"
                                  name="password"
                                  label="Current Password"
                                  value={values.password}
                                  error={Boolean(
                                    touched.password && errors.password
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </FormControl>

                              <FormControl variant="standard" fullWidth>
                                <InputPassword
                                  name="newPassword"
                                  label="Enter Password"
                                  value={values.newPassword}
                                  error={Boolean(
                                    touched.newPassword && errors.newPassword
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.newPassword && errors.newPassword
                                  }
                                  onBlur={handleBlur}
                                  onChange={evt => {
                                    const {
                                      target: {value},
                                    } = evt;

                                    onChangeValidatePasswordPattern(value);
                                    handleChange(evt);
                                  }}
                                  className="mandatory-field"
                                />
                                <PasswordValidator
                                  passwordStr={values.newPassword}
                                />
                              </FormControl>
                              <FormControl variant="standard" fullWidth>
                                <InputPassword
                                  name="confirmPassword"
                                  label="Confirm Password"
                                  value={values.confirmPassword}
                                  error={Boolean(
                                    touched.confirmPassword &&
                                      errors.confirmPassword
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  className="mandatory-field"
                                />
                              </FormControl>
                              <LoadingButton
                                type="submit"
                                fullWidth
                                className="btn-reset_password"
                                variant="contained"
                                color="primary"
                                loading={isLoading}
                                disabled={disabled}
                              >
                                Submit
                              </LoadingButton>
                            </Grid>
                          </Wrapper>
                        </ResetPasswordRoot>
                      </CardContent>
                    </Card>
                  </Shadow>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default ResetPassword;
