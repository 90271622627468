/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {Modal, Select, Button, Input} from '../../../../components';
import MembersService from '../../../../services/Members/members.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  optionTier?: any;
  setOptionTier?: (any) => void;
  tierId_eq?: string;
  email_eq?: string;
  setTierId_eq?: (string) => void;
  setEmail_eq?: (string) => void;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  tierId_eq,
  email_eq,
  optionTier,
  setOptionTier,
  setTierId_eq,
  setEmail_eq,
}: ModalFilterProps) {
  // states
  const [params] = useState({
    tierName_ne: 'Jubilee',
  });

  const onChangeEmail_eq = (event: any) => {
    setEmail_eq(event.target.value);
  };

  const onChangeTierId_eq = (event: any) => {
    setTierId_eq(event.target.value);
  };

  const handleClose = () => {
    onClose();
    if (!visible) {
      handleClean();
    }
  };

  const getListTier = () => {
    new MembersService().getListTier(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const newData = res?.data?.items.map(item => {
          item.value = item.id;
          item.label = item.tierDescription ?? item.tierName;
          return item;
        });
        setOptionTier(newData);
      }
    });
  };

  useEffect(() => {
    if (visible) {
      getListTier();
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={handleClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TIER
            </Typography>
            <Select
              labelId="tierId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChangeTierId_eq(e)}
              value={tierId_eq}
              placeholder="Select by tier"
              size="small"
              selectOptions={optionTier}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY EMAIL
            </Typography>
            <Input
              type="email"
              variant="outlined"
              placeholder="Enter email"
              size="small"
              value={email_eq}
              onChange={e => onChangeEmail_eq(e)}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button
              disabled={!tierId_eq && !email_eq}
              variant="text"
              onClick={handleClean}
            >
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button disabled={!tierId_eq && !email_eq} onClick={handleFilter}>
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
