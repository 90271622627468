import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {
  Table,
  Paper as MuiPaper,
  Toolbar,
  Typography as MuiTypography,
} from '@mui/material';

const StyledTable = styled(Table)``;

const Paper = styled(MuiPaper)`
  ${spacing};
  border-start-end-radius: 0px;
  border-start-start-radius: 0px;
`;

const Typography = styled(MuiTypography)`
  ${spacing};
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const StyledHeader = styled.div`
  margin-bottom: 10px;
`;

const TableHeader = styled.div`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  cursor: default;
`;

const StyledMuiTableRowHover = {
  '& .MuiTableBody-root': {
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
};

const StyledToolbar = styled(Toolbar)``;

const StyledPaperLoading = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '748px',
};

const StyledNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export {
  StyledTable,
  Paper,
  Spacer,
  StyledHeader,
  TableHeader,
  StyledMuiTableRowHover,
  StyledToolbar,
  StyledPaperLoading,
  StyledNoData,
  Typography,
};
