import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CardContent, Grid, Stack} from '@mui/material';

import {Breadcrumbs, Button, Chip} from '../../../../components';
import {
  DataDetail,
  typeRoutes,
} from '../../../../types/typeReferralTransactions';
import {path} from '../../../../routes/path';
import {formatDate} from '../../../../utils';
import queryString from 'query-string';
import ReferralTransactionService from '../../../../services/ReferralProgramme/referral-transaction.service';
import {
  REFERRAL_TRANSACTION_STATUS,
  REFERRAL_TRANSACTION_STATUS_COLOR,
  REFERRAL_TRANSACTION_STATUS_LABEL,
} from '../../../../constants/status';
import {ColorType} from '../../../../types/typeChip';

// styles
import {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledCardContent,
  StyledChip,
} from './ReferralTransactionDetail.style';

function ReferralTransactionDetail() {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const locationUrl = useLocation();
  const idDetail = paramsUrl?.id;
  const membershipId = locationUrl?.state?.membershipId;
  const merchantId = locationUrl?.state?.merchantId;

  const formatDateTime = 'DD/MM/YYYY HH:mm';
  // states
  const [dataDetail, setDataDetail] = useState<DataDetail>({});

  const renderCaption = (value: string) => {
    if (value) {
      return (
        <Typography color="rgba(0, 0, 0, 0.5)" mb="31px" variant="h6">
          {value}:
        </Typography>
      );
    }
    return null;
  };

  const renderSubtitle = value => {
    return (
      <Typography mb="31px" variant="h6">
        {value ? value : '--'}
      </Typography>
    );
  };

  const getDataDetail = () => {
    new ReferralTransactionService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setDataDetail(res.data.data);
      }
    });
  };

  const renderLink = () => {
    return `/${path.REFERRAL_PROGRAMME}/${
      path.REFERRAL_TRANSACTIONS
    }?${queryString.stringify(locationUrl?.state?.filterParams)}`;
  };

  const renderStatus = () => {
    const status =
      dataDetail?.processId === 1
        ? REFERRAL_TRANSACTION_STATUS.COMPLETE
        : REFERRAL_TRANSACTION_STATUS.PENDING;
    const renderColor = REFERRAL_TRANSACTION_STATUS_COLOR[status];
    return (
      <Chip
        label={REFERRAL_TRANSACTION_STATUS_LABEL[status]}
        color={renderColor as ColorType}
        size="medium"
      />
    );
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Referral Transaction Detail" />

      <Typography variant="h3" gutterBottom>
        Referral Transaction Detail
      </Typography>
      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            typeRoutes('Referral Programme', undefined),
            typeRoutes('Referral Transactions', renderLink(), false, {
              membershipId: membershipId,
              merchantId: merchantId,
            }),
            typeRoutes('Detail', undefined, true),
          ]}
        />
      </StyledDivBreadcrumbs>
      <Divider my={6} />
      <Grid container mb={9}>
        <Grid item xs={12} md={12}>
          <StyledChip>{renderStatus()}</StyledChip>
          <Card
            pl={12}
            pr={12}
            sx={{
              position: 'relative',
              bottom: '16px',
            }}
          >
            <StyledCardContent>
              <Divider my={6} />
              <Grid wrap="nowrap" container justifyContent="center">
                <Grid item xs={4} md={4}>
                  {renderCaption('Referral Type')}
                  {renderCaption('Referral Partner Name')}
                  {renderCaption('Referrer Reference')}
                  {renderCaption('Date of Referral')}
                  {renderCaption('Referral Account ID')}
                  {renderCaption('Referral Code Used')}
                  {renderCaption('Points Rewarded')}
                  {renderCaption('Transaction ID')}
                </Grid>
                <Grid item xs={8} md={8}>
                  {renderSubtitle(
                    dataDetail?.referralType === 1
                      ? 'Partnership'
                      : 'Islander Member'
                  )}
                  {renderSubtitle(dataDetail?.referralMerchantName)}
                  {renderSubtitle(dataDetail?.referralCampaignId)}
                  {renderSubtitle(
                    formatDate(dataDetail?.createdDateTime, formatDateTime)
                  )}
                  {renderSubtitle(dataDetail?.accountId)}
                  {renderSubtitle(dataDetail?.referralCode)}
                  {renderSubtitle(dataDetail?.pointsRewarded)}
                  {renderSubtitle(dataDetail?.transactionId)}
                </Grid>
              </Grid>
            </StyledCardContent>
          </Card>
        </Grid>
        <Divider my={6} />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card px={6} pt={3}>
            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                    // width="125px"
                  >
                    Go to Transactions
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      // navigate(`/${path.VOUCHER}/${dataDetail?.ticketId}`);
                    }}
                    disabled
                  >
                    Go to Referrer
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      navigate(
                        `/${path.ISLAND_MEMBERS}/${dataDetail?.membershipId}`
                      );
                    }}
                  >
                    Go to Referee
                  </Button>
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ReferralTransactionDetail;
