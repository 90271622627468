import React from 'react';
import {createFilterOptions} from '@mui/material/Autocomplete';
import Input from '../Input/Input';
// style
import {StyleAutoComplete} from './MultipleSelect.style';

interface AutoCompleteOptionsProps {
  title: string;
  value: string;
}

interface AutoCompleteProps {
  id?: string;
  options?: Array<AutoCompleteOptionsProps>;
  placeholder?: string;
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: AutoCompleteOptionsProps) => option.title,
});

function MultipleSelect({
  id,
  options,
  placeholder,
  ...other
}: AutoCompleteProps) {
  return (
    <StyleAutoComplete
      id={id}
      getOptionLabel={option => option.title}
      filterOptions={filterOptions}
      size="small"
      options={options}
      renderInput={params => (
        <Input variant="outlined" placeholder={placeholder} {...params} />
      )}
      {...other}
    />
  );
}

export default MultipleSelect;
