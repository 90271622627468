/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
// style
import {StyledModal} from './Modal.style';

interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  children: ReactNode;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

function Modal({
  visible,
  onClose,
  fullScreen,
  children,
  maxWidth = 'xs',
}: ModalProps) {
  return (
    <StyledModal
      open={visible}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
    >
      {children}
    </StyledModal>
  );
}
export default Modal;
