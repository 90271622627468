import React from 'react';
import {useLocation} from 'react-router-dom';
import {hasPermission} from '../../utils';

import {SidebarItemsType} from '../../types/typeSidebar';
import reduceChildRoutes from './SidebarReduceChildRoutes';

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
};

const SidebarNavList: React.FC<SidebarNavListProps> = props => {
  const {pages, depth} = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce((items, page) => {
    const {href} = page;

    if (hasPermission(href, 'read')) {
      return reduceChildRoutes({items, page, currentRoute, depth});
    }
    return items;
  }, [] as JSX.Element[]);

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
