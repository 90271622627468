import {TextField} from '@mui/material';
import styled from 'styled-components/macro';

const StyledInput = styled(TextField)`
  margin-bottom: 18px;
  input:read-only {
    font-weight: 100;
  }
`;

export {StyledInput};
