import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {Typography as MuiTypography, DialogActions} from '@mui/material';

const StyledModalActions = styled(DialogActions)`
  padding-bottom: 40px;
`;
const Typography = styled(MuiTypography)(spacing);

const StyledTitle = styled.div`
  &.type {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 24px;

    margin-top: 34px;
    margin-bottom: 22px;

    &--approve {
      color: #4caf50;
    }

    &--declined {
      color: #b71c1c;
    }
  }
`;

export {StyledModalActions, Typography, StyledTitle};
