import BaseApiService from '../baseApi.service';

export default class ArticleService extends BaseApiService {
  readonly basePath: string = 'articles/v1';
  constructor(props = null) {
    super(props);
  }

  uploadImage = async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    return await this.post('uploadImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(async res => res.data);
  };

  getArticleFromRedis = async () => {
    return await this.get('getfromredis').then(res => {
      return res;
    });
  };

  storeInRedis = async params => {
    return await this.post('storeinredis', params).then(res => {
      return res;
    });
  };
}
