/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement} from 'react';
import {ColorType} from '../../types/typeChip';
// style
import {StyledChip, StyledSizeLabel} from './Chip.style';

interface PropsChip {
  label: string;
  size?: 'small' | 'medium';
  variant?: 'filled' | 'outlined';
  color?: ColorType;
  avatar?: ReactElement<any, string | React.JSXElementConstructor<any>>;
  icon?: ReactElement<any, string | React.JSXElementConstructor<any>>;
  onDelete?: () => void;
  onClick?: () => void;
}

function Chip({
  label,
  variant,
  size = 'small',
  color,
  avatar,
  icon,
  onDelete,
  onClick,
  ...other
}: PropsChip) {
  return (
    <StyledChip
      size={size}
      label={label}
      variant={variant}
      color={color}
      avatar={avatar}
      icon={icon}
      sx={StyledSizeLabel}
      onDelete={onDelete}
      onClick={onClick}
      {...other}
    />
  );
}

export default Chip;
