/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {InputAdornment} from '@mui/material';

// style
import {StyledInput, StyledIconButton} from './InputPassword.style';

interface InputProps {
  className?: string;
  name?: string;
  label?: string;
  value?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  key?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  variant?: 'filled' | 'outlined' | 'standard';
  placeholder?: string;
  defaultValue?: string | number;
}

function Input({
  className,
  name,
  label,
  value,
  disabled,
  size,
  key,
  fullWidth,
  onChange,
  onBlur,
  error,
  helperText,
  color = 'primary',
  variant = 'standard',
  readOnly = false,
  placeholder,
  defaultValue,
  ...other
}: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <StyledInput
        label={label}
        key={key}
        name={name}
        defaultValue={defaultValue}
        variant={variant}
        className={className}
        value={value}
        disabled={disabled}
        onChange={onChange}
        fullWidth={fullWidth}
        type={showPassword ? 'text' : 'password'}
        size={size}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        color={color}
        placeholder={placeholder}
        InputProps={{
          readOnly: readOnly,
          endAdornment: (
            <InputAdornment position="end">
              <StyledIconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </StyledIconButton>
            </InputAdornment>
          ),
        }}
        {...other}
      />
    </>
  );
}
export default Input;
