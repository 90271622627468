/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {ColumnsType, RowType, typeRoutes} from '../../types/typeAllTransaction';
import {Breadcrumbs, Table, Tooltips} from '../../components';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import ArticleService from '../../services/Article/article.service';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledAction,
  StyledIcon,
} from './Article.style';

const Article = (props: any) => {
  const formatDateTime = 'DD/MM/YYYY HH:mm';

  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [focusId, setFocusId] = useState(null);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'image',
      numeric: false,
      disablePadding: false,
      label: 'Article Featured Image',
    },
    {
      dataIndex: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Article Title',
    },
    {
      dataIndex: 'path',
      numeric: false,
      disablePadding: false,
      label: 'Article Item Path',
    },
    {
      dataIndex: 'Article URL',
      numeric: false,
      disablePadding: false,
      label: 'URL',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getArticle = () => {
    setIsLoading(true);
    new ArticleService().getArticleFromRedis().then(res => {
      if (res.data.success) {
        setDataSource([res.data.data]);
      }
      setIsLoading(false);
    });
  };

  const toggleModal = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                toggleModal(!isModalAddNew);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  useEffect(() => {
    getArticle();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Event" />

      <ModalAddNew
        visible={isModalAddNew}
        article={dataSource[0] || {}}
        onClose={() => {
          toggleModal(!isModalAddNew);
        }}
        reloadPage={getArticle}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Article
          </Typography>
          <Breadcrumbs routes={[typeRoutes('Article', null, true)]} />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={() => {}}
        onChangeRowsPerPage={() => {}}
        rowsPerPage={params._size}
        textNodata="There are no all event(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(index)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell component="th" scope="row">
                    <img src={row.image} width={60} height={80} />
                  </TableCell>
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.path}</TableCell>
                  <TableCell align="left">{row.url}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(index)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
};

export default Article;
