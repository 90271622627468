export type ColumnsType = {
  dataIndex?: string | null;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  disabled?: boolean;
};

export type RowType = {
  [key: string]: string | number;
  type: string;
  reference: string;
  date: string;
  accountId: string;
};

export type DataDetail = {
  accountId?: string;
  createdDateTime?: string;
  updatedDateTime?: string;
  id?: string;
  processId?: number;
  membershipId?: string;
  pointsRewarded?: number;
  referralCampaignMerchantId?: string;
  referralCodeId?: number;
  referralType?: number | string;
  status?: string | number;
  transactionId?: number | string;
  referralPartnerName?: string;
  referralCodeUsed?: string;
};

export type VoucherDetail = {
  id?: string;
  title?: string;
  numberId?: string | number;
};

export type MembershipTicketDetail = {
  ticketId?: string | number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean,
  state?: object
) {
  return {name, path, isCurrentPage, state};
}
