/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {DialogContent, Grid, Stack} from '@mui/material';
import {Modal, Button, Select} from '../../../../components';
import {images} from '../../../../constants/image';
import FormControl from '@mui/material/FormControl';

// style
import {
  StyledModalActions,
  StyledIconClose,
  StyledModalTitle,
  Divider,
} from './ModalEditTier.style';

interface ModalEditTierProps {
  visible: boolean;
  onClose?: () => void;
  onEditTier?: () => void;
}

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

function ModalEditTier({visible, onClose, onEditTier}: ModalEditTierProps) {
  const [valueTier, setValueTier] = useState('');

  const onChange = (e: any) => {
    setValueTier(e.target.value);
  };

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="xs">
      <>
        <StyledModalTitle>Edit Tier</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <DialogContent sx={{marginLeft: '23px', marginRight: '23px'}}>
          <FormControl fullWidth margin="normal">
            <Select
              labelId="by-tier"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueTier}
              placeholder="Select by tier"
              size="small"
              selectOptions={[
                createData('Insider Tier', 'Insider Tier'),
                createData('Priority Tier', 'Priority Tier'),
                createData('All-Access Tier', 'All-Access Tier'),
              ]}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button variant="outlined" onClick={onClose} width="125px">
                  Deny
                </Button>
                <Button variant="contained" onClick={onEditTier} width="125px">
                  OK
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalEditTier;
