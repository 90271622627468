/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Typography} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {path} from '../../../routes/path';
import useAuth from '../../../hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';

// styles
import {Brand, Wrapper, Alert, TextField} from './SignUp.style';

function SignUp() {
  const navigate = useNavigate();
  const {signUp} = useAuth();
  // states
  const [isLoading] = useState(false);

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Get started
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Start creating the best possible user experience for you customers
        </Typography>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().max(255).required('First name is required'),
            lastName: Yup.string().max(255).required('Last name is required'),
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup.string()
              .min(12, 'Must be at least 12 characters')
              .max(255)
              .required('Required'),
            confirmPassword: Yup.string().when('password', {
              is: (val: any) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                'Both password need to be the same'
              ),
            }),
          })}
          onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
            try {
              signUp(
                values.email,
                values.password,
                values.firstName,
                values.lastName
              );
              navigate(`/${path.AUTH}/${path.SIGN_IN}`);
            } catch (error: any) {
              const message = error.message || 'Something went wrong';

              setStatus({success: false});
              setErrors({submit: message});
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="text"
                name="firstName"
                label="First name"
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="text"
                name="lastName"
                label="Last name"
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="email"
                name="email"
                label="Email address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm password"
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                loading={isLoading}
                loadingPosition="start"
              >
                Sign up
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
