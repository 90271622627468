import React from 'react';
import {CardContent, Grid, Stack} from '@mui/material';
import Button from '../Button/Button';
import {useNavigate} from 'react-router-dom';

// styles
import {Card} from './FooterDetail.style';

interface FooterDetailProps {
  isResetPassword?: boolean;
  isCashOut?: boolean;
  toggleModalConfirm: () => void;
  toggleModalConfirmDelete: () => void;
  onClickCashOut?: () => void;
  onClickTransactions?: () => void;
  onClickVoucherTransaction?: () => void;
  onClickVoucher?: () => void;
  onClickReferralTransactions?: () => void;
  disableArrays?: any;
  titleDelete?: string;
  loading?: boolean;
  isTransactions?: boolean;
  isVoucher?: boolean;
  isVoucherTransaction?: boolean;
  isPartnership?: boolean;
  isEdit?: boolean;
  canRead?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
  canCreate?: boolean;
  isDelete?: boolean;
}

function FooterDetail({
  toggleModalConfirm,
  toggleModalConfirmDelete,
  isResetPassword,
  isCashOut,
  onClickCashOut,
  titleDelete = 'Delete',
  loading,
  isTransactions,
  onClickTransactions,
  onClickVoucher,
  isVoucherTransaction,
  isVoucher,
  onClickVoucherTransaction,
  isPartnership,
  onClickReferralTransactions,
  isEdit,
  canRead,
  canDelete,
  canUpdate,
  canCreate,
  isDelete = true,
  disableArrays = [],
}: FooterDetailProps) {
  const navigate = useNavigate();

  return (
    <Grid container mt={10} className="no-print">
      <Grid item xs={12} lg={12}>
        <Card px={6} pt={3}>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={4}>
                {!canCreate && !canUpdate && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={loading}
                  >
                    {!isEdit ? 'Add new' : 'Save changes'}
                  </Button>
                )}
                {isResetPassword && !canUpdate && (
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={toggleModalConfirm}
                    loading={loading}
                  >
                    Reset password
                  </Button>
                )}
                {isCashOut && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={onClickCashOut}
                    loading={loading}
                  >
                    Reimbursement
                  </Button>
                )}
                {isTransactions && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={onClickTransactions}
                    loading={loading}
                  >
                    All Transactions
                  </Button>
                )}
                {isVoucherTransaction && !canRead && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={onClickVoucherTransaction}
                    loading={loading}
                  >
                    Voucher Transaction
                  </Button>
                )}
                {isVoucher && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={onClickVoucher}
                    loading={loading}
                  >
                    Voucher Transaction
                  </Button>
                )}
                {isPartnership && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={disableArrays.includes('referralTransactions')}
                    onClick={onClickReferralTransactions}
                    loading={loading}
                  >
                    Referral Transactions
                  </Button>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                {!isEdit && (
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                    loading={loading}
                  >
                    Cancel
                  </Button>
                )}
                {isDelete && isEdit && !canDelete && (
                  <Button
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={toggleModalConfirmDelete}
                    loading={loading}
                  >
                    {titleDelete}
                  </Button>
                )}
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default FooterDetail;
