import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {DialogActions, DialogTitle, Divider as MuiDivider} from '@mui/material';
const StyledIconClose = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
`;

const StyledModalActions = styled(DialogActions)`
  padding-bottom: 40px;
`;
const StyledModalTitle = styled(DialogTitle)`
  font-size: 24px;
  font-weight: 900;
  margin: 20px;
`;
const Divider = styled(MuiDivider)`
  ${spacing};
  margin: 10px 25px 12px 25px;
`;
export {StyledIconClose, StyledModalActions, StyledModalTitle, Divider};
