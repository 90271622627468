import styled from 'styled-components';

const StyledRichTextEditorWrapper = styled.div`
  display: block;
  margin-bottom: 30px;
`;

const StyledRichTextEditor = styled.div<{error: boolean}>`
  border: thin solid
    ${props => (props.error === true ? '#d32f2f' : 'lightgrey')};
  border-radius: 5px;
  padding-bottom: 20px;
  .MUIRichTextEditor-editor-4 {
    padding: 10px;
  }
  .MUIRichTextEditor-editor-18 {
    padding: 10px;
  }
  .MUIRichTextEditor-placeHolder-9 {
    padding: 10px;
  }
  .MUIRichTextEditor-placeHolder-37 {
    padding: 10px;
  }
  #mui-rte-media-control-button {
    display: none;
  }
  #mui-rte-Save-button {
    display: none;
  }
`;

const StyledErrorText = styled.p`
  color: #d32f2f;
  font-weight: 400;
  margin-left: 14px;
  font-size: 11px;
`;

export {StyledRichTextEditorWrapper, StyledRichTextEditor, StyledErrorText};
