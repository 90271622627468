/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// style
import {StyledDatePicker} from './DatePicker.style';

interface DatePickerProps {
  label: string;
  value?: any | Date | string | number;
  toolbarPlaceholder?: string;
  onChange?: (newValue: any) => void;
  onClose?: () => void;
  disabled?: boolean;
  loading?: boolean;
  maxDate?: any;
  minDate?: any;
  okText?: ReactNode;
  readOnly?: boolean;
  format?: string;
  mark?: string;
  openTo?: any;
  views?: any;
}

function DatePicker({
  label,
  value,
  toolbarPlaceholder,
  onChange,
  onClose,
  disabled,
  loading,
  maxDate,
  minDate,
  okText,
  readOnly,
  format = 'MM/dd/yyyy',
  mark = '____/__/__',
  openTo = 'day',
  views,
  ...other
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDatePicker
        label={label}
        value={value}
        toolbarPlaceholder={toolbarPlaceholder}
        onChange={onChange}
        onClose={onClose}
        disabled={disabled}
        loading={loading}
        mask={mark}
        maxDate={maxDate}
        minDate={minDate}
        okText={okText}
        openTo={openTo}
        views={views}
        readOnly={readOnly}
        inputFormat={format}
        renderInput={params => <TextField variant="standard" {...params} />}
        {...other}
      />
    </LocalizationProvider>
  );
}
export default DatePicker;
