import styled from 'styled-components/macro';
import {spacing, display} from '@mui/system';
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@mui/material';

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Typography = styled(MuiTypography)(display);

const StyledDivBreadcrumbs = styled.div`
  margin-top: 10px;
`;

export {Card, Divider, Shadow, Typography, StyledDivBreadcrumbs};
