import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Typography} from '@mui/material';
import {path} from '../../../routes/path';
import {USER_TYPE} from '../../../constants/UserType';

// styles
import {Button, Wrapper} from './Page404.style';
import {envRole} from '../../../utils';

function Page404() {
  const renderLink = () => {
    return +envRole === USER_TYPE.ADMINISTRATOR
      ? `/${path.ISLAND_MEMBERS}`
      : `/${path.ISLAND_PARTNERS}`;
  };

  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>

      <Button
        component={Link}
        to={renderLink()}
        variant="contained"
        color="secondary"
        mt={2}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page404;
