/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../constants/image';
import {Modal, Button, Input} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter,
}: ModalFilterProps) {
  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter?.((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'BY WATCHLIST TAG NAME'}
            </Typography>
            <Input
              type="text"
              name="name_contains"
              placeholder="Enter Tag Name"
              size="small"
              variant="outlined"
              value={valueFilter.name_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={!valueFilter.name_contains}
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
