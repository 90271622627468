/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Checkbox, FormControl, Grid} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {
  Modal,
  Button,
  Input,
  TextArea,
  RadioGroups,
  DateTimePicker,
} from '../../../../../components';
import useAuth from '../../../../../hooks/useAuth';
import success from '../../../../../constants/success';
import ReferralPartnershipService from '../../../../../services/ReferralProgramme/referral-partnership.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNewPartner.style';

const radioOptions = [
  {
    key: 'Standard',
    value: 1,
  },
  {
    key: 'Unique (Prefix)',
    value: 2,
  },
];

interface ModalAddNewProps {
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

interface DateTimeRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

function ModalAddNewPartner({visible, onClose, reloadPage}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  // states
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    point: 0,
    quantity: 1,
    expireStartDateTime: '',
    expireEndDateTime: '',
    codeType: 1,
    code: '',
    numberId: 0,
    referralCampaignMerchantId: '',
    updatedDateTime: '',
    createdDateTime: '',
  });

  const [disabledQty, setDisabledQty] = useState(false);

  const addNewPartner = values => {
    setIsLoading(true);
    if (
      valueRangePicker.startDate !== null &&
      valueRangePicker.endDate !== null
    ) {
      if (valueRangePicker.startDate >= valueRangePicker.endDate) {
        setIsLoading(false);
        showNotification({
          message: 'End Referral Period should be after Start Referral Period',
          variation: 'error',
        });
      } else {
        new ReferralPartnershipService()
          .create({
            ...values,
            expireStartDateTime: valueRangePicker.startDate?.toISOString(),
            expireEndDateTime: valueRangePicker.endDate?.toISOString(),
            quantity: +values.quantity,
            referralCampaignMerchantId: undefined,
            updatedDateTime: undefined,
            createdDateTime: undefined,
            numberId: undefined,
          })
          .then(res => {
            if (res && res?.success) {
              setIsLoading(false);
              showNotification({
                message: success.CREATE_SUCCESS,
              });
              onClose();
              reloadPage();
            } else {
              setIsLoading(false);
              showNotification({
                message: res.errorMessage,
                variation: 'error',
              });
            }
          });
      }
    }
  };

  useEffect(() => {
    setDisabledQty(initialValues.quantity === -1);
  }, [initialValues.quantity]);

  useEffect(() => {
    if (visible) {
      setValueRangePicker({startDate: null, endDate: null});
      setInitialValues({
        name: '',
        description: '',
        point: 0,
        quantity: 1,
        expireStartDateTime: '',
        expireEndDateTime: '',
        codeType: 1,
        code: '',
        numberId: 0,
        referralCampaignMerchantId: '',
        updatedDateTime: '',
        createdDateTime: '',
      });
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="sm">
      <>
        <StyledModalTitle>Add New Partner</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required('Referral Partner Name is required')
                .max(40),
              description: Yup.string()
                .required('Referral Description is required')
                .max(205),
              point: Yup.number()
                .integer()
                .max(
                  2000,
                  'Maximum referral points reward must be less than equal to 2000'
                )
                .min(
                  0,
                  'Minimum referral points reward must be equal or greater than 0'
                ),
              quantity: Yup.number()
                .integer()
                .min(
                  initialValues.quantity === -1 ? -1 : 1,
                  'Quantity must be between 1 and 9999, both values inclusive'
                )
                .max(
                  initialValues.quantity === -1 ? -1 : 9999,
                  'Quantity must be between 1 and 9999, both values inclusive'
                ),
              code: Yup.string()
                .required('Referral Code is required')
                .matches(
                  initialValues.codeType === 1
                    ? /^[A-NP-Z0-9]{3,10}$/
                    : /^[A-NP-Z0-9]{3,8}$/,
                  initialValues.codeType === 1
                    ? 'Code should only contain uppercased alphanumeric excluding character "O", and min of 3 and max of 10 characters'
                    : 'Code should only contain uppercased alphanumeric excluding character "O", and min of 3 and max of 8 characters'
                )
                .max(
                  initialValues.codeType === 1 ? 10 : 8,
                  `Referral code must be at most ${
                    initialValues.codeType === 1 ? 10 : 8
                  } characters`
                )
                .min(3, 'Referral code must be at least 3 characters'),
            })}
            onSubmit={addNewPartner}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Referral Partner Name (40 Max Char.){' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Enter Referral Partner Name"
                        size="small"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Referral Description (250 Max Char.){' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <TextArea
                        label=""
                        placeholder="Enter Referral Description"
                        maxRows={3}
                        name="description"
                        value={values.description}
                        helperText={touched.description && errors.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Referral Points Reward
                      </Typography>
                      <Input
                        placeholder="Enter Points Reward"
                        size="small"
                        name="point"
                        type="number"
                        value={values.point}
                        variant="outlined"
                        helperText={touched.point && errors.point}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.point && errors.point)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth margin="dense">
                      <Typography variant="body2" mb={2}>
                        Referral Quantity
                      </Typography>
                      <Input
                        placeholder="Enter Referral Quantity"
                        size="small"
                        name="quantity"
                        type="string"
                        value={values.quantity === -1 ? '∞' : values.quantity}
                        variant="outlined"
                        helperText={touched.quantity && errors.quantity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.quantity && errors.quantity)}
                        disabled={disabledQty}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth margin="dense">
                      <div>
                        <Typography variant="inherit">
                          Unlimited Quantity
                        </Typography>
                        <Checkbox
                          sx={{
                            paddingLeft: 0,
                          }}
                          checked={values.quantity === -1}
                          value={values.quantity}
                          onChange={e => {
                            handleChange(e);
                            setInitialValues({
                              ...values,
                              quantity: e.target.checked ? -1 : 0,
                            });
                          }}
                          inputProps={{'aria-label': 'controlled'}}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <Typography variant="body2" mb={2}>
                        Referral Code{' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <Input
                        name="code"
                        placeholder="Enter Preferral Code"
                        size="small"
                        value={values.code}
                        variant="outlined"
                        helperText={touched.code && errors.code}
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          setInitialValues({
                            ...values,
                            code: e.target.value.toUpperCase(),
                          });
                        }}
                        error={Boolean(touched.code && errors.code)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Code Type
                      </Typography>
                      <RadioGroups
                        name="codeType"
                        options={radioOptions}
                        values={values.codeType}
                        onChange={e => {
                          handleChange(e);
                          setInitialValues({
                            ...values,
                            codeType: +e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        Start Referral Period{' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <DateTimePicker
                        required
                        isEmptyValue={valueRangePicker.startDate === null}
                        value={valueRangePicker.startDate}
                        helperText="Start Referral Period is required"
                        minDate={new Date()}
                        onChange={e => {
                          if (e >= valueRangePicker.endDate) {
                            setValueRangePicker({
                              ...valueRangePicker,
                              startDate: e,
                              endDate: null,
                            });
                          } else {
                            setValueRangePicker({
                              ...valueRangePicker,
                              startDate: e,
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl variant="standard" fullWidth>
                      <Typography variant="body2" mb={2} mt={3}>
                        End Referral Period{' '}
                        <span className="mandatory-label">(*)</span>
                      </Typography>
                      <DateTimePicker
                        disabled={valueRangePicker.startDate === null}
                        required
                        isEmptyValue={valueRangePicker.endDate === null}
                        value={valueRangePicker.endDate}
                        helperText="End Referral Period is required"
                        minDate={valueRangePicker.startDate ?? new Date()}
                        onChange={e => {
                          setValueRangePicker({
                            ...valueRangePicker,
                            endDate: e,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider variant="middle" pt={8} />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      Add Partnership
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNewPartner;
