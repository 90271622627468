/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Input from '../Input/Input';
import Checkbox from '../Checkbox/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// style
import {StyleAutoCompleteCheckBox} from './MultipleSelectCheckbox.style';

interface AutoCompleteOptionsProps {
  title: string;
  value: number;
}

interface AutoCompleteProps {
  id?: string;
  options?: Array<AutoCompleteOptionsProps>;
  placeholder?: string;
  onChange?: (event: any, value: any, reason: any, detail: any) => void;
  value?: any;
  disabled?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultipleSelectCheckbox({
  id,
  options,
  placeholder,
  onChange,
  value,
  disabled = false,
  ...other
}: AutoCompleteProps) {
  return (
    <StyleAutoCompleteCheckBox
      id={id}
      multiple
      onChange={onChange}
      getOptionLabel={option => option.title}
      disableCloseOnSelect
      value={value}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      size="small"
      options={options}
      disabled={disabled}
      renderInput={params => (
        <Input variant="outlined" placeholder={placeholder} {...params} />
      )}
      {...other}
    />
  );
}
export default MultipleSelectCheckbox;
