import React from 'react';
import {NavLink} from 'react-router-dom';
import {Grid, List} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {SidebarItemsType} from '../../types/typeSidebar';
import useAuth from '../../hooks/useAuth';
import SidebarNavList from './SidebarNavList';

// style
import {
  Box,
  Drawer,
  Brand,
  Footer,
  FooterText,
  FooterSubText,
  Scrollbar,
  PerfectScrollbar,
  Items,
  Title,
  StyledImage,
} from './Sidebar.style';

import '../../vendor/perfect-scrollbar.css';
import {images} from '../../constants/image';
import {appVersion} from '../../config';
import {path} from '../../routes/path';
import DashboardNavRoutes from '../../routes/DashboardNavRoutes';
import SidebarNavListItem from './SidebarNavListItem';

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

type SidebarNavSectionProps = {
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  const {getHomeUrl} = useAuth();

  //
  // Sidebar footer
  const SidebarFooter: React.FC = ({...rest}) => {
    const {user} = useAuth();
    return (
      <Footer {...rest}>
        <Grid container spacing={2}>
          <Grid item>
            {!!user && (
              <FooterText variant="body2">{user.displayName}</FooterText>
            )}

            <FooterText variant="body2">
              {user?.data?.firstName + ' ' + user?.data?.lastName}
            </FooterText>

            <FooterSubText variant="caption">{user?.role}</FooterSubText>
            {/* <FooterSubText variant="caption">{appVersion}</FooterSubText> */}
            <SidebarNavListItem
              depth={0}
              href={`/${path.CHANGE_LOG}`}
              icon={null}
              key={appVersion}
              title={`${appVersion} - Changelog`}
            />
          </Grid>
        </Grid>
      </Footer>
    );
  };

  //SidebarNav
  const SidebarNav: React.FC<SidebarNavProps> = ({items}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const ScrollbarComponent = (
      matches ? PerfectScrollbar : Scrollbar
    ) as React.ElementType;
    return (
      <ScrollbarComponent>
        <List disablePadding>
          <Items>
            {items &&
              items.map(item => (
                <SidebarNavSection
                  component="div"
                  key={item.title}
                  pages={item.pages}
                  title={item.title}
                />
              ))}
          </Items>
        </List>
      </ScrollbarComponent>
    );
  };

  // SidebarNavSection
  const SidebarNavSection: React.FC<SidebarNavSectionProps> = props => {
    const {title, pages, component: Component = 'nav', ...rest} = props;

    return (
      <Component {...rest}>
        {title && <Title variant="subtitle2">{title}</Title>}
        <SidebarNavList pages={pages} depth={0} />
      </Component>
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to={getHomeUrl()}>
        <Box ml={1}>
          <StyledImage>
            <img src={images.logo_sidebar} />
          </StyledImage>
        </Box>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <SidebarFooter />}
    </Drawer>
  );
};

export default Sidebar;
