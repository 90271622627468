/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {Modal, Button, Input} from '../../../../components';
import IslanderService from '../../../../services/Islander/islander.service';
import {Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';

interface ModalAddNewProps {
  visible: boolean;
  onClose?: () => void;
  reloadPage?: () => void;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({visible, onClose, reloadPage}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  // states
  const [initialValues] = useState({
    name: '',
    contactName: '',
    contactEmail: '',
    additionalEmail: '',
    qrExpiredDate: qrExpiredDate.toISOString(),
    refreshedDate: refreshedDate.toISOString(),
  });
  // const [valueFile, setValueFile] = useState();

  // const onChangeUploadFile = (event: any) => {
  //   setValueFile(event.target.files[0]);
  // };

  const onAddNew = values => {
    new IslanderService()
      .create({...values, contactName: values.contactName})
      .then(res => {
        if (res && res?.success) {
          onClose();
          reloadPage();
          showNotification({
            message: success.CREATE_SUCCESS,
          });
        } else {
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Add New Island Partners</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              contactEmail: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              additionalEmail: Yup.string()
                .email('Must be a valid email')
                .max(255),
              name: Yup.string().required('Island Partner Name is required'),
              contactName: Yup.string().required('Contact Name is required'),
            })}
            onSubmit={onAddNew}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Island Partner Name{' '}
                    <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Island Partner Name"
                    size="small"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Contact Name <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="contactName"
                    placeholder="Enter Contact Name"
                    size="small"
                    variant="outlined"
                    value={values.contactName}
                    onChange={handleChange}
                    helperText={touched.contactName && errors.contactName}
                    onBlur={handleBlur}
                    error={Boolean(touched.contactName && errors.contactName)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Email <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="email"
                    name="contactEmail"
                    placeholder="Enter email"
                    size="small"
                    variant="outlined"
                    value={values.contactEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.contactEmail && errors.contactEmail}
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Additional Email (Optional)
                  </Typography>
                  <Input
                    type="email"
                    name="additionalEmail"
                    placeholder="Enter additional email"
                    size="small"
                    variant="outlined"
                    value={values.additionalEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.additionalEmail && errors.additionalEmail
                    }
                    error={Boolean(
                      touched.additionalEmail && errors.additionalEmail
                    )}
                  />
                </FormControl>
                {/* <FormControl
                  fullWidth
                  sx={{
                    marginBottom: '20px',
                  }}
                >
                  <UploadFile
                    label="Image"
                    // setValueFile={setValueFile}
                    valueFile={valueFile}
                    onChange={onChangeUploadFile}
                  />
                </FormControl> */}
                <Divider variant="middle" />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button variant="text" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit">Add New</Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
