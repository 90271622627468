import styled from 'styled-components/macro';
import {Dialog} from '@mui/material';

const StyledModal = styled(Dialog)`
  border-radius: 10px;
  .MuiDialogContent-root {
    padding-top: 0px;
  }
`;

export {StyledModal};
