import styled from 'styled-components/macro';

export const ValidatorWrapperMain = styled.div`
  padding: 0 ${props => props.theme.spacing(2.5)}
    ${props => props.theme.spacing(5)};
`;

export const ValidatorWrapperContent = styled.div`
  padding: 0 ${props => props.theme.spacing(2.5)};
`;
