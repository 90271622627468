export type ColumnsType = {
  dataIndex: string;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
  disabled?: boolean;
  subText?: string;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  ticketTitle: string;
  ticketPoint: number;
  membershipEmail: string;
  merchantName: string;
  redeemedFrom: number;
};

export type DataDetail = {
  id?: number;
  membershipId?: string;
  membershipEmail?: string;
  pointId?: number | null;
  ticketId?: string;
  ticketTitle?: string;
  ticketPoint?: number;
  merchantName?: string;
  createdDateTime?: string;
  updatedDateTime?: string;
  createdBy?: number;
  updatedBy?: string;
  status?: number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean,
  state?: object
) {
  return {name, path, isCurrentPage, state};
}
