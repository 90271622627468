/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Delete, Edit} from '@mui/icons-material';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeIslandersMembers';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';

import {
  Breadcrumbs,
  Table,
  Tooltips,
  Button,
  ModalConfirm,
  Chip,
} from '../../components';
import useAuth from '../../hooks/useAuth';
import queryString from 'query-string';
import TagsService from '../../services/Tags/tags.service';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import {ColorType} from '../../types/typeChip';
import ModalFilter from './components/Modalfilter/ModalFilter';
import {formatLocalDateFromUTCTime} from '../../utils';

// style
import {
  Typography,
  Divider,
  StyledTableRow,
  StyledAction,
  StyledIcon,
  StyledStack,
} from './WatchlistTags.style';

function WatchlistTags() {
  const {showNotification} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const [order] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy] = React.useState('');
  const [dense] = React.useState(false);
  const [focusId, setFocusId] = useState(null);

  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [valueFilter, setValueFilter] = useState({
    name_contains: searchParams.get('name_contains') ?? '',
  });

  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
    name_contains: searchParamsObject.name_contains ?? undefined,
  });

  const [isModalFilter, setIsModalFilter] = useState(false);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [selectedTag, setSelectedTag] = useState<object>(null);

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      dataIndex: 'referralCampaigns',
      numeric: false,
      disablePadding: false,
      label: 'Referral Campaigns',
    },
    {
      dataIndex: 'createdDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Created Date Time',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderAction = (value: object) => {
    if (focusId === value.id) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                setSelectedTag(value);
                toggleModalAddNew();
              }}
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
          <Tooltips title="Delete">
            <IconButton
              aria-label="details"
              size="medium"
              onClick={() => {
                setSelectedTag(value);
                toggleModalConfirmDelete();
              }}
            >
              <Delete color="error" style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const getWatchlistTags = () => {
    setIsLoading(true);
    new TagsService().getAll(params).then(res => {
      if (Array.isArray(res?.data?.items)) {
        setDataSource(res?.data?.items);
        setTotalPage(res?.data?.total);
        setIsLoading(false);
      }
    });
  };

  const deleteTag = () => {
    setIsLoading(true);
    new TagsService().delete(selectedTag.id).then(res => {
      if (res && res?.success) {
        showNotification({
          message: 'Tag has been deleted',
        });
        toggleModalConfirmDelete();
        getWatchlistTags();
      } else {
        showNotification({
          message: res.errorMessage,
          variation: 'error',
        });
      }
    });
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const toggleModalAddNew = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const toggleModalConfirmDelete = () => {
    setIsModalConfirmDelete(!isModalConfirmDelete);
  };

  const handleFilter = () => {
    if (valueFilter?.name_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        name_contains: valueFilter?.name_contains,
      }));
    }
    toggleModalFilter();
  };

  const handleClean = () => {
    setValueFilter({name_contains: ''});
    setParams(preState => ({
      ...preState,
      _page: 0,
      name_contains: undefined,
    }));
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'name_contains') {
      deleteSearchParams('name_contains');
      setValueFilter({
        ...valueFilter,
        name_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        name_contains: undefined,
      }));
    }
  };

  const renderDate = values => {
    if (values) {
      return (
        <>
          <Typography variant="inherit">
            {formatLocalDateFromUTCTime(values, 'DD/MM/YYYY HH:mm A')}
          </Typography>
        </>
      );
    }
  };

  const renderCampaigns = values => {
    return values.map(e => <Typography variant="inherit">{e.name}</Typography>);
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    getWatchlistTags();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Watchlist Tags" />

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
      />

      <ModalAddNew
        visible={isModalAddNew}
        tag={selectedTag}
        onClose={toggleModalAddNew}
        reloadPage={getWatchlistTags}
      />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">
            Are you sure you want to delete tag?
          </Typography>
        }
        // subTitle={<Typography>Voucher will not be able to login.</Typography>}
        onClose={toggleModalConfirmDelete}
        onConfirm={deleteTag}
        isLoading={isLoading}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Watchlist Tags
          </Typography>

          <Breadcrumbs
            routes={[typeRoutes('Watchlist Tags', undefined, true)]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('name_contains') !== null && (
              <Chip
                label={searchParams.get('name_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('name_contains');
                }}
              />
            )}
          </StyledStack>
          <Button
            onClick={() => {
              setSelectedTag(null);
              toggleModalAddNew();
            }}
            loading={isLoading}
          >
            Add new Watchlist Tags
          </Button>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        isLoading={isLoading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        order={order}
        orderBy={orderBy}
        textNodata="There are no islander member(s) matching the filter."
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
      >
        <TableBody>
          {dataSource?.map(row => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onMouseEnter={() => setFocusId(row.id)}
                onMouseLeave={() => setFocusId(null)}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {renderCampaigns(row.referralCampaigns)}
                </TableCell>
                <TableCell align="left">
                  {renderDate(row.createdDateTime)}
                </TableCell>
                <TableCell
                  align="right"
                  padding="checkbox"
                  style={{paddingRight: '10px'}}
                >
                  {renderAction(row)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default WatchlistTags;
