import * as React from 'react';
import {Grid, List} from '@mui/material';

// styles
import {Wrapper, ListItemText} from './Footer.style';

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0} justifyContent={'center'}>
        <Grid container item xs={12} md={24} justifyContent={'flex-end'}>
          <List>
            <ListItemText primary={'Sentosa Development Corporation'} />
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
