/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';

// style
import {StyledButton} from './Button.style';

interface ButtonProps {
  variant?: 'contained' | 'text' | 'outlined';
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  width?: string;
  margin?: string;
  loading?: boolean;
}

function Button({
  variant = 'contained',
  color = 'primary',
  type = 'submit',
  onClick,
  children,
  fullWidth,
  startIcon,
  endIcon,
  size = 'medium',
  width,
  loading,
  margin,
  ...other
}: ButtonProps) {
  return (
    <StyledButton
      variant={variant}
      color={color}
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      loading={loading}
      sx={{width: width, margin: margin}}
      {...other}
      className="no-print"
    >
      {children}
    </StyledButton>
  );
}
export default Button;
