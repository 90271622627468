export const TRANSACTION_TYPE_EARN_POINTS = 'earn-points';
export const TRANSACTION_TYPE_USE_VOUCHER = 'redeem-voucher';
export const TRANSACTION_TYPE_GET_VOUCHER = 'get-voucher';
export const TRANSACTION_TYPE_LIST = {
  [TRANSACTION_TYPE_EARN_POINTS]: 'Earn Points',
  [TRANSACTION_TYPE_USE_VOUCHER]: 'Use Voucher',
  [TRANSACTION_TYPE_GET_VOUCHER]: 'Get Voucher',
};

export const TRANSACTION_FILTER_TYPE_LIST = {
  ['earnpoints']: 'Earn Points',
  ['usevoucher']: 'Use Voucher',
  ['getvoucher']: 'Get Voucher',
  ['earnbonuspoints']: 'Earn Bonus Points',
};
