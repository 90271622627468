/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Edit} from '@mui/icons-material';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, TableCell, TableBody, IconButton} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import {DateRange} from '@mui/lab/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {POINT_SUCCESS, POINT_SUCCESS_COLOR} from '../../../constants/status';

import {ColorType} from '../../../types/typeChip';
import {Breadcrumbs, Chip, Table, Tooltips, Button} from '../../../components';
import {path} from '../../../routes/path';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../../types/typeReferralTransactions';
import ModalFilter from './components/ModalFilter/ModalFilter';
import {
  envRole,
  formatDateTime,
  getEndOfDate,
  getStartOfDate,
  hasPermission,
} from '../../../utils';
import useAuth from '../../../hooks/useAuth';
import {USER_TYPE} from '../../../constants/UserType';
import {formatLocalDateFromUTCTime} from '../../../utils';
import queryString from 'query-string';
import ReferralTransactionService from '../../../services/ReferralProgramme/referral-transaction.service';
import ExportExcelService from '../../../services/ExportExcel/ExportExcel';
import success from '../../../constants/success';

// Style
import {
  Divider,
  StyledTableRow,
  Typography,
  StyledStack,
  StyledAction,
  StyledIcon,
} from './ReferralTransactions.style';

function ReferralTransactions() {
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const membershipId = locationUrl?.state?.membershipId;
  const merchantID = locationUrl?.state?.merchantId;

  const format = 'DD/MM/YYYY';
  const formatDate = 'DD/MM/YYYY HH:mm';
  const {getUser, showNotification} = Object.assign(useAuth());
  const user = getUser() || {};
  const {merchantId, email} = user;
  const userType = +envRole;

  const role =
    +envRole === USER_TYPE.ADMINISTRATOR ? 'administrator' : 'merchant';
  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [optionByType, setOptionByType] = useState([]);
  const [nameContainsList, setNameContainsList] = useState([]);
  const [valueFilter, setValueFilter] = useState({
    referralType_eq: searchParams.get('referralType_eq') ?? '',
    referralCampaignNumberId_eq:
      searchParams.get('referralCampaignNumberId_eq') ?? '',
    accountId_eq: searchParams.get('accountId_eq') ?? '',
    processId_eq: searchParams.get('processId_eq') ?? '',
    referralCodeUsed_eq: searchParams.get('referralCodeUsed_eq') ?? '',
    createdDateTime_lte: searchParams.get('createdDateTime_lte') ?? '',
    createdDateTime_gte: searchParams.get('createdDateTime_gte') ?? '',
  });
  const [valueRangePicker, setValueRangePicker] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const searchParamsObject = queryString.parse(searchParams.toString());
  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    _sort: 'createdDateTime:desc',
    status_eq: searchParamsObject.status_eq ?? 1,
    referralCampaignNumberId_eq:
      searchParamsObject.referralCampaignNumberId_eq ?? undefined,
    referralCodeUsed_eq: searchParamsObject.referralCodeUsed_eq ?? undefined,
    processId_eq: searchParamsObject.processId_eq ?? undefined,
    accountId_eq: searchParamsObject.accountId_eq ?? undefined,
    expireDateTime_lt: searchParamsObject.expireDateTime_lt ?? undefined,
  });

  const PARTNERSHIP = '1';
  const ISLANDER_MEMBER = '2';

  const referralType_eq_LIST = {
    [PARTNERSHIP]: 'Partnership',
    [ISLANDER_MEMBER]: 'Islander Member',
  };

  const COMPLETE = '1';
  const PENDING = '2';
  const EXPIRED = '6';
  const DECLINED = '5';

  const processId_eq_LIST = {
    [COMPLETE]: 'Complete',
    [PENDING]: 'Pending',
    [EXPIRED]: 'Expired',
    [DECLINED]: 'Declined',
  };

  const columns: Array<ColumnsType> = [
    {
      dataIndex: 'referralCreationId',
      numeric: false,
      disablePadding: false,
      label: 'Referral Creation ID',
    },
    {
      dataIndex: 'referralDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Referral Date Time',
    },
    {
      dataIndex: 'referralExpiryDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Referral Expiry Date Time',
    },
    {
      dataIndex: 'referralType',
      numeric: false,
      disablePadding: false,
      label: 'Referral Type',
    },
    {
      dataIndex: 'refereeAccountId',
      numeric: false,
      disablePadding: false,
      label: 'Referee Account ID',
    },
    {
      dataIndex: 'referralCodeUsed',
      numeric: false,
      disablePadding: false,
      label: 'Referral Code Used',
    },
    {
      dataIndex: 'referrerTransactionStatus',
      numeric: false,
      disablePadding: false,
      label: 'Referrer Transaction Status',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportReferralCampaignTransactions({
        ...params,
        email: email,
        processId_eq:
          Number(params?.processId_eq) === POINT_SUCCESS.EXPIRED
            ? undefined
            : params.processId_eq,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res && res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const onClickIconButton = idTransaction => {
    return navigate(
      `/${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}/${idTransaction}`
    );
  };

  const renderStatus = (value: any) => {
    let label = processId_eq_LIST[value.processId];
    let color = POINT_SUCCESS_COLOR[value.processId];
    const isExpired = new Date(value.expiryDateTime) < new Date();
    if (value.processId === 1 && !isExpired) {
      label = processId_eq_LIST[value.processId];
      color = POINT_SUCCESS_COLOR[value.processId];
    } else if (value.processId === 2 && !isExpired) {
      label = processId_eq_LIST[value.processId];
      color = POINT_SUCCESS_COLOR[value.processId];
    } else if (value.processId === 6 && !isExpired) {
      label = processId_eq_LIST[value.processId];
      color = POINT_SUCCESS_COLOR[value.processId];
    } else if (value.status === 2 && isExpired) {
      label = processId_eq_LIST[EXPIRED];
      color = POINT_SUCCESS_COLOR[POINT_SUCCESS.FAILED];
    }
    return <Chip label={label} color={color as ColorType} />;
  };

  const renderAction = (value: string | number) => {
    if (!canUpdate) {
      if (focusId === value) {
        return (
          <StyledAction>
            <Tooltips title="View detail">
              <IconButton
                aria-label="details"
                size="medium"
                onClick={() => {
                  onClickIconButton(value);
                }}
              >
                <VisibilityIcon style={StyledIcon} />
              </IconButton>
            </Tooltips>
          </StyledAction>
        );
      }
    }
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              onClick={() => {
                onClickIconButton(value);
              }}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'date') {
      deleteSearchParams('createdDateTime_lte');
      deleteSearchParams('createdDateTime_gte');
      setValueRangePicker([null, null]);
      setValueFilter({
        ...valueFilter,
        createdDateTime_lte: '',
        createdDateTime_gte: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        createdDateTime_lte: undefined,
        createdDateTime_gte: undefined,
      }));
    }

    if (type === 'referralType_eq') {
      deleteSearchParams('referralType_eq');
      deleteSearchParams('referralType_label');
      setValueFilter({
        ...valueFilter,
        referralType_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        referralType_eq: undefined,
      }));
    }

    if (type === 'referralCampaignNumberId_eq') {
      deleteSearchParams('referralCampaignNumberId_eq');
      setValueFilter({
        ...valueFilter,
        referralCampaignNumberId_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        referralCampaignNumberId_eq: undefined,
      }));
    }

    if (type === 'referralCodeUsed_eq') {
      deleteSearchParams('referralCodeUsed_eq');
      setValueFilter({
        ...valueFilter,
        referralCodeUsed_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        referralCodeUsed_eq: undefined,
      }));
    }

    if (type === 'accountId_eq') {
      setValueFilter({
        ...valueFilter,
        accountId_eq: '',
      });
      deleteSearchParams('accountId_eq');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        accountId_eq: undefined,
      }));
    }

    if (type === 'processId_eq') {
      setValueFilter({
        ...valueFilter,
        processId_eq: '',
      });
      deleteSearchParams('processId_eq');
      deleteSearchParams('processId_label');
      setParams(preState => ({
        ...preState,
        _page: 0,
        _size: 10,
        processId_eq: undefined,
        status_eq: undefined,
        expireDateTime_lt: undefined,
      }));
    }
  };

  const toggleModal = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleClean = () => {
    setValueRangePicker([null, null]);
    setValueFilter({
      referralType_eq: '',
      referralCampaignNumberId_eq: '',
      accountId_eq: '',
      createdDateTime_lte: '',
      createdDateTime_gte: '',
      processId_eq: '',
      referralCodeUsed_eq: '',
    });
  };

  const handleFilter = () => {
    if (valueFilter?.processId_eq) {
      if (Number(valueFilter?.processId_eq) === POINT_SUCCESS.EXPIRED) {
        setParams(preState => ({
          ...preState,
          _page: 0,
          processId_eq: undefined,
          status_eq: 2,
          expireDateTime_lt: formatDateTime(new Date(), formatDate),
        }));
      } else {
        setParams(preState => ({
          ...preState,
          _page: 0,
          processId_eq: valueFilter?.processId_eq,
          status_eq: 1,
          expireDateTime_lt: undefined,
        }));
      }
    }

    if (valueFilter?.referralType_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        referralType_eq: valueFilter?.referralType_eq,
      }));
    }

    if (valueRangePicker[0] && valueRangePicker[1]) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: getEndOfDate(valueRangePicker[1], format),
        createdDateTime_gte: getStartOfDate(valueRangePicker[0], format),
      }));
    } else if (
      valueFilter?.createdDateTime_lte !== '' &&
      valueFilter?.createdDateTime_gte !== ''
    ) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        createdDateTime_lte: valueFilter?.createdDateTime_lte,
        createdDateTime_gte: valueFilter?.createdDateTime_gte,
      }));
    }

    if (valueFilter?.referralCampaignNumberId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        referralCampaignNumberId_eq: valueFilter?.referralCampaignNumberId_eq,
      }));
    }

    if (valueFilter?.accountId_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        accountId_eq: valueFilter?.accountId_eq,
      }));
    } else {
      handleDeleteChip('accountId_eq');
    }

    if (valueFilter?.referralCodeUsed_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        referralCodeUsed_eq: valueFilter?.referralCodeUsed_eq,
      }));
    } else {
      handleDeleteChip('referralCodeUsed_eq');
    }

    toggleModal();
  };

  const getListTransaction = () => {
    setIsLoading(true);
    if (role) {
      new ReferralTransactionService()
        .getAll({
          ...params,
          processId_eq:
            Number(params?.processId_eq) === POINT_SUCCESS.EXPIRED
              ? undefined
              : params.processId_eq,
        })
        .then(res => {
          if (res?.data && Array.isArray(res.data.items)) {
            setDataSource(res.data.items);
            setTotalPage(res?.data?.total);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          // Get api error => show notification or no items listing
          setError(error);
        });
    }
  };

  const renderAccountIdEq = () => {
    if (
      nameContainsList.length === 0 &&
      searchParams.get('accountId_eq') !== null
    ) {
      setSearchParams(
        queryString.stringify({
          ...params,
          referralType_label: referralType_eq_LIST[valueFilter.referralType_eq],
          processId_label: processId_eq_LIST[valueFilter.processId_eq],
          accountId_eq: searchParams.get('accountId_eq'),
        })
      );
      return searchParams.get('accountId_eq');
    }
    return;
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
        referralType_label: referralType_eq_LIST[valueFilter.referralType_eq],
        processId_label: processId_eq_LIST[valueFilter.processId_eq],
      })
    );

    renderAccountIdEq();
    getListTransaction();
  }, [params]);

  return (
    <React.Fragment>
      <Helmet title="Referral Transactions" />

      <ModalFilter
        referralTypeList={referralType_eq_LIST}
        processIdList={processId_eq_LIST}
        visible={isModalFilter}
        onClose={toggleModal}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        valueRangePicker={valueRangePicker}
        setValueFilter={setValueFilter}
        setValueRangePicker={setValueRangePicker}
        setOptionByType={setOptionByType}
        optionByType={optionByType}
        nameContainsList={nameContainsList}
        setNameContainsList={setNameContainsList}
      />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Referral Programme
          </Typography>
          <Breadcrumbs
            routes={[typeRoutes('Referral Transactions', undefined, true)]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('createdDateTime_lte') !== null &&
              searchParams.get('createdDateTime_gte') !== null && (
                <Chip
                  label={`${formatDateTime(
                    searchParams.get('createdDateTime_gte'),
                    format
                  )} -
                    ${formatDateTime(
                      searchParams.get('createdDateTime_lte'),
                      format
                    )}`}
                  color={'default' as ColorType}
                  onDelete={() => {
                    handleDeleteChip('date');
                  }}
                />
              )}

            {searchParams.get('referralType_label') !== null && (
              <Chip
                label={searchParams.get('referralType_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('referralType_eq');
                }}
              />
            )}

            {searchParams.get('referralCampaignNumberId_eq') !== null && (
              <Chip
                label={searchParams.get('referralCampaignNumberId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('referralCampaignNumberId_eq');
                }}
              />
            )}

            {searchParams.get('accountId_eq') !== null && (
              <Chip
                label={searchParams.get('accountId_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('accountId_eq');
                }}
              />
            )}

            {searchParams.get('processId_label') !== null && (
              <Chip
                label={searchParams.get('processId_label')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('processId_eq');
                }}
              />
            )}

            {searchParams.get('referralCodeUsed_eq') !== null && (
              <Chip
                label={searchParams.get('referralCodeUsed_eq')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('referralCodeUsed_eq');
                }}
              />
            )}
          </StyledStack>
          <Tooltips title="Filter list">
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModal}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no all transaction(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={index}
                  tabIndex={-1}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">
                    {row.referralCampaignNumberId}
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(row.createdDateTime, format)}
                  </TableCell>
                  <TableCell align="left">
                    {formatLocalDateFromUTCTime(row.expiryDateTime, format)}
                  </TableCell>
                  <TableCell align="left">
                    {row.referralType === 1 ? 'Partnership' : 'Islander Member'}
                  </TableCell>
                  <TableCell align="left">{row.accountId}</TableCell>
                  <TableCell align="left">{row.referralCode}</TableCell>
                  <TableCell align="left">{renderStatus(row)}</TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default ReferralTransactions;
