/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Helmet} from 'react-helmet-async';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  Link,
} from '@mui/material';

import AllModal from './PartnershipDetail.method';
import {path} from '../../../../routes/path';
import {images} from '../../../../constants/image';
import useAuth from '../../../../hooks/useAuth';
import success from '../../../../constants/success';
import {DataDetail, typeRoutes} from '../../../../types/typePartnership';
import {
  Breadcrumbs,
  Input,
  TextArea,
  FooterDetail,
  RadioGroups,
  Stats,
  DateTimePicker,
} from '../../../../components';
import queryString from 'query-string';
import ReferralPartnershipService from '../../../../services/ReferralProgramme/referral-partnership.service';
import ModalCodeList from '../components/ModalCodeList/ModalCodeList';

// style
import {
  Card,
  Divider,
  Shadow,
  Typography,
  StyledDivBreadcrumbs,
} from './PartnershipDetail.style';
import ExportExcelService from '../../../../services/ExportExcel/ExportExcel';

const radioOptions = [
  {
    key: 'Standard',
    value: 1,
  },
  {
    key: 'Unique (Prefix)',
    value: 2,
  },
];

interface DateTimeRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

let defaultQuantity = 0;
const currentDate = new Date();

function PartnershipDetail() {
  const paramsUrl = useParams();
  const location = useLocation();
  const idDetail = paramsUrl?.id;
  const {getUser, showNotification} = Object.assign(useAuth());
  const {email} = getUser() || {};
  const navigate = useNavigate();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    isModalConfirm: false,
    isModalConfirmDelete: false,
    isModalSuccess: false,
    isModalSuccessDelete: false,
    isModalEditTier: false,
    isModalEditPoints: false,
  });

  const [valueRangePicker, setValueRangePicker] = useState<DateTimeRange>({
    startDate: null,
    endDate: null,
  });

  const [initialValues, setInitialValues] = useState<DataDetail>({
    id: '',
    name: '',
    description: '',
    point: 0,
    quantity: 0,
    usedQuantity: 0,
    expireStartDateTime: '',
    expireEndDateTime: '',
    codeType: 0,
    code: '',
    numberId: 0,
    referralCampaignMerchantId: '',
    updatedDateTime: '',
    createdDateTime: '',
  });

  const [disabledQty, setDisabledQty] = useState(false);
  const [disabledUnlimitedQty, setDisabledUnlimitedQty] = useState(false);
  const [codeListVisible, setCodeListVisible] = useState(false);
  const [showGeneratedCodes, setShowGeneratedCodes] = useState(false);

  const onUpdate = values => {
    if (
      valueRangePicker.startDate !== null &&
      valueRangePicker.endDate !== null
    ) {
      if (valueRangePicker.startDate >= valueRangePicker.endDate) {
        showNotification({
          message: 'End Referral Period should be after Start Referral Perod',
          variation: 'error',
        });
      } else {
        new ReferralPartnershipService()
          .update(idDetail, {
            ...values,
            id: undefined,
            numberId: undefined,
            updatedDateTime: undefined,
            createdDateTime: undefined,
            usedQuantity: undefined,
            quantity: +values.quantity,
            expireStartDateTime: valueRangePicker.startDate?.toISOString(),
            expireEndDateTime: valueRangePicker.endDate?.toISOString(),
          })
          .then(res => {
            if (res && res?.success) {
              getDataDetail();
              setIsLoading(false);
              showNotification({
                message: success.UPDATE_SUCCESS,
              });
            } else {
              setIsLoading(false);
              showNotification({
                message: res.errorMessage,
                variation: 'error',
              });
            }
          });
      }
    }
  };

  const getCodeExport = () => {
    new ExportExcelService()
      .exportReferralCode({
        referralCampaignId_eq: idDetail,
        email: email,
      })
      .then(res => {
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const toggleModalConfirm = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirm: !modal.isModalConfirm,
    }));
  };

  const toggleModalConfirmDelete = () => {
    setModal(preState => ({
      ...preState,
      isModalConfirmDelete: !modal.isModalConfirmDelete,
    }));
  };

  const toggleModalSuccessDelete = () => {
    setIsLoading(true);
    new ReferralPartnershipService().softDelete(idDetail).then(res => {
      if (res && res?.success) {
        setIsLoading(false);
        toggleModalConfirmDelete();
        setModal(preState => ({
          ...preState,
          isModalSuccessDelete: !modal.isModalSuccessDelete,
        }));
      }
    });
  };

  const getDataDetail = () => {
    if (idDetail) {
      setIsLoading(true);
      new ReferralPartnershipService().getOne(idDetail).then(res => {
        if (res && res.data && res.data.data) {
          const data = res?.data?.data;
          setIsLoading(false);
          setValueRangePicker({
            startDate: new Date(data?.expireStartDateTime),
            endDate: new Date(data?.expireEndDateTime),
          });
          setInitialValues({
            ...initialValues,
            id: data?.id,
            name: data?.name,
            description: data?.description,
            point: data?.point,
            quantity: data?.quantity,
            expireStartDateTime: data?.expireStartDateTime,
            expireEndDateTime: data?.expireEndDateTime,
            codeType: data?.codeType,
            code: data?.prefix,
            numberId: data?.numberId,
            referralCampaignMerchantId: data?.referralCampaignMerchantId,
            updatedDateTime: data?.updatedDateTime,
            createdDateTime: data?.createdDateTime,
            usedQuantity: data?.usedQuantity,
          });
          defaultQuantity = data?.quantity;
          setDisabledUnlimitedQty(data?.quantity === -1);
          setShowGeneratedCodes(data?.codeType === 2 && data?.quantity !== -1);
        }
      });
    }
  };

  const toggleCodeListVisible = () => {
    setCodeListVisible(!codeListVisible);
  };

  useEffect(() => {
    setDisabledQty(
      initialValues.quantity === -1 || initialValues.codeType === 2
    );
  }, [initialValues.quantity]);

  useEffect(() => {
    getDataDetail();
  }, []);

  return (
    <>
      <Helmet title="Edit Partner Detail" />

      <AllModal
        isModalConfirmDelete={modal.isModalConfirmDelete}
        isModalSuccessDelete={modal.isModalSuccessDelete}
        toggleModalConfirmDelete={toggleModalConfirmDelete}
        toggleModalSuccessDelete={toggleModalSuccessDelete}
        setModal={setModal}
      />

      <ModalCodeList
        visible={codeListVisible}
        idDetail={idDetail}
        onClose={toggleCodeListVisible}
      />

      <Box
        component="section"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          Referral Partner Detail
        </Typography>

        <StyledDivBreadcrumbs>
          <Breadcrumbs
            routes={[
              typeRoutes('Referral Programme'),
              typeRoutes(
                'Partnership',
                `/${path.REFERRAL_PROGRAMME}/${
                  path.PARTNERSHIP
                }?${queryString.stringify(location?.state)}`
              ),
              typeRoutes('Detail', undefined, true),
            ]}
          />
        </StyledDivBreadcrumbs>

        <Divider my={6} />

        <Grid container spacing={6} mb={4}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Stats
              title="No. of Referral"
              amount={initialValues.usedQuantity}
              icon={images.icon_points}
              isManage={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Stats
              title="Remaining Referral Quantity"
              amount={
                initialValues.quantity !== -1
                  ? initialValues.quantity - initialValues.usedQuantity
                  : '∞'
              }
              icon={images.icon_transactional}
              isManage={false}
            />
          </Grid>
        </Grid>

        <Grid container mb="30px">
          <Grid item xs={12} lg={12}>
            <Shadow>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .required('Referral Partner Name is required')
                    .max(40),
                  description: Yup.string()
                    .required('Referral Description is required')
                    .max(205),
                  point: Yup.number()
                    .integer()
                    .max(
                      2000,
                      'Maximum referral points reward must be less than equal to 2000'
                    )
                    .min(
                      0,
                      'Minimum referral points reward must be equal or greater than 0'
                    ),
                  quantity: Yup.number()
                    .integer()
                    .min(
                      initialValues.quantity === -1 ? -1 : 1,
                      'Quantity must be between 1 and 9999, both values inclusive'
                    )
                    .max(
                      initialValues.quantity === -1 ? -1 : 9999,
                      'Quantity must be between 1 and 9999, both values inclusive'
                    ),
                })}
                onSubmit={onUpdate}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  // isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Card px={6} pt={6}>
                      <CardContent>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={10}
                          mb="50px"
                        >
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Creation ID
                              </Typography>
                              <Input
                                size="small"
                                name="numberId"
                                value={values.numberId}
                                variant="outlined"
                                helperText={touched.numberId && errors.numberId}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled
                                error={Boolean(
                                  touched.numberId && errors.numberId
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Partner Name (40 Max Char.){' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <Input
                                placeholder="Enter Referral Partner Name"
                                size="small"
                                name="name"
                                value={values.name}
                                variant="outlined"
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.name && errors.name)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Description (100 Max Char.){' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <TextArea
                                label=""
                                placeholder="Enter Referral Description"
                                maxRows={3}
                                name="description"
                                value={values.description}
                                helperText={
                                  touched.description && errors.description
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Points Reward
                              </Typography>
                              <Input
                                placeholder="Enter Points Reward"
                                size="small"
                                name="point"
                                type="number"
                                value={values.point}
                                variant="outlined"
                                helperText={touched.point && errors.point}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.point && errors.point)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Quantity
                              </Typography>
                              <Input
                                placeholder="Enter Referral Quantity"
                                size="small"
                                name="quantity"
                                type="string"
                                value={
                                  values.quantity === -1 ? '∞' : values.quantity
                                }
                                variant="outlined"
                                helperText={touched.quantity && errors.quantity}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.quantity && errors.quantity
                                )}
                                disabled={disabledQty}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl fullWidth margin="normal">
                              <div>
                                <Typography variant="body2">
                                  Unlimited Quantity
                                </Typography>
                                <Checkbox
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  checked={values.quantity === -1}
                                  name="unlimitedQuantity"
                                  value={values.quantity === -1}
                                  onChange={e => {
                                    handleChange(e);
                                    setInitialValues({
                                      ...values,
                                      quantity: e.target.checked
                                        ? -1
                                        : defaultQuantity,
                                    });
                                  }}
                                  inputProps={{'aria-label': 'controlled'}}
                                  disabled={
                                    disabledUnlimitedQty ||
                                    initialValues.codeType === 2
                                  }
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl fullWidth margin="normal">
                              <Typography variant="body2" mb={2}>
                                Referral Code{' '}
                                <span className="mandatory-label">(*)</span>{' '}
                                {showGeneratedCodes && (
                                  <Link
                                    sx={{cursor: 'pointer', fontWeight: 'bold'}}
                                    onClick={getCodeExport}
                                  >
                                    Export Referral Code
                                  </Link>
                                )}
                              </Typography>
                              <Input
                                disabled
                                label=""
                                name="code"
                                size="small"
                                variant="outlined"
                                placeholder="Enter Referral Code"
                                value={values.code}
                                helperText={touched.code && errors.code}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.code && errors.code)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth disabled>
                              <Typography variant="body2" mb={2} mt={3}>
                                Code Type
                              </Typography>
                              <RadioGroups
                                name="codeType"
                                options={radioOptions}
                                values={values.codeType}
                                onChange={e => {
                                  handleChange(e);
                                  setInitialValues({
                                    ...values,
                                    codeType: +e.target.value,
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                Start Referral Period{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <DateTimePicker
                                required
                                isEmptyValue={
                                  valueRangePicker.startDate === null
                                }
                                value={valueRangePicker.startDate}
                                helperText="Start Referral Period is required"
                                minDate={
                                  valueRangePicker.startDate >= currentDate
                                    ? currentDate
                                    : valueRangePicker.startDate
                                }
                                onChange={e => {
                                  if (e >= valueRangePicker.endDate) {
                                    setValueRangePicker({
                                      ...valueRangePicker,
                                      startDate: e,
                                      endDate: null,
                                    });
                                  } else {
                                    setValueRangePicker({
                                      ...valueRangePicker,
                                      startDate: e,
                                    });
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl variant="standard" fullWidth>
                              <Typography variant="body2" mb={2} mt={3}>
                                End Referral Period{' '}
                                <span className="mandatory-label">(*)</span>
                              </Typography>
                              <DateTimePicker
                                disabled={valueRangePicker.startDate === null}
                                value={valueRangePicker.endDate}
                                required
                                isEmptyValue={valueRangePicker.endDate === null}
                                helperText="End Referral Period is required"
                                minDate={
                                  valueRangePicker?.startDate ?? new Date()
                                }
                                onChange={e => {
                                  setValueRangePicker({
                                    ...valueRangePicker,
                                    endDate: e,
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <FooterDetail
                      isEdit={idDetail ? true : false}
                      toggleModalConfirm={toggleModalConfirm}
                      toggleModalConfirmDelete={toggleModalConfirmDelete}
                      titleDelete="Delete Referral Partner"
                      loading={isLoading}
                      isPartnership
                      onClickReferralTransactions={() => {
                        navigate(
                          `/${path.REFERRAL_PROGRAMME}/${path.REFERRAL_TRANSACTIONS}?referralCampaignNumberId_eq=${initialValues.numberId}`
                        );
                      }}
                    />
                  </form>
                )}
              </Formik>
            </Shadow>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PartnershipDetail;
