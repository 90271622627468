import styled from 'styled-components/macro';
import {spacing} from '@mui/system';
import {Link} from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from '@mui/material';

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const Typography = styled(MuiTypography)(spacing);

export {AppBar, IconButton, MenuItemLink, Typography};
