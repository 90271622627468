export type ColumnsType = {
  dataIndex: string;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
};

export type RowType = {
  [key: string]: string | number;
  id: string;
  contactName: string;
  contactEmail: string;
  points: number;
};

export type DataDetail = {
  name?: string;
  contactEmail?: string;
  additionalEmail?: string;
  contactName?: string;
  createdBy?: number;
  createdDateTime?: string;
  id?: number;
  lockdown?: boolean;
  qRId?: string;
  status?: number;
  totalPoints?: number;
  updatedBy?: number;
  updatedDateTime?: string;
  totalTierPoints?: string | number;
  totalTicketsUsed?: string | number;
  qrExpiredDate?: string;
  refreshedDate?: string;
  cashoutBalance?: number;
  totalCashout?: number;
};

export function typeRoutes(
  name: string,
  path?: string,
  isCurrentPage?: boolean
) {
  return {name, path, isCurrentPage};
}
