/* eslint-disable @typescript-eslint/no-explicit-any */
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {FormControl, Grid} from '@mui/material';
import {Modal, Button} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from '../ModalMonthSelection/ModalMonthSelection.style';

interface ModalConfirmCashoutProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm?: (any) => void;
}

function ModalConfirmCashout({
  visible,
  onCancel,
  onConfirm,
}: ModalConfirmCashoutProps) {
  return (
    <Modal visible={visible} onClose={onCancel} maxWidth="xs">
      <>
        <StyledModalTitle>Cash Out confirm</StyledModalTitle>
        <StyledIconClose onClick={onCancel}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sx={{width: '100%'}}>
              <FormControl variant="standard" fullWidth>
                <Typography variant="body2" mb={2} mt={3}>
                  Are you sure you want to cash out? This action cannot be
                  reversed.
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Divider variant="middle" pt={8} />
          <StyledModalActions>
            <div className="btn-action-1">
              <Button variant="text" type="button" onClick={onCancel}>
                No
              </Button>
            </div>
            <div className="btn-action-2">
              <Button
                type="submit"
                onClick={() => {
                  if (onConfirm) {
                    onConfirm(true);
                    onCancel();
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </StyledModalActions>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalConfirmCashout;
