/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseApiService from '../baseApi.service';

export default class ReferralPartnershipService extends BaseApiService {
  readonly basePath: string = 'referralCampaign/v1';
  constructor(props = null) {
    super(props);
  }

  exportCSV = async (
    params: object,
    onDownloadProgress: (progressEvent: any) => void
  ) => {
    return await this.getProgress(
      'exportCSV/voucher',
      params,
      {},
      progressEvent => onDownloadProgress(progressEvent)
    ).then(async res => res?.data);
  };

  getGeneratedCodeList = async (partnerId: string, params: object) => {
    return await this.get(`getGenarateCode/${partnerId}`, params).then(
      async res => res?.data
    );
  };
}
