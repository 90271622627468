/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Typography, Button} from '@mui/material';
import {images} from '../../../constants/image';
import {Link, useLocation} from 'react-router-dom';
import {path} from '../../../routes/path';

// styles
import {
  Wrapper,
  StyledImage,
  StyledImg,
  StyledEmail,
} from './ResetPasswordSuccess.style';
import {replaceEmail} from '../../../utils';

function ResetPassword() {
  const location = useLocation();
  const {email} = location?.state;

  return (
    <React.Fragment>
      <StyledImage>
        <img src={images.logo_sentosa} />
      </StyledImage>
      <Wrapper sx={{width: '456px'}}>
        <Helmet title="Reset password success" />
        <StyledImg>
          <img src={images.icon_reset_password_success} />
        </StyledImg>

        <Typography
          component="h1"
          align="center"
          gutterBottom
          fontSize="14px"
          fontWeight="bold"
        >
          Password Reset Email Sent
        </Typography>
        <Typography
          component="h6"
          fontSize="14px"
          align="center"
          lineHeight="24px"
          color="#000000"
          mb={5}
        >
          An email has been sent to your rescue email address,
          <StyledEmail> {replaceEmail(email)}</StyledEmail>. Follow the
          directions in the email to reset your password.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          component={Link}
          to={`/${path.AUTH}/${path.SIGN_IN}`}
        >
          Done
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
