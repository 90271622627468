/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Formik} from 'formik';
import {Grid, CardContent, Checkbox, FormControl} from '@mui/material';
import * as Yup from 'yup';
import {images} from '../../../constants/image';
import ReferralCampaignService from '../../../services/ReferralProgramme/referral-campaign.service';
import {
  Breadcrumbs,
  FooterDetail,
  DateTimePicker,
  Input,
  Stats,
} from '../../../components';
import useAuth from '../../../hooks/useAuth';
import success from '../../../constants/success';
import {typeRoutes} from '../../../types/typeReferralTransactions';

// Style
import {
  Divider,
  Typography,
  Shadow,
  Card,
  FlexBox,
} from './IslanderMember.style';
import {formatDateTime} from '../../../utils';

const currentDate = new Date();

function ReferralIslanderMember() {
  const format = 'DD/MM/YYYY';
  const formatDateTimes = 'DD/MM/YYYY HH:mm';
  const {showNotification} = useAuth();
  const [idReferralCampaign, setIdReferralCampaign] = useState<string>('');
  const [initialValues, setInitialValues] = useState({
    description: '',
    point: 0,
    quantity: 0,
    usedQuantity: 0,
    codeType: 0,
    expireStartDateTime: '',
    expireEndDateTime: '',
    referralLimit: 0,
    referralType: 0,
  });
  const [defaultQuantity, setDefaultQuantity] = useState<number>(9999);
  const [defaultReferralLimit, setDefaultReferralLimit] =
    useState<number>(9999);
  const [isUnlimitReferralLimit, setIsUnlimitReferralLimit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueRangePicker, setValueRangePicker] = useState({
    expireStartDateTime: null,
    expireEndDateTime: null,
  });

  const checkRemainingReferralQuantity = () => {
    if (initialValues.quantity === -1) {
      return '∞';
    } else {
      return initialValues.quantity - initialValues.usedQuantity;
    }
  };

  const getReferralCampaign = () => {
    setIsLoading(true);
    new ReferralCampaignService()
      .getAll({
        _page: 0,
        _size: 1,
        _sort: 'createdDateTime:desc',
        referralType_eq: 2,
      })
      .then(res => {
        if (res.success === true) {
          if (res.data.items) {
            const dataCampaign = res.data.items[0];
            setInitialValues({
              ...initialValues,
              description: dataCampaign.description,
              point: dataCampaign.point,
              quantity: dataCampaign.quantity,
              usedQuantity: dataCampaign.usedQuantity,
              codeType: dataCampaign.codeType,
              expireStartDateTime: dataCampaign.expireStartDateTime,
              expireEndDateTime: dataCampaign.expireEndDateTime,
              referralLimit: dataCampaign.referralLimit,
              referralType: dataCampaign.referralType,
            });
            setIdReferralCampaign(dataCampaign.id);
            setDefaultQuantity(dataCampaign.quantity);
            setDefaultReferralLimit(dataCampaign.referralLimit);
            if (dataCampaign.referralLimit === -1)
              setIsUnlimitReferralLimit(true);
            setValueRangePicker({
              expireStartDateTime: dataCampaign.expireStartDateTime,
              expireEndDateTime: dataCampaign.expireEndDateTime,
            });
            setIsLoading(false);
          }
        }
      });
  };

  const onUpdate = values => {
    setIsLoading(true);
    new ReferralCampaignService()
      .update(idReferralCampaign, {
        ...values,
        expireStartDateTime: formatDateTime(
          valueRangePicker.expireStartDateTime,
          formatDateTimes
        ),
        expireEndDateTime: formatDateTime(
          valueRangePicker.expireEndDateTime,
          formatDateTimes
        ),
      })
      .then(res => {
        if (res && res.success) {
          getReferralCampaign();
          setIsLoading(false);
          showNotification({
            message: success.UPDATE_SUCCESS,
          });
        } else {
          setIsLoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  useEffect(() => {
    getReferralCampaign();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Referral Transactions" />

      <Grid justifyContent="space-between" container spacing={2} columns={16}>
        <Grid item xs={8}>
          <Typography variant="h3" gutterBottom>
            Referral Programme
          </Typography>
          <Breadcrumbs
            routes={[typeRoutes('Islander Member', undefined, true)]}
          />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6} mb={4}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Total No. of Islander Member Referrals"
            amount={initialValues.usedQuantity}
            icon={images.icon_transactional}
            isManage={false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Remaining Referral Quantity"
            amount={checkRemainingReferralQuantity()}
            icon={images.icon_transactional}
            isManage={false}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          quantity: Yup.number().max(9999, 'Maximum referral quantity is 9999'),
          referralLimit: Yup.number(),
        })}
        onSubmit={onUpdate}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container mb={9}>
              <Grid item xs={12} lg={12}>
                <Shadow>
                  <Card px={6} pt={6}>
                    <CardContent>
                      <Grid container spacing={5} mb="50px">
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl variant="outlined" fullWidth>
                            <Typography variant="body2" mb={2}>
                              Referral reward points
                            </Typography>
                            <Input
                              type="number"
                              placeholder="Enter Referral reward points"
                              size="small"
                              variant="outlined"
                              name="point"
                              value={values.point}
                              helperText={touched.point && errors.point}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.point && errors.point)}
                              className="mandatory-field"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <Typography variant="body2" mb={2}>
                              Referral limit per member
                            </Typography>
                            <Input
                              disabled={isUnlimitReferralLimit}
                              type="string"
                              placeholder="Enter Referral limit per member"
                              size="small"
                              variant="outlined"
                              name="referralLimit"
                              value={
                                isUnlimitReferralLimit
                                  ? '∞'
                                  : values.referralLimit
                              }
                              helperText={
                                touched.referralLimit && errors.referralLimit
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.referralLimit && errors.referralLimit
                              )}
                              className="mandatory-field"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl>
                            <Typography variant="body2">
                              Unlimited Per Member
                            </Typography>
                            <Checkbox
                              sx={{
                                paddingLeft: 0,
                                width: 'fit-content',
                              }}
                              checked={isUnlimitReferralLimit}
                              name="unlimitedQuantity"
                              value={isUnlimitReferralLimit}
                              onChange={e => {
                                handleChange(e);
                                setIsUnlimitReferralLimit(e.target.checked);
                                setInitialValues({
                                  ...values,
                                  referralLimit: e.target.checked
                                    ? -1
                                    : defaultReferralLimit,
                                });
                              }}
                              inputProps={{'aria-label': 'controlled'}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <Typography variant="body2" mb={2}>
                              Referral quantity
                            </Typography>
                            <FlexBox>
                              <Input
                                disabled={initialValues.quantity === -1}
                                fullWidth
                                type="string"
                                placeholder="Enter Referral quantity"
                                size="small"
                                variant="outlined"
                                name="quantity"
                                value={
                                  values.quantity === -1 ? '∞' : values.quantity
                                }
                                helperText={touched.quantity && errors.quantity}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.quantity && errors.quantity
                                )}
                                className="mandatory-field"
                              />
                            </FlexBox>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <FormControl>
                            <Typography variant="body2">
                              Unlimited Quantity
                            </Typography>
                            <Checkbox
                              sx={{
                                paddingLeft: 0,
                                width: 'fit-content',
                              }}
                              checked={values.quantity === -1}
                              name="unlimitedQuantity"
                              value={values.quantity === -1}
                              onChange={e => {
                                handleChange(e);
                                setInitialValues({
                                  ...values,
                                  quantity: e.target.checked
                                    ? -1
                                    : defaultQuantity,
                                });
                              }}
                              inputProps={{'aria-label': 'controlled'}}
                              disabled={defaultQuantity === -1}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Typography variant="body2" mb={2} mt={3}>
                              Start Referral Period{' '}
                              <span className="mandatory-label">(*)</span>
                            </Typography>
                            <DateTimePicker
                              required
                              isEmptyValue={
                                valueRangePicker.expireStartDateTime === null
                              }
                              value={valueRangePicker.expireStartDateTime}
                              helperText="Start Referral Period is required"
                              minDate={
                                valueRangePicker.expireStartDateTime >=
                                currentDate
                                  ? currentDate
                                  : valueRangePicker.expireStartDateTime
                              }
                              onChange={e => {
                                if (e >= valueRangePicker.expireEndDateTime) {
                                  setValueRangePicker({
                                    ...valueRangePicker,
                                    expireStartDateTime: e,
                                    expireEndDateTime: null,
                                  });
                                } else {
                                  setValueRangePicker({
                                    ...valueRangePicker,
                                    expireStartDateTime: e,
                                  });
                                }
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth>
                            <Typography variant="body2" mb={2} mt={3}>
                              End Referral Period{' '}
                              <span className="mandatory-label">(*)</span>
                            </Typography>
                            <DateTimePicker
                              disabled={
                                valueRangePicker.expireStartDateTime === null
                              }
                              value={valueRangePicker.expireEndDateTime}
                              required
                              isEmptyValue={
                                valueRangePicker.expireEndDateTime === null
                              }
                              helperText="End Referral Period is required"
                              minDate={
                                valueRangePicker?.expireStartDateTime ??
                                new Date()
                              }
                              onChange={e => {
                                setValueRangePicker({
                                  ...valueRangePicker,
                                  expireEndDateTime: e,
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Shadow>
              </Grid>
            </Grid>
            <FooterDetail isEdit loading={isLoading} canDelete={true} />
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default ReferralIslanderMember;
