/* eslint-disable node/no-unsupported-features/node-builtins */
import React, {useState, useRef, useEffect} from 'react';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  DialogContent,
  Box,
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';
import {images} from '../../constants/image';
import {Modal, Button, RadioGroups} from '../index';
import {
  AddAPhotoOutlined,
  AddToPhotosOutlined,
  AspectRatioOutlined,
  BrowserUpdatedOutlined,
  Cancel,
  ImageAspectRatioRounded,
  PlusOneOutlined,
  PlusOneRounded,
} from '@mui/icons-material';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './AspectSelectImage.style';
import 'react-image-crop/dist/ReactCrop.css';

const aspectOptions = [
  {
    key: '1 : 1',
    value: 1,
  },
  {
    key: '3 : 4',
    value: 3 / 4,
  },
];

interface AspectSelectImageProps {
  defaultImages: [];
  setDefaultImages: () => void;
  required?: boolean;
  helperText?: string;
  showError?: boolean;
}

const AspectSelectImage = ({
  defaultImages,
  setDefaultImages,
  required,
  helperText,
  showError,
}: AspectSelectImageProps) => {
  // const [voucherImages, setVoucherImages] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(1);
  const [cropVisible, setCropVisible] = useState(false);

  const onSelectImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    ratio: number
  ) => {
    setAspect(ratio);
    if (e.target.files && e.target.files[0]) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
        setCropVisible(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      imgRef.current = e.currentTarget;
      const {width, height} = imgRef.current;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = 'high';

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  };

  const onSubmitImage = async () => {
    if (imgRef.current && completedCrop?.width && completedCrop?.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef.current,
        completedCrop,
        'newFile.jpeg'
      );
      setDefaultImages([croppedImageUrl]);
      setCropVisible(false);
    }
  };

  const closeCrop = () => {
    setCropVisible(false);
  };

  useEffect(() => {
    if (aspect === 1) {
      setCrop(
        centerAspectCrop(
          imgRef.current?.width ?? 1080,
          imgRef.current?.height ?? 1080,
          aspect
        )
      );
    } else if (aspect === 3 / 4) {
      setCrop(
        centerAspectCrop(
          imgRef.current?.width ?? 480,
          imgRef.current?.height ?? 680,
          aspect
        )
      );
    }
  }, [aspect]);

  return (
    <>
      {defaultImages.length > 0 ? (
        <ImageList cols={5}>
          {defaultImages.map(e => (
            <ImageListItem key={e}>
              <img
                src={typeof e === 'string' ? e : URL.createObjectURL(e)}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{color: 'white'}}
                    onClick={() => setDefaultImages([])}
                  >
                    <Cancel />
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Grid container spacing={3}>
          <Grid item md={6}>
            <FormControl fullWidth margin="dense">
              <Button
                variant="outlined"
                color="primary"
                aria-label="upload image"
                component="label"
                sx={{marginRight: 5}}
                onClick={() => {}}
              >
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={e => onSelectImage(e, 1 / 1)}
                />
                1 : 1
              </Button>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth margin="dense">
              <Button
                variant="outlined"
                color="primary"
                aria-label="upload image"
                component="label"
                sx={{marginLeft: 5}}
                onClick={() => {}}
              >
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={e => onSelectImage(e, 3 / 4)}
                />
                3 : 4
              </Button>
            </FormControl>
          </Grid>
          {required && showError && (
            <FormHelperText
              error
              sx={{margin: '4px 14px 0px 14px', paddingLeft: '12px'}}
            >
              {helperText ? helperText : 'Image is required'}
            </FormHelperText>
          )}
        </Grid>
      )}
      <Modal visible={cropVisible} onClose={closeCrop} maxWidth="sm">
        <>
          <StyledModalTitle>
            <Typography variant="body2" mb={2} mt={3}>
              Crop Image
            </Typography>
            {/* <RadioGroups
              name="aspectType"
              options={aspectOptions}
              values={aspect}
              onChange={e => {
                setAspect(+e.target.value);
              }}
            /> */}
          </StyledModalTitle>
          <StyledIconClose onClick={closeCrop}>
            <img src={images.icon_close} />
          </StyledIconClose>
          <Divider variant="middle" />
          <DialogContent>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </DialogContent>
          <Divider variant="middle" pt={8} />
          <StyledModalActions>
            <div className="btn-action-2">
              <Button type="button" onClick={onSubmitImage}>
                Add Image
              </Button>
            </div>
          </StyledModalActions>
        </>
      </Modal>
    </>
  );
};

export default AspectSelectImage;
