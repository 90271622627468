import {TextField} from '@mui/material';
import styled from 'styled-components/macro';

const ComponentWrap = styled.div`
  padding: 60px 90px;
`;

const SectionWrap = styled.section`
  position: relative;
`;

const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 380px;
  border: 3px dashed #bfbfbf;
  &:hover {
    border: 3px dashed #0d99ff;
  }
`;

const TypographyFirst = styled.p`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const TypographySecond = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const LinkUpload = styled.span`
  color: #4171cc;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;

const Sub = styled.p`
  color: #999595;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
`;

const StyledIcon = {
  width: '18px',
  height: '18px',
};

const StyledImage = styled.img`
  width: 84px;
  height: 70px;
  margin-bottom: 20px;
`;

const StyledAside = styled.aside`
  width: fit-content;
  position: absolute;
  top: 50%;
  right: -30px;
`;

const StyledWrapOption = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const StyledOption = styled.li`
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #4171cc;
  width: fit-content;
  color: #fff;
  margin-bottom: 5px;
`;

const SectionWrapItem = styled.section`
  position: relative;
  width: 70%;
  height: 90px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  display: flex;

  & .linear__progress {
    span {
      background-color: #0d99ff;
    }
  }

  & .linear__progress--completed {
    height: 10px;
    span {
      background-color: #0d99ff;
    }
  }
`;

const SectionWrapItemFail = styled.section`
  position: relative;
  width: 70%;
  height: 90px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  display: flex;
  background-color: rgba(253, 162, 155, 0.2);
  & .error-title {
    color: #b42318;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & .error-sub {
    color: #d92d20;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .error-btn {
    color: #b42318;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-top: 10px;
  }
`;

const NormalText = styled.p`
  margin: 0;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
`;

const SubText = styled.p`
  color: #475467;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const Icon = styled.img`
  width: 15px;
  aspect-ratio: auto 15 / 20;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
`;

export {
  StyledWrap,
  TypographyFirst,
  TypographySecond,
  LinkUpload,
  Sub,
  StyledIcon,
  StyledImage,
  StyledOption,
  StyledWrapOption,
  SectionWrap,
  StyledAside,
  ComponentWrap,
  SectionWrapItem,
  NormalText,
  Icon,
  SubText,
  SectionWrapItemFail,
};
