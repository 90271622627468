import {ModalConfirm, ModalSuccess} from '../../../components';
import {useNavigate} from 'react-router-dom';
import {path} from '../../../routes/path';

// styles
import {Typography} from './IslandPartnerDetail.style';

interface AllModalProps {
  isModalConfirm?: boolean;
  isModalSuccess?: boolean;
  isModalConfirmDelete?: boolean;
  isModalSuccessDelete?: boolean;
  toggleModalConfirm?: () => void;
  toggleModalSuccess?: () => void;
  toggleModalConfirmDelete?: () => void;
  toggleModalSuccessDelete?: () => void;
  setModal?: (any) => void;
  isLoading?: boolean;
}

export function AllModal({
  isModalConfirm,
  toggleModalConfirm,
  isModalSuccess,
  toggleModalSuccess,
  setModal,
  isModalConfirmDelete,
  toggleModalConfirmDelete,
  toggleModalSuccessDelete,
  isModalSuccessDelete,
  isLoading,
}: AllModalProps) {
  const navigate = useNavigate();

  return (
    <>
      <ModalConfirm
        visible={isModalConfirm}
        title={
          <Typography variant="h4">
            You are resetting the Island Partners password
          </Typography>
        }
        subTitle={
          <Typography>
            Island Partners will not be able to login with the old password.
          </Typography>
        }
        onClose={toggleModalConfirm}
        onConfirm={toggleModalSuccess}
        isLoading={isLoading}
      />

      <ModalSuccess
        visible={isModalSuccess}
        title={
          <Typography variant="h4">Reset password successfully</Typography>
        }
        subTitle={
          <>
            <Typography>Island Partners password has been changed.</Typography>
            <Typography>
              Tell them to check their email and set a new password.
            </Typography>
          </>
        }
        onClose={() =>
          setModal(preState => ({
            ...preState,
            isModalSuccess: false,
          }))
        }
      />

      <ModalConfirm
        visible={isModalConfirmDelete}
        title={
          <Typography variant="h4">
            You are deleting the Island Partners
          </Typography>
        }
        subTitle={
          <Typography>Island Partners will not be able to login.</Typography>
        }
        onClose={toggleModalConfirmDelete}
        onConfirm={toggleModalSuccessDelete}
        isLoading={isLoading}
      />

      <ModalSuccess
        visible={isModalSuccessDelete}
        title={<Typography variant="h4">Delete successfully</Typography>}
        subTitle={<Typography>Island Partners account deleted.</Typography>}
        onClose={() => {
          setModal(preState => ({
            ...preState,
            isModalSuccessDelete: false,
          }));
          navigate(`/${path.ISLAND_PARTNERS}`);
        }}
      />
    </>
  );
}
