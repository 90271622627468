/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {Modal, Button, Input} from '../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  name_contains?: string;
  setName_contains?: (string) => void;
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  name_contains,
  setName_contains,
}: ModalFilterProps) {
  const onChangeName_contains = (event: any) => {
    setName_contains(event.target.value);
  };

  const handleClose = () => {
    onClose();
    if (!visible) {
      handleClean();
    }
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ISLAND PARTNER NAME
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Island Partner Name"
              size="small"
              value={name_contains}
              onChange={e => onChangeName_contains(e)}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button
              disabled={!name_contains}
              variant="text"
              onClick={handleClean}
            >
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button disabled={!name_contains} onClick={handleFilter}>
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
