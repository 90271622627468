import styled from 'styled-components/macro';
import {TextField} from '@mui/material';
import {IconButton} from '@mui/material';

const StyledInput = styled(TextField)`
  margin-bottom: 18px;
  input:read-only {
    font-weight: 100;
  }
`;

const StyledIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    width: 18px;
  }
`;

export {StyledInput, StyledIconButton};
