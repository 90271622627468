import styled from 'styled-components/macro';
import {
  Paper,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from '@mui/material';
import {spacing} from '@mui/system';

import {ReactComponent as Logo} from '../../../vendor/logo.svg';

export const Brand = styled(Logo)`
  fill: ${props => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

export const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

export const Alert = styled(MuiAlert)(spacing);
export const TextField = styled(MuiTextField)<{my?: number}>(spacing);
