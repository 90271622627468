/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, forwardRef} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {
  Modal,
  Select,
  Button,
  Input,
  MultipleSelectCheckbox,
} from '../../../../components';
import MembersService from '../../../../services/Members/members.service';
import {formatPhoneNumber} from '../../../../utils';
import TagsService from '../../../../services/Tags/tags.service';
import InterestsService from '../../../../services/Interests/interests.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';
interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  optionTier?: any;
  setOptionTier?: (any) => void;
  tierId_eq?: string;
  email_contains?: string;
  mobileNumber_contains?: string | number;
  membershipId_contains?: string | number;
  supportTierIds_isnull?: string;
  tagId_contains?: string[];
  interestId_contains?: string[];
  setPhoneNumber_eq?: (string) => void;
  setMembershipId_eq?: (any) => void;
  setTierId_eq?: (string) => void;
  setEmail_eq?: (string) => void;
  setSupportTierIds_isnull?: (string) => void;
  setTagId_contains?: (string) => void;
  setTagId_label?: (string) => void;
  status_label?: string;
  setStatus_eq?: () => void;
  setNumberOfReferrals_eq?: () => void;
  numberOfReferrals_eq?: number | string;
  setReferralCode_contains?: () => void;
  referralCode_contains?: string;
  setTotalPoints_lte?: () => void;
  totalPoints_lte?: string;
  setTotalPoints_gte?: () => void;
  totalPoints_gte?: string;
  setTotalTierPoints_lte?: () => void;
  totalTierPoints_lte?: string;
  setTotalTierPoints_gte?: () => void;
  totalTierPoints_gte?: string;
  statusSelected?: string | number;
  setStatusSelected?: () => void;
  setInterestId_contains?: (string) => void;
  setInterestId_label?: (string) => void;
}

const citizenshipOptions = [
  {label: 'SC/PR', value: '0'},
  {label: '-', value: '1'},
];

const statusOptions = [
  {label: 'Active', value: '1'},
  {label: 'Deleted', value: '2'},
];

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  tierId_eq,
  email_contains,
  optionTier,
  setOptionTier,
  setTierId_eq,
  setEmail_eq,
  mobileNumber_contains,
  membershipId_contains,
  setPhoneNumber_eq,
  setMembershipId_eq,
  supportTierIds_isnull,
  setSupportTierIds_isnull,
  tagId_contains,
  setTagId_contains,
  setTagId_label,
  status_label,
  setStatus_eq,
  setNumberOfReferrals_eq,
  numberOfReferrals_eq,
  setReferralCode_contains,
  referralCode_contains,
  setTotalPoints_lte,
  totalPoints_lte,
  setTotalPoints_gte,
  totalPoints_gte,
  setTotalTierPoints_lte,
  totalTierPoints_lte,
  setTotalTierPoints_gte,
  totalTierPoints_gte,
  statusSelected,
  setStatusSelected,
  interestId_contains,
  setInterestId_contains,
  setInterestId_label,
}: ModalFilterProps) {
  // states
  const [params] = useState({
    tierName_ne: 'Jubilee',
    _sort: 'tierName:asc',
  });

  const [watchlistMultiSelectCheckbox, setWatchlistMultiSelectCheckbox] =
    useState([]);
  const [optionWatchList, setOptionWatchList] = useState([]);

  const [interestMultiSelectCheckbox, setInterestMultiSelectCheckbox] =
    useState([]);
  const [optionInterest, setOptionInterest] = useState([]);

  const onChangeEmail_eq = (event: any) => {
    setEmail_eq(event.target.value);
  };

  const onChangeTierId_eq = (event: any) => {
    setTierId_eq(event.target.value);
  };

  const onChangePhoneNumber_eq = (event: any) => {
    const {value} = event.target;
    if (value.length > 0 && value.length < 9) {
      setPhoneNumber_eq(formatPhoneNumber(value));
    }
  };

  const onChangeMembershipId_eq = (event: any) => {
    setMembershipId_eq(event.target.value);
  };

  const onChangeCitizenship_eq = (event: any) => {
    setSupportTierIds_isnull(event.target.value);
  };

  const onChangeStatus = event => {
    setStatus_eq(event.target.value);
    setStatusSelected(event.target.value);
  };

  const handleCleanForm = () => {
    handleClean();
    setStatusSelected('');
  };

  const handleClose = () => {
    onClose();
    if (!visible) {
      handleCleanForm();
    }
  };

  const getListTier = () => {
    new MembersService().getListTier(params).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const newData = res?.data?.items.map(item => {
          item.value = item.id;
          item.label = item.tierDescription ?? item.tierName;
          return item;
        });
        setOptionTier(newData);
      }
    });
  };

  const getWatchListTag = () => {
    new TagsService()
      .getAll({
        status_eq: 1,
        _size: 2147483647,
        _sort: 'name:asc',
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionWatchList(newData);
          const selectedIds = newData?.filter(item => {
            return tagId_contains?.includes(item.value);
          });
          setWatchlistMultiSelectCheckbox(selectedIds);
        }
      });
  };

  const getInterests = () => {
    new InterestsService().getAll({}).then(res => {
      if (res?.data && Array.isArray(res.data.items)) {
        const newData = res?.data?.items?.reduce((items, item) => {
          items.push({
            title: item.label || '-',
            value: item.id,
          });
          return items;
        }, []);
        setOptionInterest(newData);
        const selectedIds = newData?.filter(item => {
          return interestId_contains?.includes(item.value);
        });
        setInterestMultiSelectCheckbox(selectedIds);
      }
    });
  };

  useEffect(() => {
    if (status_label !== null) {
      setStatusSelected(
        statusOptions.find(item => item.label === status_label[0])?.value
      );
    }
  }, [status_label]);

  useEffect(() => {
    if (visible) {
      getListTier();
      getWatchListTag();
      getInterests();
    }
  }, [visible]);

  return (
    <Modal visible={visible} onClose={handleClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TIER
            </Typography>
            <Select
              labelId="tierId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChangeTierId_eq(e)}
              value={tierId_eq}
              placeholder="Select by tier"
              size="small"
              selectOptions={optionTier}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY EMAIL
            </Typography>
            <Input
              type="email"
              variant="outlined"
              placeholder="Enter email"
              size="small"
              value={email_contains}
              onChange={e => onChangeEmail_eq(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY MOBILE NUMBER
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter mobile number"
              size="small"
              name="mobileNumber_contains"
              value={mobileNumber_contains}
              onChange={onChangePhoneNumber_eq}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ACCOUNT ID
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Account ID"
              size="small"
              value={membershipId_contains}
              onChange={e => onChangeMembershipId_eq(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY CITIZENSHIP
            </Typography>
            <Select
              labelId="supportTierIds_isnull"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChangeCitizenship_eq(e)}
              value={supportTierIds_isnull}
              placeholder="Select by citizenship"
              size="small"
              selectOptions={citizenshipOptions}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <Typography variant="body2" mb={2} mt={3}>
              BY WATCHLIST TAGS
            </Typography>
            <MultipleSelectCheckbox
              placeholder="Select by Watchlist tags"
              value={watchlistMultiSelectCheckbox}
              onChange={(e, values) => {
                setWatchlistMultiSelectCheckbox([...values]);
                setTagId_contains(values.map(item => item.value));
                setTagId_label(values.map(item => item.title));
              }}
              options={optionWatchList}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <Typography variant="body2" mb={2} mt={3}>
              BY INTERESTS
            </Typography>
            <MultipleSelectCheckbox
              placeholder="Select by Interests"
              value={interestMultiSelectCheckbox}
              onChange={(e, values) => {
                setInterestMultiSelectCheckbox([...values]);
                setInterestId_contains(values.map(item => item.value));
                setInterestId_label(values.map(item => item.title));
              }}
              options={optionInterest}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY ACCOUNT STATUS
            </Typography>
            <Select
              labelId="status_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChangeStatus(e)}
              value={statusSelected}
              placeholder="Select by account status"
              size="small"
              selectOptions={statusOptions}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY NUMBER OF REFERRALS
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter Number Of Referrals"
              size="small"
              value={numberOfReferrals_eq}
              onChange={e => setNumberOfReferrals_eq(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REFERRAL CODE
            </Typography>
            <Input
              type="text"
              variant="outlined"
              placeholder="Enter Referral Code"
              size="small"
              value={referralCode_contains}
              onChange={e => setReferralCode_contains(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TOTAL POINTS START
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter Total Points Start"
              size="small"
              value={totalPoints_gte}
              onChange={e => setTotalPoints_gte(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TOTAL POINTS END
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter Total Points End"
              size="small"
              value={totalPoints_lte}
              onChange={e => setTotalPoints_lte(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TOTAL TIER POINTS START
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter Total Tier Points Start"
              size="small"
              value={totalTierPoints_gte}
              onChange={e => setTotalTierPoints_gte(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY TOTAL TIER POINTS END
            </Typography>
            <Input
              type="number"
              variant="outlined"
              placeholder="Enter Total Tier Points End"
              size="small"
              value={totalTierPoints_lte}
              onChange={e => setTotalTierPoints_lte(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button
              disabled={
                !tierId_eq &&
                !email_contains &&
                !mobileNumber_contains &&
                !membershipId_contains &&
                !supportTierIds_isnull &&
                !tagId_contains &&
                !interestId_contains &&
                !statusSelected &&
                !numberOfReferrals_eq &&
                !totalPoints_lte &&
                !totalPoints_gte &&
                !totalTierPoints_lte &&
                !totalTierPoints_gte
              }
              variant="text"
              onClick={handleCleanForm}
            >
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              disabled={
                !tierId_eq &&
                !email_contains &&
                !mobileNumber_contains &&
                !membershipId_contains &&
                !supportTierIds_isnull &&
                !tagId_contains &&
                !interestId_contains &&
                !statusSelected &&
                !numberOfReferrals_eq &&
                !totalPoints_lte &&
                !totalPoints_gte &&
                !totalTierPoints_lte &&
                !totalTierPoints_gte
              }
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
