export const POINT_SUCCESS = {
  APPROVED: 1,
  PENDING: 2,
  CANCELLED: 3,
  FAILED: 4,
  ADJUSTED: 5,
  EXPIRED: 6,
};

export const POINT_SUCCESS_LABEL = {
  [POINT_SUCCESS.APPROVED]: 'Complete',
  [POINT_SUCCESS.PENDING]: 'Pending',
  [POINT_SUCCESS.CANCELLED]: 'Declined',
  [POINT_SUCCESS.FAILED]: 'Failed',
  [POINT_SUCCESS.ADJUSTED]: 'Adjusted',
  [POINT_SUCCESS.EXPIRED]: 'Expired',
};

export const POINT_SUCCESS_COLOR = {
  [POINT_SUCCESS.APPROVED]: 'success',
  [POINT_SUCCESS.PENDING]: 'warning',
  [POINT_SUCCESS.CANCELLED]: 'default',
  [POINT_SUCCESS.FAILED]: 'error',
  [POINT_SUCCESS.ADJUSTED]: 'primary',
  [POINT_SUCCESS.EXPIRED]: 'secondary',
};

export const MEMBERSHIP_TICKET_STATUS = {
  ACTIVE: 1,
  USED: 0,
};

export const MEMBERSHIP_TICKET_LABEL = {
  [MEMBERSHIP_TICKET_STATUS.ACTIVE]: 'Active',
  [MEMBERSHIP_TICKET_STATUS.USED]: 'Used',
};

export const MEMBERSHIP_TICKET_COLOR = {
  [MEMBERSHIP_TICKET_STATUS.ACTIVE]: 'success',
  [MEMBERSHIP_TICKET_STATUS.USED]: 'error',
};

export const REFERRAL_TRANSACTION_STATUS = {
  COMPLETE: 1,
  PENDING: 2,
};

export const REFERRAL_TRANSACTION_STATUS_LABEL = {
  [REFERRAL_TRANSACTION_STATUS.COMPLETE]: 'Complete',
  [REFERRAL_TRANSACTION_STATUS.PENDING]: 'Pending',
};

export const REFERRAL_TRANSACTION_STATUS_COLOR = {
  [REFERRAL_TRANSACTION_STATUS.COMPLETE]: 'success',
  [REFERRAL_TRANSACTION_STATUS.PENDING]: 'default',
};
