/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import {
  Modal,
  Button,
  Input,
  MultipleSelectCheckbox,
} from '../../../../components';
import {Formik} from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../hooks/useAuth';
import TagsService from '../../../../services/Tags/tags.service';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalAddNew.style';
import ReferralPartnershipService from '../../../../services/ReferralProgramme/referral-partnership.service';

interface ModalAddNewProps {
  visible: boolean;
  tag?: object;
  onClose?: () => void;
  reloadPage?: () => void;
}

const refreshedDate = new Date();
const qrExpiredDate = new Date(refreshedDate);
qrExpiredDate.setMonth(refreshedDate.getMonth() + 1);

function ModalAddNew({visible, tag, onClose, reloadPage}: ModalAddNewProps) {
  const {showNotification} = useAuth();

  // states
  const [initialValues, setInitialValues] = useState({
    name: '',
    referralCampaignIds: [],
  });
  const [valueMultipleSelectCheckbox, setValueMultipleSelectCheckbox] =
    useState([]);
  const [optionPartnership, setOptionPartnership] = useState([]);
  const [referralIds, setReferralIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAddNew = values => {
    setIsLoading(true);
    if (tag !== null) {
      new TagsService()
        .update(tag?.id, {
          ...values,
          referralCampaignIds:
            tag !== null && tag?.referralCampaigns.length > 0
              ? undefined
              : referralIds,
        })
        .then(res => {
          if (res && res?.success) {
            onClose();
            reloadPage();
            setIsLoading(false);
            showNotification({
              message: 'Tag has been updated',
            });
          } else {
            setIsLoading(false);
            showNotification({
              message: res.errorMessage,
              variation: 'error',
            });
          }
        });
    } else {
      new TagsService()
        .create({...values, referralCampaignIds: referralIds})
        .then(res => {
          if (res && res?.success) {
            onClose();
            reloadPage();
            setIsLoading(false);
            showNotification({
              message: 'Tag has been created',
            });
          } else {
            setIsLoading(false);
            showNotification({
              message: res.errorMessage,
              variation: 'error',
            });
          }
        });
    }
  };

  const getListPartnership = () => {
    setIsLoading(true);
    new ReferralPartnershipService()
      .getAll({
        _page: 0,
        _size: 2147483647,
        status_eq: 1,
        nameSortByDesc_eq: false,
      })
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          const newData = res?.data?.items?.reduce((items, item) => {
            items.push({
              title: item.name || '-',
              value: item.id,
            });
            return items;
          }, []);
          setOptionPartnership(newData);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (visible) {
      setValueMultipleSelectCheckbox([]);
      setOptionPartnership([]);
      setReferralIds([]);
      getListPartnership();
    }
  }, [visible]);

  useEffect(() => {
    if (tag !== null) {
      setInitialValues({
        ...initialValues,
        name: tag?.name ?? '',
      });

      setValueMultipleSelectCheckbox(
        tag.referralCampaigns.reduce((items, item) => {
          items.push({
            title: item.name || '-',
            value: item.id,
          });
          return items;
        }, [])
      );
    } else {
      setInitialValues({
        ...initialValues,
        name: '',
      });
    }
  }, [tag]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>
          {tag !== null ? 'Update Watchlist Tags' : 'Add New Watchlist Tags'}
        </StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .matches(
                  /^[a-zA-Z0-9\s{2,}]{3,20}$/,
                  'Please enter min of 3 and a max of 20 alphanumeric characters including spaces only.'
                )
                .required('Name of tag is required'),
            })}
            onSubmit={onAddNew}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Typography variant="body2" mb={2}>
                    Name of tag <span className="mandatory-label">(*)</span>
                  </Typography>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Name of tag"
                    size="small"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <Typography variant="inherit" mb={2} mt={3}>
                    Referral Campaign Name
                  </Typography>
                  <MultipleSelectCheckbox
                    placeholder="Select Referral Campaign Name"
                    value={valueMultipleSelectCheckbox}
                    onChange={(e, values) => {
                      const newData = values.map(item => item.value);
                      setReferralIds(newData);
                      setValueMultipleSelectCheckbox([...values]);
                    }}
                    disabled={tag !== null && tag.referralCampaigns.length > 0}
                    options={optionPartnership}
                  />
                </FormControl>
                <Divider variant="middle" />
                <StyledModalActions>
                  <div className="btn-action-1">
                    <Button
                      variant="text"
                      type="button"
                      onClick={onClose}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="btn-action-2">
                    <Button type="submit" loading={isLoading}>
                      {tag !== null ? 'Update Tag' : 'Add Tag'}
                    </Button>
                  </div>
                </StyledModalActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalAddNew;
