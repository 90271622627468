/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../../constants/image';
import {
  Modal,
  Select,
  Button,
  RangePicker,
  Input,
} from '../../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleClean?: () => void;
  handleFilter?: () => void;
  valueFilter?: any;
  valueRangePicker?: any;
  setValueFilter?: (any) => void;
  setValueRangePicker?: (any) => void;
  setOptionByType?: (any) => void;
  optionByType?: any;
  nameContainsList?: any;
  setNameContainsList?: (any) => void;
  referralTypeList?: any;
  processIdList?: any;
}

function ModalFilter({
  visible,
  onClose,
  handleClean,
  handleFilter,
  valueFilter,
  setValueRangePicker,
  setValueFilter,
  valueRangePicker,
  referralTypeList,
  processIdList,
}: ModalFilterProps) {
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (name: string, event: any) => {
    setValueFilter((prevState: any) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const onChangeRangePicker = value => {
    setValueRangePicker(value);
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REFERRAL TYPE
            </Typography>
            <Select
              labelId="referralType_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('referralType_eq', e)}
              value={valueFilter.referralType_eq}
              placeholder="Select Referral Type"
              size="small"
              selectOptions={Object.keys(referralTypeList).reduce(
                (referralTypes, key) => {
                  referralTypes.push({
                    label: referralTypeList[key],
                    value: key,
                  });
                  return referralTypes;
                },
                []
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'BY REFERRAL CREATION ID'}
            </Typography>
            <Input
              type="text"
              name="referralCampaignNumberId_eq"
              placeholder="Enter Referral Creation ID"
              size="small"
              variant="outlined"
              value={valueFilter.referralCampaignNumberId_eq}
              onChange={e => onChange('referralCampaignNumberId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REFERRAL DATE RANGE
            </Typography>
            <RangePicker
              value={valueRangePicker}
              onChange={onChangeRangePicker}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'BY REFERRAL ACCOUNT ID'}
            </Typography>
            <Input
              type="text"
              name="accountId_eq"
              placeholder="Enter Referral Account ID"
              size="small"
              variant="outlined"
              value={valueFilter.accountId_eq}
              onChange={e => onChange('accountId_eq', e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REFERRAL TRANSACTION STATUS
            </Typography>
            <Select
              labelId="processId_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange('processId_eq', e)}
              value={valueFilter.processId_eq}
              placeholder="Select Referral Transaction Status"
              size="small"
              selectOptions={Object.keys(processIdList).reduce(
                (referralTypes, key) => {
                  referralTypes.push({
                    label: processIdList[key],
                    value: key,
                  });
                  return referralTypes;
                },
                []
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              BY REFERRAL CODE USED
            </Typography>
            <Input
              type="text"
              name="referralCodeUsed_eq"
              placeholder="Enter Referral Code Used"
              size="small"
              variant="outlined"
              value={valueFilter.referralCodeUsed_eq}
              onChange={e => onChange('referralCodeUsed_eq', e)}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean} loading={isLoading}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              loading={isLoading}
              disabled={
                !valueFilter?.referralType_eq &&
                !valueFilter?.referralCampaignNumberId_eq &&
                !valueRangePicker[0] &&
                !valueRangePicker[1] &&
                !valueFilter?.accountId_eq &&
                !valueFilter?.processId_eq &&
                !valueFilter?.referralCodeUsed_eq
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
