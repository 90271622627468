import BaseApiService from '../baseApi.service';

export default class UserService extends BaseApiService {
  readonly basePath = 'users/v1/me';

  constructor(props = null) {
    super(props);
  }

  getOne = async () => {
    return await this.get('0').then(async res => res);
  };

  getRefeshToken = async (params: object) => {
    return await this.post('auth/refreshToken', params).then(async res => res);
  };
}
