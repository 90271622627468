/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';

import {images} from '../../../../../constants/image';
import {
  Modal,
  Button,
  Input,
  RangePicker,
  Select,
} from '../../../../../components';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalFilter.style';

interface ModalFilterProps {
  visible: boolean;
  onClose?: () => void;
  handleFilter?: () => void;
  handleClean?: () => void;
  valueFilter?: any;
  setValueFilter?: (any) => void;
  nameContainsList?: any;
  setNameContainsList?: (any) => void;
  valueRangePicker?: any;
  setValueRangePicker?: (any) => void;
}

function createData(label: string, value: string) {
  return {
    label,
    value,
  };
}

function ModalFilter({
  visible,
  onClose,
  handleFilter,
  handleClean,
  valueFilter,
  setValueFilter,
  valueRangePicker,
  setValueRangePicker,
}: ModalFilterProps) {
  const onChange = (event: any) => {
    const {name, value} = event.target;
    setValueFilter?.((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangeRangePicker = value => {
    setValueRangePicker?.(value);
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <>
        <StyledModalTitle>Filter</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'BY NAME/ DESCRIPTION'}
            </Typography>
            <Input
              type="text"
              name="nameOrDescription_contains"
              placeholder="Enter Name or Description"
              size="small"
              variant="outlined"
              value={valueFilter.nameOrDescription_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              CODE TYPE
            </Typography>
            <Select
              labelId="codeType_eq"
              name="codeType_eq"
              displayEmpty
              variant="outlined"
              onChangeSelect={e => onChange(e)}
              value={valueFilter.codeType_eq}
              placeholder="Select Code Type"
              size="small"
              selectOptions={[
                createData('Standard', '1'),
                createData('Unique', '2'),
              ]}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              {'BY REFERRAL CODE'}
            </Typography>
            <Input
              type="text"
              name="referralCode_contains"
              placeholder="Enter Referral Code"
              size="small"
              variant="outlined"
              value={valueFilter.referralCode_contains}
              onChange={e => onChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Typography variant="subtitle2" mb={2}>
              REFERRAL PERIOD
            </Typography>
            <RangePicker
              value={valueRangePicker}
              onChange={onChangeRangePicker}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <StyledModalActions>
          <div className="btn-action-1">
            <Button variant="text" onClick={handleClean}>
              Clear
            </Button>
          </div>
          <div className="btn-action-2">
            <Button
              onClick={handleFilter}
              disabled={
                !valueFilter.nameOrDescription_contains &&
                !valueRangePicker[0] &&
                !valueRangePicker[1] &&
                !valueFilter.codeType_eq &&
                !valueFilter.referralCode_contains
              }
            >
              Apply Filter
            </Button>
          </div>
        </StyledModalActions>
      </>
    </Modal>
  );
}
export default ModalFilter;
