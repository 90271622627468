export const USER_TYPE = {
  ADMINISTRATOR: 1,
  MERCHANT: 2,
};

export const USER_ROLE = {
  [USER_TYPE.ADMINISTRATOR]: 'Administrator',
  [USER_TYPE.MERCHANT]: 'Merchant',
};

export const ROLE_SDC_ADMIN = {
  [USER_TYPE.ADMINISTRATOR]: 'administrator',
  [USER_TYPE.MERCHANT]: 'merchant',
};
