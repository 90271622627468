import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CardContent, Grid, Stack} from '@mui/material';

import {Breadcrumbs, Button, Chip} from '../../../components';
import {
  DataDetail,
  typeRoutes,
} from '../../../types/typeVoucherTransactionHistory';
import {path} from '../../../routes/path';
import MembershipTicketService from '../../../services/MembershipTicket/membership-ticket.service';
import {formatLocalDateFromUTCTime, hasPermission} from '../../../utils';
import {ColorType} from '../../../types/typeChip';
import {
  MEMBERSHIP_TICKET_COLOR,
  MEMBERSHIP_TICKET_LABEL,
  MEMBERSHIP_TICKET_STATUS,
} from '../../../constants/status';
import queryString from 'query-string';

// styles
import {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledChip,
  StyledCardContent,
} from './VoucherTransactionHistoryDetail.style';

function VoucherTransactionHistoryDetail() {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const locationUrl = useLocation();
  const idDetail = paramsUrl?.id;
  const membershipId = locationUrl?.state?.membershipId;
  const merchantId = locationUrl?.state?.merchantId;

  // states
  const [dataDetail, setDataDetail] = useState<DataDetail>({});
  const [isUsed, setIsUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const renderCaption = (value: string) => {
    if (value) {
      return (
        <Typography color="rgba(0, 0, 0, 0.5)" mb="31px" variant="h6">
          {value}:
        </Typography>
      );
    }
    return null;
  };

  const renderSubtitle = value => {
    return (
      <Typography mb="31px" variant="h6">
        {value ? value : '--'}
      </Typography>
    );
  };

  const getDataDetail = () => {
    setIsLoading(true);
    new MembershipTicketService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setIsLoading(false);
        setDataDetail(res.data.data);
      }
    });
  };

  const renderLink = () => {
    if (membershipId) {
      return `/${
        path.VOUCHER_TRANSACTION_HISTORY
      }?membershipId=${membershipId}${queryString.stringify(
        locationUrl?.state.filterParams
      )}`;
    }
    if (merchantId) {
      return `/${
        path.VOUCHER_TRANSACTION_HISTORY
      }?merchantId=${merchantId}${queryString.stringify(
        locationUrl?.state.filterParams
      )}`;
    }
    return `/${path.VOUCHER_TRANSACTION_HISTORY}?${queryString.stringify(
      locationUrl?.state.filterParams
    )}`;
  };

  const renderStatus = () => {
    const status =
      dataDetail?.pointId === null
        ? MEMBERSHIP_TICKET_STATUS.ACTIVE
        : MEMBERSHIP_TICKET_STATUS.USED;
    const renderColor = MEMBERSHIP_TICKET_COLOR[status];
    return (
      <Chip
        label={MEMBERSHIP_TICKET_LABEL[status]}
        color={renderColor as ColorType}
        size="medium"
      />
    );
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  useEffect(() => {
    setIsUsed(dataDetail?.pointId !== null);
  }, [dataDetail]);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Typography variant="h3" gutterBottom>
        Voucher Transaction Detail
      </Typography>
      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            typeRoutes('Voucher Transaction', renderLink(), false, {}),
            typeRoutes('Detail', null, true),
          ]}
        />
      </StyledDivBreadcrumbs>

      <Divider my={6} />

      <Grid container mb={9}>
        <Grid item xs={12} md={12}>
          <StyledChip>{renderStatus()}</StyledChip>
          <Card
            pl={12}
            pr={12}
            sx={{
              position: 'relative',
              bottom: '16px',
            }}
          >
            <StyledCardContent>
              <Divider my={6} />
              <Grid wrap="nowrap" container justifyContent="space-evenly">
                <Grid item xs={6} md={6}>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      {renderCaption('Voucher No')}
                    </Grid>
                    <Grid item xs={8} md={8}>
                      {renderSubtitle(dataDetail?.id)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      {renderCaption('Voucher Title')}
                    </Grid>
                    <Grid item xs={8} md={8}>
                      {renderSubtitle(dataDetail?.ticketTitle)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      {renderCaption('Voucher Point')}
                    </Grid>
                    <Grid item xs={8} md={8}>
                      {renderSubtitle(dataDetail?.ticketPoint?.toString())}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      {renderCaption('Islander Member Email')}
                    </Grid>
                    <Grid item xs={8} md={8}>
                      {renderSubtitle(dataDetail?.membershipEmail)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      {renderCaption('Gotten Voucher Date Time')}
                    </Grid>
                    <Grid item xs={8} md={8}>
                      {renderSubtitle(
                        formatLocalDateFromUTCTime(
                          dataDetail?.createdDateTime,
                          'H:mm A, MMM DD, YYYY'
                        ) || '--'
                      )}
                    </Grid>
                  </Grid>
                  {isUsed && dataDetail?.updatedDateTime && (
                    <Grid container>
                      <Grid item xs={4} md={4}>
                        {renderCaption('Used Voucher Date Time')}
                      </Grid>
                      <Grid item xs={8} md={8}>
                        {renderSubtitle(
                          formatLocalDateFromUTCTime(
                            dataDetail?.updatedDateTime,
                            'H:mm A, MMM DD, YYYY'
                          ) || '--'
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {isUsed && (
                  <Grid item xs={6} md={6}>
                    <Grid container>
                      <Grid item xs={4} md={4}>
                        {renderCaption('Transacted at IP')}
                      </Grid>
                      <Grid item xs={8} md={8}>
                        {renderSubtitle(dataDetail?.merchantName)}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} md={4}>
                        {renderCaption('Transaction ID')}
                      </Grid>
                      <Grid item xs={8} md={8}>
                        {renderSubtitle(dataDetail?.pointId)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </StyledCardContent>
          </Card>
        </Grid>
        <Divider my={6} />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card px={6} pt={3}>
            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    loading={isLoading}
                    onClick={() => {
                      navigate(`/${path.VOUCHER}/${dataDetail?.ticketId}`);
                    }}
                  >
                    Go to Voucher
                  </Button>
                  {isUsed && hasPermission(path.ALL_TRANSACTION, 'read') && (
                    <Button
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      type="button"
                      onClick={() => {
                        navigate(
                          `/${path.ALL_TRANSACTION}/${dataDetail?.pointId}`
                        );
                      }}
                    >
                      Go to Transaction
                    </Button>
                  )}
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VoucherTransactionHistoryDetail;
