import ApiService from '../api.service';

export default class TokenService extends ApiService {
  readonly basePath = 'auth/v1';

  constructor(props = null) {
    super(props);
  }

  getRefeshToken = async (params: object) => {
    return await this.post('refreshToken', params).then(async res => res);
  };
}
