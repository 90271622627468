import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CardContent, Grid, Stack} from '@mui/material';

import {Breadcrumbs, Button, Chip} from '../../../components';
import {
  DataDetail,
  typeRoutes,
  VoucherDetail,
} from '../../../types/typeAllTransaction';
import {path} from '../../../routes/path';
import {statusColor} from '../../../styles/status-color';
import ModalApproval from '../components/ModalApproval/ModalApproval';
import AllTransactionService from '../../../services/AllTransaction/all-transaction.service';
import {
  envRole,
  formatDate,
  formatLocalDateFromUTCTime,
  hasPermission,
} from '../../../utils';
import {ColorType} from '../../../types/typeChip';
import {
  POINT_SUCCESS_COLOR,
  POINT_SUCCESS_LABEL,
} from '../../../constants/status';
import VoucherService from '../../../services/Voucher/voucher.service';
import queryString from 'query-string';

// styles
import {
  Typography,
  StyledDivBreadcrumbs,
  Card,
  Divider,
  StyledChip,
  StyledCardContent,
} from './AllTransactionDetail.style';
import {USER_TYPE} from '../../../constants/UserType';

function AllTransactionDetail() {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const locationUrl = useLocation();
  const idDetail = paramsUrl?.id;
  const membershipId = locationUrl?.state?.membershipId;
  const merchantId = locationUrl?.state?.merchantId;

  const canUpdate = hasPermission(path.ISLAND_MEMBERS, 'update');
  const formatDateTime = 'DD/MM/YYYY HH:mm';
  // states
  const [isModalApproval, setIsModalApproval] = useState(false);
  const [dataDetail, setDataDetail] = useState<DataDetail>({});
  const [typeModal, setTypeModal] = useState('');
  const [vouchersUsedTitle, setVouchersUsedTitle] = useState<
    string | undefined
  >(undefined);

  const renderCaption = (value: string) => {
    if (value) {
      return (
        <Typography color="rgba(0, 0, 0, 0.5)" mb="31px" variant="h6">
          {value}:
        </Typography>
      );
    }
    return null;
  };

  const renderSubtitle = value => {
    return (
      <Typography mb="31px" variant="h6">
        {value ? value : '--'}
      </Typography>
    );
  };

  const renderTextStatus = (operation: number, point: number) => {
    const fontSize = '50px';
    if (point > 0) {
      return (
        <Typography
          color={operation === 1 ? statusColor.approved : statusColor.failed}
          variant="h1"
          fontSize={fontSize}
          mt={5}
          textAlign="center"
        >
          {operation === 1 ? `+ ${point}` : `- ${point}`}
        </Typography>
      );
    }
    return (
      <Typography
        color="#000000"
        variant="h1"
        fontSize={fontSize}
        mt={5}
        textAlign="center"
      >
        {point}
      </Typography>
    );
  };

  const toggleModalApproval = () => {
    setIsModalApproval(!isModalApproval);
  };

  const getVoucherUsed = data => {
    if (data.ticketTitle && data.secondTicketTitle) {
      return setVouchersUsedTitle(
        `${data.ticketTitle}, ${data.secondTicketTitle}`
      );
    }

    if (data.ticketTitle) {
      return setVouchersUsedTitle(data.ticketTitle);
    }

    return setVouchersUsedTitle(undefined);
  };

  const getDataDetail = () => {
    new AllTransactionService().getOne(idDetail).then(res => {
      if (res && res.data && res.data.data) {
        setDataDetail(res.data.data);
        getVoucherUsed(res.data.data);
      }
    });
  };

  const renderLink = () => {
    if (membershipId) {
      return `/${
        path.ALL_TRANSACTION
      }?membershipId=${membershipId}${queryString.stringify(
        locationUrl?.state?.filterParams
      )}`;
    }
    if (merchantId) {
      return `/${
        path.ALL_TRANSACTION
      }?merchantId=${merchantId}${queryString.stringify(
        locationUrl?.state?.filterParams
      )}`;
    }
    return `/${path.ALL_TRANSACTION}?${queryString.stringify(
      locationUrl?.state?.filterParams
    )}`;
  };

  const renderStatus = () => {
    const renderColor = POINT_SUCCESS_COLOR[dataDetail?.processId];
    return (
      <Chip
        label={POINT_SUCCESS_LABEL[dataDetail?.processId]}
        color={renderColor as ColorType}
        size="medium"
      />
    );
  };

  const renderTransactionType = () => {
    if (
      dataDetail?.operation === 1 &&
      dataDetail?.workflowId !== null &&
      dataDetail?.pointMultiplierEventCreationId !== null
    ) {
      return `Bonus Points associated with Transaction No: ${dataDetail?.workflowId}`;
    }

    if (
      dataDetail?.operation === 1 &&
      dataDetail?.pointValue > 0 &&
      dataDetail?.ticketId === null
    ) {
      return `Earn points at ${dataDetail.islandPartnerName}`;
    }
    if (dataDetail?.operation === 2 && dataDetail?.islandPartnerName === null) {
      return `Get voucher ID: ${dataDetail.ticketNumberId}${
        dataDetail?.secondTicketNumberId
          ? ', ' + dataDetail?.secondTicketNumberId
          : ''
      }`; // endpoint tickets/v1
    }
    if (
      dataDetail?.islandPartnerName !== null &&
      dataDetail?.ticketId !== null
    ) {
      return `Used voucher ID: ${dataDetail.ticketNumberId}${
        dataDetail?.secondTicketNumberId
          ? ', ' + dataDetail?.secondTicketNumberId
          : ''
      }`; // endpoint transactions/v1
    }
  };

  const renderSpentAmount = () => {
    if (dataDetail?.operation === 2 && dataDetail?.islandPartnerName === null) {
      return '$ 0';
    }
    return `$ ${parseFloat(dataDetail?.spentAmount).toFixed(2)}`;
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <ModalApproval
        title={typeModal === 'approve' ? 'Approve' : 'Declined'}
        typeModal={typeModal}
        isModalApproval={isModalApproval}
        onClose={toggleModalApproval}
        dataDetail={dataDetail}
        reloadPage={getDataDetail}
        // setDataDetail={setDataDetail}
      />

      <Typography variant="h3" gutterBottom>
        Transaction Detail
      </Typography>
      <StyledDivBreadcrumbs>
        <Breadcrumbs
          routes={[
            // typeRoutes('Dashboard', `/${path.DASHBOARD}`),
            typeRoutes('All Transaction', renderLink(), false, {
              membershipId: membershipId,
              merchantId: merchantId,
            }),
            typeRoutes('Detail', null, true),
          ]}
        />
      </StyledDivBreadcrumbs>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Typography variant="h6" fontSize="14px">
            {formatLocalDateFromUTCTime(
              dataDetail?.createdDateTime,
              'H:mm A, MMM DD, YYYY'
            ) || '--'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container mb={9}>
        <Grid item xs={12} md={12}>
          <StyledChip>{renderStatus()}</StyledChip>
          <Card
            pl={12}
            pr={12}
            sx={{
              position: 'relative',
              bottom: '16px',
            }}
          >
            <StyledCardContent>
              <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                  {renderTextStatus(
                    dataDetail?.operation,
                    dataDetail?.pointValue
                  )}
                  <Typography
                    variant="h6"
                    mt="10px"
                    textAlign="center"
                    fontStyle="oblique"
                  >
                    {dataDetail?.notes || '--'}
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    color="rgba(0, 0, 0, 0.5)"
                  >
                    {`# ${dataDetail?.id}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider my={6} />
              <Grid wrap="nowrap" container justifyContent="space-evenly">
                <Grid item xs={2} md={2}>
                  {+envRole === USER_TYPE.ADMINISTRATOR &&
                    renderCaption('Email Members')}
                  {renderCaption('Account ID')}
                  {renderCaption('Loyalty Membership ID')}
                  {renderCaption('Transactional Type')}
                  {renderCaption('Spent Amount')}
                  {renderCaption('Voucher Used')}
                </Grid>
                <Grid item xs={4} md={4}>
                  {+envRole === USER_TYPE.ADMINISTRATOR &&
                    renderSubtitle(dataDetail?.email)}
                  {renderSubtitle(dataDetail?.customerId)}
                  {renderSubtitle(dataDetail?.membershipId)}
                  {renderSubtitle(renderTransactionType())}
                  {renderSubtitle(renderSpentAmount())}
                  {renderSubtitle(vouchersUsedTitle)}
                </Grid>
                <Grid item xs={1} md={1}></Grid>
                <Grid item xs={2} md={2}>
                  {renderCaption('Expiry date')}
                  {renderCaption('Island Partner')}
                  {renderCaption('Island Partner ID')}
                  {renderCaption('Authorised by')}
                  {renderCaption('Limited Event ID')}
                  {renderCaption('Associated Transaction No')}
                </Grid>
                <Grid item xs={2} md={2}>
                  {renderSubtitle(
                    formatDate(dataDetail?.expiryDateTime, formatDateTime)
                  )}
                  {renderSubtitle(dataDetail?.islandPartnerName)}
                  {renderSubtitle(dataDetail?.islandPartnerId)}
                  {renderSubtitle(dataDetail?.employeeName)}
                  {renderSubtitle(dataDetail?.pointMultiplierEventCreationId)}
                  {renderSubtitle(dataDetail?.workflowId)}
                </Grid>
              </Grid>
            </StyledCardContent>
          </Card>
        </Grid>
        <Divider my={6} />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card px={6} pt={3}>
            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Stack direction="row" spacing={4}>
                  {dataDetail?.processId === 2 && !!canUpdate ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        type="button"
                        onClick={() => {
                          toggleModalApproval();
                          setTypeModal('approve');
                        }}
                        width="125px"
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        type="button"
                        onClick={() => {
                          toggleModalApproval();
                          setTypeModal('declined');
                        }}
                        width="125px"
                      >
                        Decline
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => {
                          navigate(-1);
                        }}
                        width="125px"
                      >
                        Done
                      </Button>
                      {(+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.operation === 2 &&
                        dataDetail?.islandPartnerName === null) ||
                      (+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.ticketId !== null &&
                        dataDetail?.islandPartnerName !== null) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.VOUCHER}/${dataDetail?.ticketId}`
                            );
                          }}
                          width="185px"
                        >
                          {`Go to Voucher ID ${dataDetail?.ticketNumberId}`}
                        </Button>
                      ) : null}
                      {(+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.operation === 2 &&
                        dataDetail?.islandPartnerName === null &&
                        dataDetail?.secondTicketId !== null) ||
                      (+envRole === USER_TYPE.ADMINISTRATOR &&
                        dataDetail?.secondTicketId !== null &&
                        dataDetail?.islandPartnerName !== null) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.VOUCHER}/${dataDetail?.secondTicketId}`
                            );
                          }}
                          width="185px"
                        >
                          {`Go to Voucher ID ${dataDetail?.secondTicketNumberId}`}
                        </Button>
                      ) : null}

                      {dataDetail?.pointMultiplierEventCreationId ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            navigate(
                              `/${path.LIMITED_EVENT}?_page=0&_size=10&status_eq=1&limitedEventId_eq=${dataDetail?.pointMultiplierEventCreationId}`
                            );
                          }}
                          width="165px"
                        >
                          Go to Limited Event
                        </Button>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AllTransactionDetail;
