/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {NavLink, useSearchParams} from 'react-router-dom';
import {Edit, FilterList as FilterListIcon} from '@mui/icons-material';
import {Grid, TableCell, TableRow, TableBody, IconButton} from '@mui/material';

import {USER_TYPE, USER_ROLE} from '../../constants/UserType';
import {path} from '../../routes/path';
import ModalAddNew from './components/ModalAddNew/ModalAddNew';
import {
  ColumnsType,
  RowType,
  typeRoutes,
} from '../../types/typeSDCAdministrators';
import {Table, Breadcrumbs, Tooltips, Button, Chip} from '../../components';
import AdministratorsService from '../../services/Administrators/administrators.service';
import useAuth from '../../hooks/useAuth';
import ExportExcelService from '../../services/ExportExcel/ExportExcel';
import {ColorType} from '../../types/typeChip';
import ModalFilter from './components/Modalfilter/ModalFilter';
import queryString from 'query-string';
import IslandPartnerService from '../../services/Islander/islander.service';

// style
import {
  StyledAction,
  Typography,
  Divider,
  StyledIcon,
  StyledStack,
} from './SDCAdministrators.style';
import success from '../../constants/success';
import {isEmpty} from 'lodash';

function SDCAdministrators() {
  const {getUser, showNotification} = useAuth();
  const {email} = getUser() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  // states
  const [order] = useState<'desc' | 'asc'>('asc');
  const [orderBy] = useState('');
  const [dense] = useState(false);
  const [focusId, setFocusId] = useState(null);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [dataSource, setDataSource] = useState<Array<RowType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [valueFilter, setValueFilter] = useState({
    type_eq: searchParams.get('type_eq') ?? '',
    ipName_contains: searchParams.get('ipName_contains') ?? '',
    email_contains: searchParams.get('email_contains') ?? '',
    phoneNumber_contains: searchParams.get('phoneNumber_contains') ?? '',
    adminName_contains: searchParams.get('adminName_contains') ?? '',
  });
  const [merchants, setMerchants] = useState([]);

  const [params, setParams] = useState({
    _page: 0,
    _size: 10,
    status_eq: 1,
    type_eq: searchParams.get('type_eq') ?? undefined,
    ipName_contains: searchParams.get('ipName_contains') ?? undefined,
    email_contains: searchParams.get('email_contains') ?? undefined,
    phoneNumber_contains: searchParams.get('phoneNumber_contains') ?? undefined,
    adminName_contains: searchParams.get('adminName_contains') ?? undefined,
  });

  const columns: Array<ColumnsType> = [
    {
      dataIndex: '',
      numeric: false,
      disablePadding: false,
      label: 'Admin Name',
    },
    {
      dataIndex: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      dataIndex: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      dataIndex: 'phoneNumber',
      numeric: false,
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      dataIndex: 'islandPartnerName',
      numeric: false,
      disablePadding: false,
      label: 'IP Name',
    },
    {
      dataIndex: null,
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setParams(preState => ({
      ...preState,
      _page: newPage,
    }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setParams(preState => ({
      ...preState,
      _page: 0,
      _size: parseInt(event.target.value, 10),
    }));
  };

  const renderAction = (value: string) => {
    if (focusId === value) {
      return (
        <StyledAction>
          <Tooltips title="Edit">
            <IconButton
              aria-label="details"
              component={NavLink}
              to={`/${path.SDC_ADMINISTRATORS}/${value}`}
              size="medium"
            >
              <Edit style={StyledIcon} />
            </IconButton>
          </Tooltips>
        </StyledAction>
      );
    }
  };

  const toggleModal = () => {
    setIsModalAddNew(!isModalAddNew);
  };

  const renderFieldTitle = value => {
    if (Object.values(USER_TYPE).includes(value)) return USER_ROLE[value];
    return '--';
  };

  const renderFieldAdminName = value => {
    if (value) {
      return `${value.firstName} ${''} ${value.lastName}`;
    }
  };

  const getAdministrators = () => {
    setIsLoading(true);
    new AdministratorsService()
      .getAll(params)
      .then(res => {
        if (res?.data && Array.isArray(res.data.items)) {
          setDataSource(res.data.items);
          setTotalPage(res?.data?.total);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        // Get api error => show notification or no items listing
        setError(error);
      });
  };

  const getMerchants = () => {
    new IslandPartnerService().getAll({_size: 99999}).then(res => {
      if (res.success) {
        if (!isEmpty(res.data) && !isEmpty(res.data.items)) {
          const listMerchant = res.data.items
            .map(item => ({
              ...item,
              label: item.name,
              value: item.name,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1));
          setMerchants(listMerchant);
        }
      }
    });
  };

  const getExport = () => {
    setIsLoadingExport(true);
    new ExportExcelService()
      .exportEmailExcelSDCAdministrators({
        email: email,
        ...params,
      })
      .then(res => {
        setIsLoadingExport(false);
        if (res?.success) {
          showNotification({
            message: success.EXPORT_SUCCESS,
          });
        } else {
          showNotification({
            message: res?.errorMessage,
            variation: 'error',
          });
        }
      });
  };

  const handleFilter = () => {
    if (valueFilter?.type_eq) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        type_eq: valueFilter?.type_eq,
      }));
    }
    if (valueFilter?.adminName_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        adminName_contains: valueFilter?.adminName_contains,
      }));
    }
    if (valueFilter?.email_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_contains: valueFilter?.email_contains,
      }));
    }
    if (valueFilter?.phoneNumber_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        phoneNumber_contains: valueFilter?.phoneNumber_contains,
      }));
    }
    if (valueFilter?.ipName_contains) {
      setParams(preState => ({
        ...preState,
        _page: 0,
        ipName_contains: valueFilter?.ipName_contains,
      }));
    }
    toggleModalFilter();
    // handleClean();
  };

  const handleClean = () => {
    setValueFilter({
      email_contains: '',
      adminName_contains: '',
      ipName_contains: '',
      phoneNumber_contains: '',
      type_eq: '',
    });
  };

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const deleteSearchParams = key => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const handleDeleteChip = (type: string) => {
    if (type === 'adminName_contains') {
      deleteSearchParams('adminName_contains');
      setValueFilter({
        ...valueFilter,
        adminName_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        adminName_contains: undefined,
      }));
    }

    if (type === 'ipName_contains') {
      deleteSearchParams('ipName_contains');
      setValueFilter({
        ...valueFilter,
        ipName_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        ipName_contains: undefined,
      }));
    }

    if (type === 'email_contains') {
      deleteSearchParams('email_contains');
      setValueFilter({
        ...valueFilter,
        email_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        email_contains: undefined,
      }));
    }

    if (type === 'phoneNumber_contains') {
      deleteSearchParams('phoneNumber_contains');
      setValueFilter({
        ...valueFilter,
        phoneNumber_contains: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        phoneNumber_contains: undefined,
      }));
    }

    if (type === 'type_eq') {
      deleteSearchParams('type_eq');
      setValueFilter({
        ...valueFilter,
        type_eq: '',
      });
      setParams(preState => ({
        ...preState,
        _page: 0,
        type_eq: undefined,
      }));
    }
  };

  useEffect(() => {
    setSearchParams(
      queryString.stringify({
        ...params,
      })
    );
    getAdministrators();
  }, [params]);

  useEffect(() => {
    getMerchants();
  }, []);

  return (
    <React.Fragment>
      <ModalAddNew
        visible={isModalAddNew}
        onClose={() => toggleModal()}
        reloadPage={getAdministrators}
      />
      <Helmet title="SDC Administrators" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            SDC Administrators
          </Typography>

          <Breadcrumbs
            routes={[typeRoutes('SDC Administrators', null, true)]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <ButtonExport
            params={params}
            exportMethod={new AdministratorsService().exportAdministrator}
            fileName="SDC_ADMINISTRATOR"
          /> */}
          <StyledStack direction="row" spacing={3}>
            {searchParams.get('email_contains') !== null && (
              <Chip
                label={searchParams.get('email_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('email_contains');
                }}
              />
            )}

            {searchParams.get('adminName_contains') !== null && (
              <Chip
                label={searchParams.get('adminName_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('adminName_contains');
                }}
              />
            )}

            {searchParams.get('phoneNumber_contains') !== null && (
              <Chip
                label={searchParams.get('phoneNumber_contains')}
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('phoneNumber_contains');
                }}
              />
            )}

            {searchParams.get('type_eq') !== null && (
              <Chip
                label={
                  searchParams.get('type_eq') === '1'
                    ? 'Administrators'
                    : 'Merchants'
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('type_eq');
                }}
              />
            )}

            {searchParams.get('ipName_contains') !== null && (
              <Chip
                label={
                  merchants.find(item =>
                    item?.name
                      .toString()
                      .includes(searchParams.get('ipName_contains'))
                  )?.name
                }
                color={'default' as ColorType}
                onDelete={() => {
                  handleDeleteChip('ipName_contains');
                }}
              />
            )}
          </StyledStack>
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={getExport}
              width="100px"
              variant="outlined"
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
          <Button onClick={toggleModal} loading={isLoading}>
            Add new
          </Button>
          <Tooltips title="Filter list" sx={{marginLeft: '10px'}}>
            <IconButton
              aria-label="Filter list"
              size="large"
              onClick={toggleModalFilter}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltips>
        </Grid>
      </Grid>

      <ModalFilter
        visible={isModalFilter}
        onClose={toggleModalFilter}
        handleFilter={handleFilter}
        handleClean={handleClean}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
        deleteSearchParams={deleteSearchParams}
        merchants={merchants}
      />

      <Divider my={6} />

      <Table
        dataSource={dataSource}
        columns={columns}
        page={params._page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={params._size}
        textNodata="There are no sdc administrator(s) matching the filter."
        order={order}
        orderBy={orderBy}
        dense={dense}
        isMultiCheckbox={false}
        count={totalPage}
        isLoading={isLoading}
      >
        {!error && (
          <TableBody>
            {dataSource?.map(row => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onMouseEnter={() => setFocusId(row.id)}
                  onMouseLeave={() => setFocusId(null)}
                >
                  <TableCell align="left">
                    {renderFieldAdminName(row)}
                  </TableCell>
                  <TableCell align="left">
                    {renderFieldTitle(row.type)}{' '}
                  </TableCell>
                  <TableCell align="left">{row?.email}</TableCell>
                  <TableCell align="left">{row?.phoneNumber}</TableCell>
                  <TableCell align="left">{row?.islandPartnerName}</TableCell>

                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{paddingRight: '10px'}}
                  >
                    {renderAction(row.id)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </React.Fragment>
  );
}

export default SDCAdministrators;
