import BaseApiService from '../baseApi.service';

export default class EventService extends BaseApiService {
  readonly basePath: string = 'event/v1';
  constructor(props = null) {
    super(props);
  }

  uploadImage = async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    return await this.post(`${id}/uploadImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(async res => res.data);
  };

  arrangerFeaturedEvent = async (params: object) => {
    return await this.post('arrange-featured-event', params).then(
      async res => res
    );
  };
}
