import {createGlobalStyle} from 'styled-components/macro';
import {GlobalStyleProps} from '../types/typeStyles';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${props => props.theme.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .show-print {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid #DFE3E8;
    background: #F9FAFB;
    margin: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4CDD5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 6px;
  }


  @media print {
    .no-print, .MuiCardHeader-action, .MuiIconButton-root, hr, .ZUWWs, .exhEMe, .bHKeQo {
      display: none !important;
    }
    .show-print {
      display: block;
    }
  }

  .mandatory-label {
    color: #B71C1C;
  }

  .mandatory-field {
    label {
      :after {
        content: " (*)";
        color: inherit;
      }

      &:not(.Mui-error) {
        :after {
          color: #B71C1C;
        }
      }
    }
  }
`;

export default GlobalStyle;
