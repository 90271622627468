/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {images} from '../../../../constants/image';
import DialogContent from '@mui/material/DialogContent';
import {Box, FormControl, Grid, Grow, TextField} from '@mui/material';
import {Modal, Button, Chip, DateTimePicker} from '../../../../components';
import {ColorType} from '../../../../types/typeChip';
import useAuth from '../../../../hooks/useAuth';

// style
import {
  Typography,
  StyledIconClose,
  StyledModalTitle,
  StyledModalActions,
  Divider,
} from './ModalSelectExpiredDate.style';
import {range} from 'lodash';
import {grey} from '@mui/material/colors';
import {Event} from '@mui/icons-material';
import {formatDateTime} from '../../../../utils';

import IslanderService from '../../../../services/Islander/islander.service';
import success from '../../../../constants/success';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

interface ModalSelectExpiredDateProps {
  visible: boolean;
  onClose: () => void;
  onAlert: () => void;
  reloadPage: () => void;
  merchantId?: string | number;
}

const getCurrentMonthValue = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setFullYear(date.getFullYear());
  return date;
};

const getMinMonthValue = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return date;
};

const getYearRange = () => {
  return range(
    getMinMonthValue().getFullYear(),
    getCurrentMonthValue().getFullYear() + 1
  );
};

function ModalMonthSelection({
  visible,
  onClose,
  onAlert,
  reloadPage,
  merchantId,
}: ModalSelectExpiredDateProps) {
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [expiredDate, setExpiredDate] = useState<string | null>(null);
  const [CTALoading, setCTALoading] = useState(false);
  const formatTime = 'DD/MM/YYYY HH:mm';

  const {showNotification, getUser} = useAuth();

  const onConfirm = () => {
    setCTALoading(true);
    new IslanderService()
      .patch(`${merchantId}`, {
        qrExpiredDate: expiredDate,
      })
      .then(res => {
        if (res?.success) {
          setCTALoading(false);
          showNotification({
            message: 'Expiration date successfully updated!',
          });
          onClose();
          reloadPage();
          if (
            new Date(res?.data?.qrExpiredDate).getTime() > new Date().getTime()
          ) {
            onAlert(false);
          }
        } else {
          setCTALoading(false);
          showNotification({
            message: res.errorMessage,
            variation: 'error',
          });
          onClose();
          reloadPage();
        }
      });
  };

  useEffect(() => {
    setDisabledSubmit(expiredDate === null);
  }, [expiredDate]);

  return (
    <Modal visible={visible} onClose={onClose} maxWidth="xs">
      <>
        <StyledModalTitle>Edit Expiry DateTime</StyledModalTitle>
        <StyledIconClose onClick={onClose}>
          <img src={images.icon_close} />
        </StyledIconClose>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sx={{width: '100%'}}>
              <Grid item md={12}>
                <FormControl variant="standard" fullWidth>
                  <Typography variant="body2" mb={2} mt={3}>
                    Select New Expiration DateTime
                  </Typography>
                  <DateTimePicker
                    format="dd/MM/yyyy hh:mm a"
                    required
                    helperText="Please select the expiration date and time."
                    value={expiredDate}
                    onChange={e => {
                      setExpiredDate(formatDateTime(e, formatTime));
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Divider variant="middle" pt={8} />
          <StyledModalActions>
            <div className="btn-action-1">
              <Button variant="text" type="button" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <div className="btn-action-2">
              <Button
                type="submit"
                onClick={onConfirm}
                disabled={disabledSubmit}
                loading={CTALoading}
              >
                Save
              </Button>
            </div>
          </StyledModalActions>
        </DialogContent>
      </>
    </Modal>
  );
}
export default ModalMonthSelection;
