/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

// style
import {StyledCheckbox} from './Checkbox.style';

interface CheckboxProps {
  name?: string;
  checked?: boolean;
  onChange?: (any) => void;
  icon?: any;
  checkedIcon?: any;
  style?: any;
}

function Checkbox({
  checked,
  name,
  onChange,
  icon,
  checkedIcon,
  style,
  ...other
}: CheckboxProps) {
  return (
    <StyledCheckbox
      name={name}
      checked={checked}
      icon={icon}
      onChange={onChange}
      checkedIcon={checkedIcon}
      style={style}
      {...other}
    />
  );
}
export default Checkbox;
