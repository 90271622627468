import styled from 'styled-components/macro';
import {Button as MuiButton} from '@mui/material';
import {spacing, SpacingProps} from '@mui/system';

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

export const Button = styled(MuiButton)<ButtonProps>(spacing);

export const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
`;
