/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// style
import {StyledTextArea} from './TextArea.style';

interface TextAreaProps {
  className?: string;
  name?: string;
  label?: string;
  value?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  key?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  placeholder?: string;
  maxRows?: number | string;
}

function TextArea({
  className,
  name,
  label,
  value,
  disabled,
  type,
  size,
  key,
  fullWidth,
  onChange,
  onBlur,
  error,
  helperText,
  color = 'primary',
  maxRows,
  placeholder,
  ...other
}: TextAreaProps) {
  return (
    <>
      <StyledTextArea
        label={label}
        key={key}
        name={name}
        variant="outlined"
        className={className}
        value={value}
        disabled={disabled}
        onChange={onChange}
        fullWidth={fullWidth}
        type={type}
        size={size}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        color={color}
        multiline
        InputProps={{
          rows: maxRows,
        }}
        {...other}
      />
    </>
  );
}
export default TextArea;
