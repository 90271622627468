/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {limitString} from '../../utils';
// Style
import {
  StyledFileUploader,
  StyledButtonUploadFile,
  StyledContentInput,
  StyledLabelUploadFile,
  Typography,
  StyledInput,
} from './UploadFile.style';

interface UploadFileProps {
  label: string;
  valueFile: any;
  onChange: (event: any) => void;
}

function UploadFile({label, onChange, valueFile}: UploadFileProps) {
  const hiddenFileInput = React.useRef(null);

  return (
    <>
      <StyledLabelUploadFile>
        <Typography variant="body2" fontSize="13px">
          {label}
        </Typography>
      </StyledLabelUploadFile>
      <StyledFileUploader>
        <StyledContentInput>
          <Typography>
            {valueFile ? limitString(valueFile.name, 35) : ''}
          </Typography>
        </StyledContentInput>
        <label htmlFor="contained-button-file">
          <StyledInput
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={onChange}
            ref={hiddenFileInput}
          />
          <StyledButtonUploadFile>
            <Typography color="#000000">Choose file</Typography>
          </StyledButtonUploadFile>
        </label>
      </StyledFileUploader>
    </>
  );
}
export default UploadFile;
